const PRODUCT_PREFIX = 'stcrof_';
export default {
  EDMODO_WEB_APP_STATE    : 'edmodo-web-app-state',
  CONFIG_MODE             : 'config_mode',
  CLEARED                 : 'cleared',
  LAST_LANGUAGE           : 'lastLanguage',
  NEW_TEACHER_ONBOARDING  : 'newTeacherOnboarding',
  TRANSLATOR_MODE_ON      : 'translatorModeOn',
  LAST_PAGE_VISITED       : 'lastPageVisited',
  FIRST_TIME_USER_EXP     : 'firstTimeUserExperience',
  USER_SELECTED_LANGUAGE  : 'userSelectedLanguage',
  ORDER_CACHED_PARAM      : PRODUCT_PREFIX + 'order_cached_param',
  USER_CACHED_PARAM       : PRODUCT_PREFIX + 'user_cached_param',
  ST_ACCOUNT_CACHED_PARAM : PRODUCT_PREFIX + 'st_account_cached_param',
  ST_GROUP_CACHED_PARAM   : PRODUCT_PREFIX + 'st_group_cached_param',
  BLACKLIST_CACHED_PARAM  : PRODUCT_PREFIX + 'blacklist_cached_param',
}
