import React, { Component } from 'react';
import { connect } from 'react-redux';
// import { bindActionCreators } from 'redux';
import { Redirect } from 'react-router-dom';
import * as UserSelectors from '../selectors/UserSelectors';
import PagePathConstants from '../constants/router/PagePathConstants';
import { LOGIN_CONSTANTS } from '../constants/store/StoreStateConstants';

const WithLoggedInRedirectionFactory = (RedirectableComponent) => {
  class EnhancedRedirectableComponent extends Component {
    constructor(props) {
      super(props);
    }

    // componentDidUpdate(prevProps) {
    //   const { accessToken, profileActions } = this.props;
    //   if (accessToken !== prevProps.accessToken && AccessTokenHelper.isValid(accessToken)) {
    //     profileActions.fetchProfileWithAccessToken();
    //   }
    // }

    render() {
      const { loginStatus } = this.props;
      const loggedOut = (loginStatus === LOGIN_CONSTANTS.LOGGED_OUT);

      let redirectTo;
      if (loggedOut) {
        redirectTo = PagePathConstants.LOGIN_PAGE;
      }
      return redirectTo ? <Redirect to={redirectTo} /> : <RedirectableComponent {...this.props} />
    }
  }

  function mapStateToProps(state) {
    return {
      loginStatus: UserSelectors.selectLoginStatus(state)
    };
  }

  // function mapDispatchToProps(dispatch) {
  //   return {
  //     userActions        : bindActionCreators(userActionCreator, dispatch),
  //   };
  // }

  return connect(mapStateToProps, null)(EnhancedRedirectableComponent);
};

export default WithLoggedInRedirectionFactory;
