import { Map } from 'immutable';
import StoreStateConstants from '../constants/store/StoreStateConstants';
import ActionConstants from '../constants/actions/ActionConstants';


const initialState = Map({
  [StoreStateConstants.WITHDRAW_LIST]: [],
  [StoreStateConstants.WITHDRAW_TOTAL_COUNT]: 125,
});

export const WithdrawReducer = (state = initialState, action) => {
  switch (action.type) {
    case ActionConstants.UPDATE_WITHDRAW_LIST: {
      return state.set(StoreStateConstants.WITHDRAW_LIST, action.payload.data).set(StoreStateConstants.WITHDRAW_TOTAL_COUNT, action.payload.total);
    }
    
    default:
      return state;
  }
};
