import React from 'react';
import Colors from '../constants/ColorConstants';

const CalendarIcon = ({
  size = 24,
  color = Colors.icon.default,
  viewBox = '0 0 24 24',
  ...props
}) => (
  <svg viewBox="0 0 1024 1024" p-id="20984" width={size} height={size} {...props}>
    <path d="M768 224a32 32 0 0 1 0-64h32a96 96 0 0 1 96 96v544a96 96 0 0 1-96 96H224a96 96 0 0 1-96-96V256a96 96 0 0 1 96-96h32a32 32 0 0 1 0 64h-32a32 32 0 0 0-32 32v544a32 32 0 0 0 32 32h576a32 32 0 0 0 32-32V256a32 32 0 0 0-32-32z" p-id="20985"></path><path d="M320 128a32 32 0 0 1 64 0v96a32 32 0 0 1-64 0z m320 0a32 32 0 0 1 64 0v96a32 32 0 0 1-64 0z m-192 96a32 32 0 0 1 0-64h128a32 32 0 0 1 0 64z m-64 352a32 32 0 0 1 0-64h256a32 32 0 0 1 0 64z m0-160a32 32 0 0 1 0-64h256a32 32 0 0 1 0 64z m0 320a32 32 0 0 1 0-64h256a32 32 0 0 1 0 64z" p-id="20986" fill={color}></path>
  </svg>
);

export default CalendarIcon