import React from 'react';
import Colors from '../constants/ColorConstants';

const ArrowRightIcon = ({
  size = 24,
  color = Colors.icon.default,
  viewBox = '0 0 24 24',
  ...props
}) => (
  <svg viewBox="0 0 1025 1024" p-id="5166" width={size} height={size} {...props}>
    <path d="M512.839219 1024c-13.652764 0-25.556268-5.119787-35.795842-15.316695-20.436482-20.393817-20.436482-52.733803 0-71.421024L903.009628 512.021332 477.000712 86.780384c-20.436482-20.393817-20.436482-52.733803 0-71.421024 20.479147-20.436482 52.819133-20.436482 71.591684 0L1010.311824 476.31082c20.436482 20.393817 20.436482 52.733803 0 71.421025L548.635061 1008.683305c-10.239573 10.239573-23.892338 15.316695-35.795842 15.316695z" fill={color} p-id="5167"></path><path d="M972.852051 563.04854H51.11979A50.173909 50.173909 0 0 1 0.007253 512.021332c0-28.926795 22.185742-51.027207 51.112537-51.027207h921.732261c28.96946 0 51.112537 22.100412 51.112537 51.027207 0 28.926795-22.185742 51.027207-51.112537 51.027208z" fill={color} p-id="5168"></path>
  </svg>
);

export default ArrowRightIcon