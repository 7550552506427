import React, { Component } from 'react';
import {
    Dropdown, DropdownToggle,
    DropdownMenu, DropdownItem,
} from 'reactstrap';
import SvgIcon from '../SvgIcon';
import Loading from '../LoadingIndicator';
import I18N from '../../../i18n';
import PagePathConstants from '../../../constants/router/PagePathConstants';
import { BASE_URL } from '../../../constants/api/ApiEndpointConstants';
import ArrowRightIcon from '../../../icons/ArrowRightIcon';
import ColorConstants from '../../../constants/ColorConstants';

class Header extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showProfileDropdown: false,
            showLoading: false
        }
        this.renderUserProfileDropdownList = this.renderUserProfileDropdownList.bind(this);
        this.toggleProfileDropdown = this.toggleProfileDropdown.bind(this);
        this.logout = this.logout.bind(this);
        this.toggleLoading = this.toggleLoading.bind(this);
        this.updateMenuTitle = this.updateMenuTitle.bind(this);
    }

    updateMenuTitle(props) {
        let { navRouterList, location } = props || this.props;

        const activeNav = navRouterList.filter(nav => {
            return location.pathname.indexOf(nav.path) >= 0;
        });

        return activeNav && activeNav[0] && activeNav[0].text;
    }

    toggleLoading() {
        this.setState({ showLoading: !this.state.showLoading });
    }

    toggleProfileDropdown() {
        this.setState({ showProfileDropdown: !this.state.showProfileDropdown });
    }

    logout() {
        const { userActions, history } = this.props;
        this.toggleLoading();
        userActions && userActions.logout()
            .then(() => {
                this.toggleLoading();
                history && history.push(PagePathConstants.LOGIN_PAGE);
            });
    }

    renderUserProfileDropdownList() {
        const { currentUser } = this.props;
        const { showProfileDropdown } = this.state;
        return (<Dropdown className="hs-Entrancepage-profile tw-h-auto" isOpen={showProfileDropdown} toggle={this.toggleProfileDropdown} direction='down'>
            <DropdownToggle className="tw-rounded-[50px] tw-text-[16px] tw-text-body-text-1 !tw-border-none !tw-px-0 !tw-py-0">
                <div className='tw-w-[48px] tw-h-[48px] tw-rounded-full tw-flex tw-justify-center tw-items-center hs-user-avatar tw-relative'>
                    <div className='tw-w-[8px] tw-h-[8px] tw-rounded-full tw-bg-brand-primary-light tw-absolute tw-right-[-2px] tw-top-[-2px] animate__zoomIn animate__animated '></div>
                    <div className='tw-w-[42px] tw-h-[42px] tw-rounded-full tw-overflow-hidden tw-text-[12px]' style={{ backgroundImage: `url(${currentUser && (BASE_URL + (currentUser.avatar))})`, backgroundSize: '100%', backgroundPosition: 'center' }}></div>
                </div>
            </DropdownToggle>

            <DropdownMenu>
                <DropdownItem
                    className='tw-w-[200px] tw-h-[30px] tw-text-left tw-pl-[10px] tw-py-0 tw-text-[12px]'
                    onClick={this.logout}>
                    <div className='tw-flex tw-w-full tw-h-full tw-items-center tw-px-[10px] tw-border-none'>
                        <SvgIcon name="logout-icon" />
                        <span className='tw-ml-[20px] tw-text-body-text-1'>{I18N.getText('logout')}</span>
                    </div>
                </DropdownItem>
            </DropdownMenu>
        </Dropdown>)
    }


    render() {
        const { showLoading } = this.state;
        const activeMenuTitle = this.updateMenuTitle();
        const { toggleCollapseStatus, isCallapsed } = this.props;
        return (
            <div className='tw-w-full tw-h-[80px] tw-px-[30px] tw-flex tw-justify-between tw-items-center tw-px-[30px] tw-text-body-text-primary tw-bg-white '>
                {showLoading && <Loading />}
                <div className='tw-flex tw-items-center'>
                    <div className='tw-mr-[30px] tw-h-[30px] tw-flex tw-flex-col tw-justify-center hamburger' onClick={toggleCollapseStatus}>
                        {isCallapsed ?
                            <ArrowRightIcon color={ColorConstants.brand.primary} /> : <React.Fragment>
                                <div className='tw-w-[26px] tw-h-[3px] tw-rounded-[5px] tw-bg-brand-primary tw-mb-[4px] line'></div>
                                <div className='tw-w-[26px] tw-h-[3px] tw-rounded-[5px] tw-bg-brand-primary tw-mb-[4px] line'></div>
                                <div className='tw-w-[15px] tw-h-[3px] tw-rounded-[5px] tw-bg-brand-primary line'></div>
                            </React.Fragment>
                        }
                    </div>
                    <div className='tw-flex tw-flex-col tw-items-start tw-justify-start'>
                        <div className='tw-text-[20px] tw-font-[600]'>{activeMenuTitle}</div>
                        <div className='tw-text-[12px] tw-font-[600] tw-text-[#B1B1B1]'>Welcome to Stcrof Admin!</div>
                    </div>
                </div>
                <div className='tw-flex tw-items-center tw-pr-[20px]'>
                    <div className='tw-relative tw-mr-[30px] tw-flex tw-justify-between tw-items-center '>
                        {/* <div className='tw-relative tw-mr-[30px]'>
                            <svg width={24} x="0px" y="0px" viewBox="0 0 512 512" >
                                <g>
                                    <g>
                                        <path d="M402.351,381.058h-203.32l-11.806-47.224h266.587L512,101.085H129.038L108.882,20.46H0v33.4h82.804l82.208,328.827
                                                    c-24.053,5.971-41.938,27.737-41.938,53.611c0,30.461,24.781,55.242,55.241,55.242c30.459,0,55.241-24.781,55.241-55.242
                                                    c0-7.755-1.613-15.138-4.511-21.841h122.577c-2.897,6.703-4.511,14.086-4.511,21.841c0,30.461,24.781,55.242,55.241,55.242
                                                    c30.459,0,55.241-24.781,55.241-55.242C457.592,405.84,432.811,381.058,402.351,381.058z M287.029,300.434h-37.08l-8.284-66.275
                                                    h45.365V300.434z M411.912,134.484h57.31l-16.568,66.275h-49.026L411.912,134.484z M399.453,234.16h44.85l-16.568,66.275h-36.566
                                                    L399.453,234.16z M320.428,134.484h57.824l-8.284,66.275h-49.539V134.484z M320.428,234.159h45.365l-8.284,66.275h-37.08V234.159z
                                                     M287.029,134.484v66.275h-49.539l-8.284-66.275H287.029z M137.388,134.484h58.158l8.284,66.275h-49.873L137.388,134.484z
                                                     M162.307,234.159h45.699l8.284,66.275h-37.414L162.307,234.159z M178.315,458.141c-12.043,0-21.841-9.798-21.841-21.842
                                                    c0-12.043,9.798-21.841,21.841-21.841s21.841,9.798,21.841,21.841C200.156,448.343,190.358,458.141,178.315,458.141z
                                                     M402.351,458.141c-12.043,0-21.841-9.798-21.841-21.842c0-12.043,9.798-21.841,21.841-21.841
                                                    c12.043,0,21.841,9.798,21.841,21.841C424.192,448.343,414.394,458.141,402.351,458.141z"></path>
                                    </g>
                                </g>
                            </svg>
                        </div> */}

                        {/* <div className='tw-relative hs-bell-container '>
                            <svg viewBox="0 0 512 512" width={24}><path d="m450.201 407.453c-1.505-.977-12.832-8.912-24.174-32.917-20.829-44.082-25.201-106.18-25.201-150.511 0-.193-.004-.384-.011-.576-.227-58.589-35.31-109.095-85.514-131.756v-34.657c0-31.45-25.544-57.036-56.942-57.036h-4.719c-31.398 0-56.942 25.586-56.942 57.036v34.655c-50.372 22.734-85.525 73.498-85.525 132.334 0 44.331-4.372 106.428-25.201 150.511-11.341 24.004-22.668 31.939-24.174 32.917-6.342 2.935-9.469 9.715-8.01 16.586 1.473 6.939 7.959 11.723 15.042 11.723h109.947c.614 42.141 35.008 76.238 77.223 76.238s76.609-34.097 77.223-76.238h109.947c7.082 0 13.569-4.784 15.042-11.723 1.457-6.871-1.669-13.652-8.011-16.586zm-223.502-350.417c0-14.881 12.086-26.987 26.942-26.987h4.719c14.856 0 26.942 12.106 26.942 26.987v24.917c-9.468-1.957-19.269-2.987-29.306-2.987-10.034 0-19.832 1.029-29.296 2.984v-24.914zm29.301 424.915c-25.673 0-46.614-20.617-47.223-46.188h94.445c-.608 25.57-21.549 46.188-47.222 46.188zm60.4-76.239c-.003 0-213.385 0-213.385 0 2.595-4.044 5.236-8.623 7.861-13.798 20.104-39.643 30.298-96.129 30.298-167.889 0-63.417 51.509-115.01 114.821-115.01s114.821 51.593 114.821 115.06c0 .185.003.369.01.553.057 71.472 10.25 127.755 30.298 167.286 2.625 5.176 5.267 9.754 7.861 13.798z"></path></svg>
                            <div className='tw-w-[6px] tw-h-[6px] tw-rounded-full tw-bg-brand-primary tw-absolute tw-right-0 tw-top-[-3px]'></div>
                        </div> */}

                    </div>
                    {this.renderUserProfileDropdownList()}
                </div>

            </div>
        );
    }
}

export default Header;
