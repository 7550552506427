import ActionConstants from '../constants/actions/ActionConstants';
import * as STCardAPI from '../apis/STCardAPI';
import { showErrorGlobalAlert, showSuccessGlobalAlert } from './globalAlertActionCreator';

export const updateStCardListAction = (stCardList, total) => ({
    type: ActionConstants.UPDATE_ST_CARD_LIST,
    payload: { data: stCardList, total: total },
});

export const getSdCardList = (params) => {
    return function (dispatch, getState) {
        return new Promise((resolve, reject) => {
            STCardAPI.fetchSTCardList(params.page, params.per_page, params.status, params.keywords)
                .then((res) => {
                    if (res.status === 200) {
                        const stCardList = res.data.data.list;
                        const total = res.data.data.total;
                        dispatch(updateStCardListAction(stCardList, total));
                        return resolve('success');
                    }
                })
                .catch((err) => {
                    return reject(err);
                });
        });
    };
}

export const getStCardInfoById = (id) => {
    return function (dispatch, getState) {
        return new Promise((resolve, reject) => {
            STCardAPI.getSTCardInfoById(id)
                .then((res) => {
                    if (res.status === 200) {
                        return resolve(res.data.data);
                    }
                })
                .catch((err) => {
                    return reject(err);
                });
        });
    };
}

export const editSTCardInfoById = (params) => {
    return function (dispatch, getState) {
        return new Promise((resolve, reject) => {
            STCardAPI.editSTCardInfoById(params)
                .then((res) => {
                    if (res.status === 200 && res.data.code === 1) {
                        dispatch(showSuccessGlobalAlert('edit-st-info-succeed'));
                        return resolve('success');
                    } else {
                        dispatch(showErrorGlobalAlert(res.data.msg));
                        return reject(res.data.msg)
                    }
                })
                .catch((err) => {
                    dispatch(showErrorGlobalAlert('edit-st-info-failed'));
                    return reject(err)
                });
        });
    };
}

export const deleteSTCardInfoById = (id) => {
    return function (dispatch, getState) {
        return new Promise((resolve, reject) => {
            STCardAPI.deleteSTCardInfoById(id)
                .then((res) => {
                    if (res.status === 200 && res.data.code === 1) {
                        dispatch(showSuccessGlobalAlert('delete-st-info-succeed'));
                        return resolve('success');
                    } else {
                        dispatch(showErrorGlobalAlert(res.data.msg));
                        return reject(res.data.msg)
                    }
                })
                .catch((err) => {
                    dispatch(showErrorGlobalAlert('delete-st-info-failed'));
                    return reject(err)
                });
        });
    };
}

// export const createNewSTCard = (params) => {
//     return function (dispatch, getState) {
//         return new Promise((resolve, reject) => {
//             STCardAPI.createSTCard(params)
//                 .then((res) => {
//                     console.log(res);
//                     if (res.status === 200) {
//                         dispatch(showSuccessGlobalAlert('create-st-info-succeed'));
//                         return resolve('success');
//                     }
//                 })
//                 .catch((err) => {
//                     dispatch(showErrorGlobalAlert('create-st-info-failed'));
//                     return reject(err);
//                 });
//         });
//     };
// }