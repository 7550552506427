import React from 'react';
import Colors from '../constants/ColorConstants';

const XIcon = ({ 
  size = 24, 
  color = Colors.icon.default,
  viewBox = '0 0 24 24',
  ...props 
  }) => (
  <svg width={size} height={size} viewBox={viewBox} {...props}>
    <path
      fill={color}
      fillRule="evenodd"
      stroke="none"
      strokeWidth="1"
      id="Icon/small/x"
      d="M13.442 11.994l3.507 3.54a1 1 0 01-1.414 1.414l-3.502-3.533-3.57 3.533a1 1 0 01-1.42-1.408L10.62 12 7.05 8.463a1 1 0 011.415-1.414l3.563 3.531 3.502-3.525a1 1 0 111.42 1.408l-3.507 3.531z"
    />
  </svg>
);

export default XIcon;
