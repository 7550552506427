import React from 'react';
import Colors from '../constants/ColorConstants';

const DollarIcon = ({
  size = 24,
  color = Colors.icon.default,
  viewBox = '0 0 24 24',
  ...props
}) => (
  <svg viewBox="0 0 1024 1024" p-id="11516" width={size} height={size} className={`tw-w-[${size}px] tw-h-[${size}px] `} {...props}>
    <path d="M449.728 891.776a486.208 486.208 0 0 1-55.872-10.688 311.68 311.68 0 0 1-104.384-46.72 233.472 233.472 0 0 1-71.872-81.28c-17.728-32.896-26.24-71.936-25.6-117.184h161.92c0 24.64 4.608 45.568 13.824 62.72 9.216 17.088 21.44 30.976 36.736 41.6 15.232 10.624 33.152 18.56 53.76 23.68 20.608 5.12 41.92 7.68 63.872 7.68 14.912 0 30.912-1.216 48-3.584 16.96-2.368 32.96-7.04 47.872-13.888 14.912-6.848 27.328-16.256 37.248-28.288 9.92-11.968 14.912-27.2 14.912-45.696 0-19.904-6.592-36.032-19.712-48.384a157.184 157.184 0 0 0-51.648-30.784 529.024 529.024 0 0 0-72.384-21.632 2058.688 2058.688 0 0 1-81.984-20.544 747.136 747.136 0 0 1-83.072-25.216 288.512 288.512 0 0 1-72.384-38.528 183.36 183.36 0 0 1-51.648-59.136c-13.12-23.68-19.712-52.224-19.712-85.824 0-37.76 8.32-70.4 24.96-98.176 16.768-27.776 38.592-50.88 65.536-69.44 27.008-18.496 57.536-32.192 91.584-41.088 16.64-4.352 33.344-7.68 50.048-9.92V0h125.184v133.12c14.08 1.92 27.84 4.544 41.472 7.68 36.544 8.64 69.056 22.528 97.408 41.664 28.416 19.2 50.944 43.712 67.648 73.536 16.64 29.824 24.96 65.92 24.96 108.48h-161.792c-1.408-21.952-6.208-40.128-14.4-54.528a92.16 92.16 0 0 0-32.448-33.92 140.224 140.224 0 0 0-46.336-17.472 295.04 295.04 0 0 0-56.96-5.12c-13.504 0-27.008 1.344-40.448 4.096-13.504 2.752-25.792 7.552-36.736 14.4a91.072 91.072 0 0 0-27.2 25.728c-7.04 10.24-10.624 23.296-10.624 39.04 0 14.4 2.816 26.048 8.512 34.944 5.696 8.96 16.832 17.152 33.536 24.704 16.64 7.552 39.744 15.04 69.248 22.592 29.44 7.552 67.968 17.152 115.52 28.8 14.208 2.752 33.92 7.68 59.072 14.912 25.216 7.168 50.24 18.688 75.072 34.432 24.832 15.744 46.336 36.864 64.448 63.232 18.112 26.368 27.136 60.16 27.136 101.248 0 33.6-6.72 64.768-20.224 93.568-13.44 28.8-33.536 53.632-60.16 74.56-26.624 20.864-59.648 37.12-99.008 48.832a420.48 420.48 0 0 1-77.696 14.72V1024H449.728v-132.224z" fill={color} p-id="11517"></path>
  </svg>
);

export default DollarIcon