import { createSelector } from 'reselect';
import StoreStateConstants from '../constants/store/StoreStateConstants';
import ReducerNameConstants from '../constants/reducers/ReducerNameConstants';

const selectRouteReducer  = state => state.get(ReducerNameConstants.ROUTE);

export const selectRouteList = createSelector(
  [selectRouteReducer],
  (routeReducer) => {
    const routerList = routeReducer && routeReducer.get(StoreStateConstants.ROUTER_LIST);
    return routerList || [];
  },
);
