import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Select from 'react-select';

// --- Components --- //
import RDSButton from '../../components/common/RDSButton';
import SvgIcon from '../../components/common/SvgIcon';
import EDSInput from '../../components/common/EDSInput';
import Loading from '../../components/common/LoadingIndicator';

// --- Utils --- //
import * as FormValidationsUtil from '../../utils/FormValidationsUtil';

// --- Constants --- //
import PagePathConstants from '../../constants/router/PagePathConstants';
import ColorConstants from '../../constants/ColorConstants';

// --- Actions --- //
import * as IbanCardActionCreator from '../../actions/IbanCardActionCreator';

import I18N from '../../i18n';
import classNames from 'classnames';

const IBAN_CARD_STATUS_LIST = [
    { value: 0, label: I18N.getText('on') },
    { value: 1, label: I18N.getText('off') },
]

const CURCRENCY_LIST = [
    { value: 0, label: I18N.getText('GBP') },
    { value: 1, label: I18N.getText('EUR') },
]

class IbanEditPage extends Component {
    constructor(props, context) {
        super(props, context);

        this.state = {
            card: '',
            telId: '',
            curcrency: CURCRENCY_LIST[0],
            maxAmount: 0,
            maxOrder: 0,
            status: IBAN_CARD_STATUS_LIST[0],
            showError: false,
            showLoading: false,
            isEditing: false,
            errMsg: '',
            errFiled: '',
        };

        this.handleCardChange = this.handleCardChange.bind(this);
        this.handleTelChange = this.handleTelChange.bind(this);
        this.handleMaxAmountChange = this.handleMaxAmountChange.bind(this);
        this.handleMaxOrderChange = this.handleMaxOrderChange.bind(this);
        this.handleStatusChange = this.handleStatusChange.bind(this);
        this.handleCurcrencyChange = this.handleCurcrencyChange.bind(this);
        this.toggleError = this.toggleError.bind(this);
        this.toggleLoading = this.toggleLoading.bind(this);
        this.toggleEditing = this.toggleEditing.bind(this);
        this.handleBackPage = this.handleBackPage.bind(this);
        this.editIbanCard = this.editIbanCard.bind(this);
    }
    componentDidMount() {
        this.getIbanCardInfoById()
    }

    getIbanCardInfoById() {
        const { location, IbanCardActions } = this.props;
        const ibanId = location.pathname.split(`${PagePathConstants.IBAN}/`)[1];
        if (ibanId && ibanId !== 'add') {
            this.toggleLoading();
            IbanCardActions.getIbanCardInfoById(ibanId)
            .then(res => {
                const ibanCardInfo = res;
                let status = IBAN_CARD_STATUS_LIST.filter(item => item.value === ibanCardInfo.status)[0];
                let curcrency = CURCRENCY_LIST.filter(item => item.value == ibanCardInfo.curcrency)[0];
                this.setState({
                    card: ibanCardInfo.card,
                    telId: ibanCardInfo.telId,
                    maxAmount: ibanCardInfo.max_money,
                    maxOrder: ibanCardInfo.max_order,
                    status: status,
                    curcrency: curcrency
                });
                this.toggleLoading();
            })
            .catch(() => {
                this.toggleLoading();
            })
        }
        
    }

    toggleEditing() {
        this.setState({ isEditing: !this.state.isEditing });
    }

    toggleError() {
        this.setState({ showError: !this.state.showError });
    }

    toggleLoading() {
        this.setState({ showLoading: !this.state.showLoading });
    }

    handleCardChange(e) {
        const card = e && e.target.value;
        this.setState({ card, showError: false });
    }

    handleTelChange(e) {
        const telId = e && e.target.value;
        if (FormValidationsUtil.isNumber(telId)) {
            this.setState({ telId, showError: false });
        }
    }

    handleMaxAmountChange(e) {
        const maxAmount = e && e.target.value;
        const regx = new RegExp(/^([0-9]|[1-9]\d+)(\.[0-9]{0,2})?$/);
        if (regx.test(maxAmount) || maxAmount === '') {
            this.setState({ maxAmount });
        }
        this.setState({ showError: false });
    }

    handleMaxOrderChange(e) {
        const maxOrder = e && e.target.value;
        if (maxOrder !== '' && FormValidationsUtil.isNumber(maxOrder)) {
            this.setState({ maxOrder: maxOrder, showError: false });
        }
        if (maxOrder === '') this.setState({ maxOrder, showError: false })
    }

    handleStatusChange(status) {
        this.setState({ status, showError: false });
    }

    handleCurcrencyChange(curcrency) {
        this.setState({ curcrency, showError: false });
    }

    customClearIndicatorWithClass(className) {
        const ClearIndicator = (props) => {
            const { innerProps: { ...restInnerProps }, } = props;
            return (<div {...restInnerProps} >
                <SvgIcon name="x-icon" className={className} color={ColorConstants.brand.primary} />
            </div>);
        };
        return ClearIndicator;
    }

    editIbanCard() {
        const { IbanCardActions, history, location } = this.props;
        let { card, telId, curcrency, maxAmount, maxOrder, status } = this.state;
        if (card.trim() !== '' && !FormValidationsUtil.isValidString(card.trim())) {
            this.setState({ showError: true, errFiled: 'card', errMsg: I18N.getText('inValid-iban-card-number') });
            return;
        }
        const ibanId = location.pathname.split(`${PagePathConstants.IBAN}/`)[1];
        
        let params = {
            card,
            telId: telId,
            max_money: parseFloat(maxAmount),
            max_order: maxOrder,
            status: status.value,
            curcrency: curcrency.value
        }
        if (ibanId && ibanId !== 'add') {
            params['id'] = ibanId;
        }
        this.toggleEditing();
        IbanCardActions && IbanCardActions.editIbanCardInfoById(params)
            .then(() => {
                history && history.push(PagePathConstants.IBAN);
            })
            .catch(err => {
                this.toggleEditing();
            });
    }

    handleBackPage() {
        const { history } = this.props;
        history && history.goBack();
    }

    render() {
        const { isEditing, showLoading, card, telId, curcrency, maxAmount, maxOrder, status, showError, errMsg, errFiled } = this.state;
        return <div className='tw-w-full tw-px-[50px] tw-py-[50px]'>
            {showLoading && <Loading />}
            <div className='tw-rounded-[30px] tw-bg-white'>
                <div className='tw-flex tw-items-center  tw-px-[30px] tw-py-[24px] tw-border-b' onClick={this.handleBackPage}>
                    <SvgIcon name="rds-back-icon" color={ColorConstants.black[0]} size={24} />
                    <span className='tw-ml-[10px] tw-text-[18px] tw-font-bold'>{I18N.getText('back')}</span>
                </div>
                <div className='tw-text-[14px] tw-px-[30px] tw-py-[30px]'>
                    <div className='tw-flex tw-items-center'>
                        <div className='tw-w-[20%] tw-font-bold'>{I18N.getText('Account')}</div>
                        <EDSInput
                            className={classNames(`tw-py-[10px] tw-px-[20px] tw-rounded-[30px] tw-border tw-shadow-xl tw-text-base tw-border-border-gray tw-font-medium focus:tw-border-brand-primary focus:tw-shadow-none placeholder:tw-text-body-text-secondary placeholder:tw-font-normal placeholder:tw-text-[12px]`, {'tw-border-light-red' : (showError && errFiled === 'card')})}
                            placeholder={I18N.getText('iban-account-tip')}
                            value={card || ""}
                            type="text"
                            onChange={this.handleCardChange}
                        />
                    </div>
                    <div className='tw-flex tw-items-center tw-mt-[30px]'>
                        <div className='tw-w-[20%] tw-font-bold'>{I18N.getText('tel-id')}</div>
                        <EDSInput
                            className="tw-py-[10px] tw-px-[20px] tw-rounded-[30px] tw-border tw-shadow-xl tw-text-base tw-border-border-gray tw-font-medium focus:tw-border-brand-primary focus:tw-shadow-none placeholder:tw-text-body-text-secondary placeholder:tw-font-normal placeholder:tw-text-[12px]"
                            placeholder={I18N.getText('tel-tip')}
                            value={telId}
                            type="text"
                            onChange={this.handleTelChange}
                        />
                    </div>
                    <div className='tw-flex tw-items-center tw-mt-[30px]'>
                        <div className='tw-w-[20%] tw-font-bold'>{I18N.getText('curcrency')}</div>
                        <Select
                            className="tw-w-full tw-rounded-[30px]  tw-px-[20px] tw-border tw-shadow-xl tw-border-border-gray placeholder:tw-text-body-text-secondary placeholder:tw-font-normal placeholder:tw-text-[12px] hs-role-selector-container"
                            components={{ ClearIndicator: this.customClearIndicatorWithClass('tw-text-brand-primary') }}
                            styles={{ dropdownIndicator: base => ({ ...base, color: ColorConstants.brand.primary }) }}
                            value={curcrency}
                            onChange={this.handleCurcrencyChange}
                            isClearable={true}
                            options={CURCRENCY_LIST}
                        />
                    </div>
                    <div className='tw-flex tw-items-center tw-mt-[30px]'>
                        <div className='tw-w-[20%] tw-font-bold'>{I18N.getText('max-money')}</div>
                        <EDSInput
                            className="tw-py-[10px] tw-px-[20px] tw-rounded-[30px] tw-border tw-shadow-xl tw-text-base tw-border-border-gray tw-font-medium focus:tw-border-brand-primary focus:tw-shadow-none placeholder:tw-text-body-text-secondary placeholder:tw-font-normal placeholder:tw-text-[12px]"
                            placeholder={I18N.getText('max-money-tip')}
                            value={maxAmount}
                            type="text"
                            onChange={this.handleMaxAmountChange}
                        />
                    </div>
                    <div className='tw-flex tw-items-center tw-mt-[30px]'>
                        <div className='tw-w-[20%] tw-font-bold'>{I18N.getText('max-order')}</div>
                        <EDSInput
                            className="tw-py-[10px] tw-px-[20px] tw-rounded-[30px] tw-border tw-shadow-xl tw-text-base tw-border-border-gray tw-font-medium focus:tw-border-brand-primary focus:tw-shadow-none placeholder:tw-text-body-text-secondary placeholder:tw-font-normal placeholder:tw-text-[12px]"
                            placeholder={I18N.getText('max-order-tip')}
                            value={maxOrder}
                            type="text"
                            onChange={this.handleMaxOrderChange}
                        />
                    </div>
                    <div className='tw-flex tw-items-center tw-mt-[30px]'>
                        <div className='tw-w-[20%] tw-font-bold'>{I18N.getText('status')}</div>
                        <Select
                            className="tw-w-full tw-rounded-[30px]  tw-px-[20px] tw-border tw-shadow-xl tw-border-border-gray placeholder:tw-text-body-text-secondary placeholder:tw-font-normal placeholder:tw-text-[12px] hs-role-selector-container"
                            components={{ ClearIndicator: this.customClearIndicatorWithClass('tw-text-brand-primary') }}
                            styles={{ dropdownIndicator: base => ({ ...base, color: ColorConstants.brand.primary }) }}
                            value={status}
                            onChange={this.handleStatusChange}
                            isClearable={true}
                            name="status"
                            options={IBAN_CARD_STATUS_LIST}
                        />
                    </div>
                    {showError && <div className='tw-mt-[20px] tw-text-[14px] tw-text-error-text'>{errMsg}</div>}
                    <div className='tw-flex tw-justify-center tw-items-center tw-mt-[30px]'>
                        <RDSButton
                            loading={isEditing}
                            disabled={isEditing || card.trim() === '' || telId === '' || maxAmount === '' || maxOrder === ''}
                            onClick={this.editIbanCard}
                            className="tw-text-white">{I18N.getText('edit')}</RDSButton>
                    </div>
                </div>
            </div>

        </div>;
    }
}

function mapStateToProps(state) {
    return {
        // selectedCountryCode: VerifyOptionSelectors.selectCountryCode(state),
        // ssn: VerifyOptionSelectors.selectSsn(state),
    };
}

function mapDispatchToProps(dispatch) {
    return {
        IbanCardActions: bindActionCreators(IbanCardActionCreator, dispatch),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(IbanEditPage);