import ApiEndpointConstants from '../constants/api/ApiEndpointConstants';
import api from './API';

export const getSTGroupInfoById = (id) => {
    return api.get(
        `${ApiEndpointConstants.EDIT_ST_GROUP}?id=${id}`);
}

export const editSTGroupInfoById = (params) => {
    return api.post(
        `${ApiEndpointConstants.EDIT_ST_GROUP}`, params, {
            headers:{
                'Content-Type': 'multipart/form-data'
              }
        });
}

export const fetchSTGroupList = (params) => {
    return api.post(
        `${ApiEndpointConstants.ST_GROUP}`,
        params,
    );
}
