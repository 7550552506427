import { Map } from 'immutable';
import StoreStateConstants from '../constants/store/StoreStateConstants';
import ActionConstants from '../constants/actions/ActionConstants';

const initialState = Map({
    [StoreStateConstants.ST_CARD_LIST]: [],
    [StoreStateConstants.ST_CARD_TOTAL_COUNT]: 0,
});

export const STCardReducer = (state = initialState, action) => {
    switch (action.type) {
        case ActionConstants.UPDATE_ST_CARD_LIST: {
            return state.set(StoreStateConstants.ST_CARD_LIST, action.payload.data).set(StoreStateConstants.ST_CARD_TOTAL_COUNT, action.payload.total);
        }
        default:
            return state;
    }
};
