import React from 'react';
import Colors from '../constants/ColorConstants';

const GBPIcon = ({
  size = 24,
  color = Colors.icon.default,
  viewBox = '0 0 24 24',
  ...props
}) => (
  <svg viewBox="0 0 1024 1024" p-id="3519" width={size} height={size} {...props}>
    <path d="M802.285714 649.714286v209.714285q0 8-5.142857 13.142858t-13.142857 5.142857H237.714286q-8 0-13.142857-5.142857t-5.142858-13.142858v-85.714285q0-7.428571 5.428572-12.857143t12.857143-5.428572h55.428571V536.571429h-54.285714q-8 0-13.142857-5.428572t-5.142857-12.857143V443.428571q0-8 5.142857-13.142857t13.142857-5.142857h54.285714V297.714286q0-97.714286 70.571429-161.142857T543.428571 73.142857q105.714286 0 191.428572 71.428572 5.142857 4.571429 5.714286 11.714285t-4 12.857143l-58.857143 72.571429q-5.142857 6.285714-12.571429 6.857143-7.428571 1.142857-13.142857-4-2.857143-2.857143-14.857143-10.857143t-39.428571-18.285715-53.142857-10.285714q-48.571429 0-78.285715 26.857143t-29.714285 70.285714v122.857143h174.285714q7.428571 0 12.857143 5.142857t5.428571 13.142857v74.857143q0 7.428571-5.428571 12.857143T610.857143 536.571429H436.571429v216.571428h236.571428v-103.428571q0-7.428571 5.142857-12.857143t13.142857-5.428572h92.571429q8 0 13.142857 5.428572t5.142857 12.857143z" p-id="3520"></path>
  </svg>
);

export default GBPIcon