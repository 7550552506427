import ApiEndpointConstants from '../constants/api/ApiEndpointConstants';
import api from './API';

export const getAchCardInfoById = (id) => {
    return api.post(
        `${ApiEndpointConstants.ACH_CARD_DETAIL}`, {id});
}

export const editAchCardInfoById = (params) => {
    return api.post(
        `${ApiEndpointConstants.EDIT_ACH_CARD}`, params);
}

export const createAchCard = (params) => {
    return api.post(
        `${ApiEndpointConstants.EDIT_ACH_CARD}`, params);
}

export const fetchAchCardList = (params) => {
    return api.post(
        `${ApiEndpointConstants.ACH_CARD}`,
        params,
    );
}

export const deleteAchCard = (id) => {
    return api.post(
        `${ApiEndpointConstants.DELETE_ACH_CARD}`, {id});
}
