import ApiEndpointConstants from '../constants/api/ApiEndpointConstants';
import api from './API';

export const getIbanCardInfoById = (id) => {
    return api.get(
        `${ApiEndpointConstants.EDIT_IBAN_CARD}?id=${id}`);
}

export const editIbanCardInfoById = (params) => {
    return api.post(
        `${ApiEndpointConstants.EDIT_IBAN_CARD}`, {card: params});
}

export const createIbanCard = (params) => {
    return api.post(
        `${ApiEndpointConstants.EDIT_IBAN_CARD}`, params);
}

export const fetchIbanCardList = (page, per_page, keywords) => {
    return api.post(
        `${ApiEndpointConstants.IBAN_CARD}`,
        { page, per_page, keywords },
    );
}
