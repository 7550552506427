const PagePathConstants = {
  ROOT_PAGE                     : "/",
  LOGIN_PAGE                    : '/login',
  DASHBOARD                     : '/dashboard',
  
  PAYMENT_PAGE                  : '/payment',

  ORDER_PAGE                    : '/order',
  ORDER_DETAIL_PAGE             : '/order/:id',

  WITHDRAW_PAGE                 : '/withdraw',
  WALLET_PAGE                   : '/withdraw/wallet',

  USER                          : '/user',
  USER_ADD                      : '/user/add',
  USER_EDIT                     : '/user/:id',

  ST                            : '/st-card',
  ST_ADD                        : '/st-card/add',
  ST_EDIT                       : '/st-card/:id',
  ST_GROUP                      : '/st-card-group',
  ST_GROUP_ADD                  : '/st-card-group/add',
  ST_GROUP_EDIT                 : '/st-card-group/:id',

  BLACKLIST                     : '/blacklist',
  BLACKLIST_ADD                 : '/blacklist/add',
  BLACKLIST_EDIT                : '/blacklist/:id',

  IBAN                          : '/iban-card',
  IBAN_ADD                      : '/iban-card/add',
  IBAN_EDIT                     : '/iban-card/:id',

  ACH                           : '/ach-card',
  ACH_ADD                       : '/ach-card/add',
  ACH_EDIT                      : '/ach-card/:id',

  COIN                          : '/coin',
  COIN_ADD                      : '/coin/add',
  COIN_EDIT                     : '/coin/:id',
};

export default PagePathConstants;
