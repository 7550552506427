import React, { Component } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';

import { bindActionCreators } from 'redux';
import { div } from 'reactstrap';
import classnames from 'classnames';
import Pagination from '@material-ui/lab/Pagination';

// --- Components --- //
import Loading from '../../components/common/LoadingIndicator';
import SvgIcon from '../../components/common/SvgIcon';
import EDSInput from '../../components/common/EDSInput';

// --- Constants --- //
import ColorConstants from '../../constants/ColorConstants';

// --- Actions --- //
import * as IbanCardActionCreator from '../../actions/IbanCardActionCreator';

// --- Selectors --- //
import * as IbanCardSelectors from '../../selectors/IbanCardSelectors'

// --- Icons --- //
import SearchIcon from '../../icons/SearchIcon';
import CommentIcon from '../../icons/CommentIcon';
import WebsiteIcon from '../../icons/WebsiteIcon';
import DollarIcon from '../../icons/DollarIcon';
import CalendarIcon from '../../icons/CalendarIcon';

import I18N from '../../i18n';
import RDSButton from '../../components/common/RDSButton';
import PagePathConstants from '../../constants/router/PagePathConstants';

class IbanCardPage extends Component {
  constructor(props, context) {
    super(props, context);

    this.state = {
      ibanCardParams: {
        page: 1,
        per_page: 10,
        keywords: ''
      },
      showLoading: false,
    };

    this.getIbanCardInfo = this.getIbanCardInfo.bind(this);
    this.handleCardFilterClick = this.handleCardFilterClick.bind(this);
    this.handlePageChange = this.handlePageChange.bind(this);
    this.toggleLoading = this.toggleLoading.bind(this);
    this.handleCardFilterChange = this.handleCardFilterChange.bind(this);
    this.handleEditIban = this.handleEditIban.bind(this);
    this.createNewUser = this.createNewUser.bind(this);
  }

  componentDidMount() {
    this.getIbanCardInfo();
  }

  handleEditIban(id) {
    const { history } = this.props;
    history && history.push(PagePathConstants.IBAN_EDIT.replace(':id', id));
  }

  toggleLoading() {
    this.setState({ showLoading: !this.state.showLoading });
  }

  handleCardFilterChange(e) {
    const name = e && e.target.value;
    const { ibanCardParams } = this.state;
    ibanCardParams.keywords = name;
    this.setState({ ibanCardParams });
  }

  getIbanCardInfo(params) {
    if (!params) {
      params = this.state.ibanCardParams;
    }
    const { IbanCardActions } = this.props;
    this.toggleLoading();
    return IbanCardActions && IbanCardActions.fetchIbanCardList(params)
      .then(data => {
        this.toggleLoading();
        return;
      });
  }

  handleCardFilterClick() {
    let { ibanCardParams } = this.state;
    ibanCardParams.page = 1;
    this.setState({ ibanCardParams });
    this.getIbanCardInfo(ibanCardParams);
  }

  handlePageChange(e, page) {
    let { ibanCardParams } = this.state;
    ibanCardParams.page = page;
    this.getIbanCardInfo(ibanCardParams)
      .then(() => {
        this.setState({ ibanCardParams });
      });
  }

  createNewUser() {
    const { history } = this.props;
    history && history.push(PagePathConstants.IBAN_ADD);
  }

  renderIbanCardStatus(state) {
    switch (state) {
      case 0: {
        return (<div className='tw-rounded-[10px] tw-w-[120px] tw-h-[40px] tw-text-[16px] tw-font-bold tw-px-[8px] tw-py-[2px] tw-bg-status-succeed-bg tw-text-status-succeed-text tw-flex tw-items-center tw-justify-center'>
          <span>{I18N.getText('on')}</span>
          {/* <SvgIcon name="check-icon" color={ColorConstants.status.succeed} /> */}
        </div>)
      }
      case 1:
      default: {
        return (<div className='tw-rounded-[10px] tw-w-[120px] tw-h-[40px] tw-text-[16px] tw-font-bold tw-px-[8px] tw-py-[2px] tw-bg-status-timeout-bg tw-text-status-timeout-text tw-flex tw-items-center tw-justify-center'>
          <span>{I18N.getText('off')}</span>
          {/* <SvgIcon name="x-icon" color={ColorConstants.status.failed} /> */}
        </div>)
      }
    }
  }

  renderCurcrency(curcrency) {
    switch (curcrency) {
      case 0: {
        return (<span>{I18N.getText('GBP')}</span>)
      }
      case 1:
      default: {
        return (<span>{I18N.getText('EUR')}</span>)
      }
    }
  }

  render() {
    const { ibanCardList, ibanCardTotalNum } = this.props;
    const { ibanCardParams, showLoading } = this.state;
    const total = Math.ceil(ibanCardTotalNum / ibanCardParams.per_page);
    return (<div className='tw-w-full tw-px-[50px]'>
      {showLoading && <Loading />}
      <div className='tw-w-full tw-mt-[30px]'>
        <div className='tw-flex tw-justify-between tw-items-center tw-w-full tw-mt-[20px] hs-userlist-filter-container'>
          {/* <EDSInput className="tw-border-b-1 tw-border-line-1 tw-w-[450px] tw-pl-[10px]"
            value={ibanCardParams.keywords}
            type="text"
            placeholder={I18N.getText('card-number-placeholder')}
            onChange={this.handleCardFilterChange}
          /> */}
          <div className='tw-relative'>
            <EDSInput className="tw-w-[450px] tw-py-[12px] tw-rounded-[30px] tw-px-[20px] tw-border-none tw-shadow-xl"
              value={ibanCardParams.keywords}
              type="text"
              placeholder={I18N.getText('card-number-placeholder')}
              onChange={this.handleCardFilterChange}
            />
            <SearchIcon size={24} color={ColorConstants.black[0]} className="tw-absolute tw-right-[20px] tw-bottom-[12px] tw-w-[24px] tw-h-[24px]" onClick={this.handleCardFilterClick} />
          </div>
          <div className='tw-w-[300px] tw-flex tw-justify-end tw-items-center'>
            {/* <RDSButton className="tw-text-white tw-mr-[30px]" onClick={this.handleCardFilterClick}>{I18N.getText('search')}</RDSButton> */}
            {/* <SvgIcon name='add-icon' onClick={this.createNewUser} /> */}
            <RDSButton className="tw-text-white" onClick={this.createNewUser}>Create</RDSButton>
          </div>
        </div>

        {ibanCardList && ibanCardList.length > 0 && <React.Fragment>
          <div className='tw-w-full tw-mt-[30px]'>

            <div className='tw-w-full hs-users-table-container'>
              <div className='tw-flex tw-justify-between tw-items-center tw-text-center tw-font-bold tw-text-[14px] tw-mb-[20px] tw-rounded-[30px] tw-bg-white tw-h-[80px] tw-shadow-xl'>
                <div className='tw-w-[10%]'>{I18N.getText('account')}</div>
                <div className='tw-w-[5%]'>{I18N.getText('curcrency')}</div>
                <div className='tw-w-[10%]'>{I18N.getText('max-money')}</div>
                <div className='tw-w-[7.5%]'>{I18N.getText('max-order')}</div>
                <div className='tw-w-[10%]'>{I18N.getText('total-money')}</div>
                <div className='tw-w-[7.5%]'>{I18N.getText('order-count')}</div>
                <div className='tw-w-[15%]'>{I18N.getText('tgid')}</div>
                <div className='tw-w-[10%]'>{I18N.getText('status')}</div>
                <div className='tw-w-[15%]'>{I18N.getText('date')}</div>
                <div className='tw-w-[10%]'>{I18N.getText('operation')}</div>
              </div>
              <div className='tw-w-full tw-text-[14px]'>
                {ibanCardList && ibanCardList.map(record => {
                  return (<div className='tw-rounded-[30px] tw-h-[80px] tw-bg-white tw-overflow-hidden tw-flex tw-justify-between tw-items-center tw-text-center tw-mb-[20px] tw-shadow-xl hs-table-row'>
                    <div className='tw-w-[10%] tw-flex tw-justify-center tw-flex-wrap tw-items-center hs-td-cell'>{`${record.card}`}</div>
                    <div className='tw-w-[5%] tw-flex tw-justify-center tw-items-center hs-td-cell'>{this.renderCurcrency(record.curcrency)}</div>
                    <div className='tw-w-[10%] tw-flex tw-justify-center tw-items-center'>
                      <DollarIcon size={18} color={ColorConstants.black[0]} className="tw-mb-[3px]" />
                      <span className='tw-font-bold hs-td-cell'>{record.max_money}</span>
                    </div>
                    <div className='tw-w-[7.5%] tw-flex tw-justify-center tw-items-center hs-td-cell'>{`${record.max_order}`}</div>
                    <div className='tw-w-[10%] tw-flex tw-justify-center tw-items-center'>
                      <DollarIcon size={18} color={ColorConstants.black[0]} className="tw-mb-[3px]" />
                      <span className='tw-font-bold hs-td-cell'>{record.money_sum}</span>
                    </div>

                    <div className='tw-w-[7.5%] tw-flex tw-justify-center tw-items-center hs-td-cell'>{`${record.order_count}`}</div>
                    <div className='tw-w-[15%] tw-flex tw-justify-center tw-items-center hs-td-cell'>{`${record.telId}`}</div>
                    <div className='tw-w-[10%] tw-flex tw-justify-center tw-items-center'>{this.renderIbanCardStatus(record.status)}</div>
                    <div className='tw-w-[15%] tw-flex tw-justify-center tw-items-center'>
                      <CalendarIcon size={24} color={ColorConstants.black[0]} className="tw-mb-[2px]" />
                      <span className='tw-text-[14px] tw-ml-[5px] hs-td-cell'>{record.createtime}</span>
                    </div>
                    <div className='tw-w-[10%] tw-flex tw-justify-center tw-items-center'>
                      <SvgIcon onClick={() => this.handleEditIban(record.id)} size={20} color={ColorConstants.brand.primary} name="edit-icon" />
                    </div>
                  </div>)
                })}
              </div>
            </div>
          </div>
          <div className='tw-w-full tw-flex tw-justify-center'>
            <Pagination count={total} page={ibanCardParams.page} onChange={this.handlePageChange} variant="outlined" color="primary" />
          </div>
        </React.Fragment>}
        {ibanCardList && ibanCardList.length === 0 && <div className='tw-w-full tw-flex tw-flex-col tw-justify-center tw-items-center'>
          <SvgIcon size={200} name="non-record-icon" />
          <div className='tw-mt-[20px] tw-text-[16px] tw-font-medium'>{I18N.getText('no-records')}</div>
        </div>}
      </div>
    </div>);
  }
}

function mapStateToProps(state) {
  return {
    ibanCardList: IbanCardSelectors.selectIbanCardList(state),
    ibanCardTotalNum: IbanCardSelectors.selectIbanRecordTotalNum(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    IbanCardActions: bindActionCreators(IbanCardActionCreator, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(IbanCardPage);

