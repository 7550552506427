import ApiEndpointConstants from '../constants/api/ApiEndpointConstants';
import api from './API';

export const getBlacklistInfoById = (id) => {
    return api.post(
        `${ApiEndpointConstants.BLACKLIST_DETAIL}`, {id});
}

export const editBlacklistInfoById = (params) => {
    return api.post(
        `${ApiEndpointConstants.EDIT_BLACKLIST}`, params
        // , {
        //     headers:{
        //         'Content-Type': 'multipart/form-data'
        //       }
        // }
    );
}

export const deleteBlacklistInfoById = (id) => {
    return api.post(
        `${ApiEndpointConstants.DELETE_BLACKLIST}`, {id: id});
}

export const fetchBlacklist = (params) => {
    return api.post(
        `${ApiEndpointConstants.BLACKLIST}`,
        params,
    );
}
