import React, { Component } from 'react';
import { connect } from 'react-redux';

import { bindActionCreators } from 'redux';
import { Input } from 'reactstrap';
import classnames from 'classnames';
import Pagination from '@material-ui/lab/Pagination';

// --- Components --- //
import DeleteConfirmationModal from '../../components/common/dialog/DeleteConfirmationModal';
import Loading from '../../components/common/LoadingIndicator';
import SvgIcon from '../../components/common/SvgIcon';
import { RDSPopover, RDSPopoverBtn } from '../../components/common/popover/index';


// --- Constants --- //
import ColorConstants from '../../constants/ColorConstants';
import DialogConstants from '../../constants/dialog/index';
import PagePathConstants from '../../constants/router/PagePathConstants';

// --- Actions --- //
import * as orderActionCreator from '../../actions/orderActionCreator';

// --- Icons --- //
import DollarIcon from '../../icons/DollarIcon';
import JPYIcon from '../../icons/JPYIcon';
import EURIcon from '../../icons/EURIcon';
import CalendarIcon from '../../icons/CalendarIcon';
import SearchIcon from '../../icons/SearchIcon';

// --- Selectors --- //
import * as OrderSelectors from '../../selectors/OrderSelectors';
import I18N from '../../i18n';
import LocalStorageUtil from '../../utils/LocalStorageUtil';
import LocalStorageConstants from '../../constants/LocalStorageConstants';



// const PAYMENT_FILTER_TYPE = ['all', 'succeeded', 'failed', 'non-payment'];
const ORDER_FILTER_TYPE = [{
    id: '',
    text: 'all'
}, {
    id: 1,
    text: 'succeeded'
},
//  {
//   id: 2,
//   text: 'notified'
// }, 
{
    id: 3,
    text: 'settle-accounts'
}, {
    id: 4,
    text: 'timeout'
}, {
    id: 0,
    text: 'unpaid'
}, {
    id: 5,
    text: 'pending-review'
}];

const CACHED_PARAM_KEY = LocalStorageConstants.ORDER_CACHED_PARAM;
class OrderPage extends Component {
    constructor(props, context) {
        super(props, context);
        const cachedParam = LocalStorageUtil.loadCachedParam(CACHED_PARAM_KEY);
        this.state = {
            orderParams: cachedParam ? cachedParam : {
                page: 1,
                per_page: 10,
                status: '',
                email: '',
                comment: ''
            },
            showLoading: false,
            showDeleteConfirmModal: false,
            showCancelConfirmModal: false,
            deleteItemId: '',
            cancelItem: '',
        };

        this.getOrderRecord = this.getOrderRecord.bind(this);
        this.handleOrderFilterClick = this.handleOrderFilterClick.bind(this);
        this.handlePageChange = this.handlePageChange.bind(this);
        this.toggleLoading = this.toggleLoading.bind(this);
        this.sendNotification = this.sendNotification.bind(this);
        this.routeToOrderDetailPage = this.routeToOrderDetailPage.bind(this);
        this.handleOrderSearchTextChange = this.handleOrderSearchTextChange.bind(this);
        this.toggleDeleteConfirmModal = this.toggleDeleteConfirmModal.bind(this);
        this.toggleCancelConfirmModal = this.toggleCancelConfirmModal.bind(this);
        this.handleUpdateOrderStatus = this.handleUpdateOrderStatus.bind(this);
        this.handleCancelSubscriptionOrder = this.handleCancelSubscriptionOrder.bind(this);
        this.handlePreviewOrderStatusWindow = this.handlePreviewOrderStatusWindow.bind(this);
        this.searchNewOrderRecord = this.searchNewOrderRecord.bind(this);
    }

    componentDidMount() {
        this.getOrderRecord();
    }

    handlePreviewOrderStatusWindow(id) {
        this.toggleDeleteConfirmModal();
        this.setState({ deleteItemId: id });
    }

    handleCancelOrder(record) {
        this.toggleCancelConfirmModal();
        this.setState({ cancelItem: record });
    }

    toggleDeleteConfirmModal() {
        this.setState({ showDeleteConfirmModal: !this.state.showDeleteConfirmModal });
    }

    toggleCancelConfirmModal() {
        this.setState({ showCancelConfirmModal: !this.state.showCancelConfirmModal });
    }

    handleUpdateOrderStatus() {
        const { orderActions } = this.props;
        const { deleteItemId } = this.state;

        orderActions && (deleteItemId && deleteItemId !== '') && orderActions.updateOrderPaymentStatus(this.state.deleteItemId)
            .then(data => {
                this.toggleDeleteConfirmModal();
                this.getOrderRecord();
                return;
            })
            .catch(this.toggleDeleteConfirmModal);
    }

    handleCancelSubscriptionOrder() {
        const { orderActions } = this.props;
        const { cancelItem } = this.state;

        orderActions && (cancelItem && cancelItem !== '') && orderActions.cancelOrderSubscription(cancelItem)
            .then(data => {
                this.toggleCancelConfirmModal();
                return;
            })
            .catch(this.toggleCancelConfirmModal);
    }

    handleOrderSearchTextChange(e, property) {
        const value = e && e.target.value;
        let { orderParams } = this.state;
        orderParams[property] = value;
        this.setState({ orderParams });
    }

    toggleLoading() {
        this.setState({ showLoading: !this.state.showLoading });
    }

    sendNotification(recordId) {
        const { orderActions } = this.props;
        orderActions && orderActions.informOrder(recordId);
    }

    routeToOrderDetailPage(orderNo) {
        const { history } = this.props;
        history && history.push(PagePathConstants.ORDER_DETAIL_PAGE.replace(':id', orderNo));
    }

    searchNewOrderRecord() {
        let { orderParams } = this.state;
        orderParams.page = 1;
        this.setState({ orderParams }, () => {
            this.getOrderRecord();
            LocalStorageUtil.saveCacheQueryParam(CACHED_PARAM_KEY, orderParams);
        });
    }

    getOrderRecord(params) {
        if (!params) {
            params = this.state.orderParams;
        }
        const { orderActions } = this.props;
        this.toggleLoading();
        return orderActions && orderActions.fetchOrderRecord(params)
            .then(data => {
                this.toggleLoading();
                return;
            })
            .catch(this.toggleLoading);
    }

    handleOrderFilterClick(item) {
        let { orderParams } = this.state;
        orderParams.status = item.id;
        orderParams.page = 1;
        this.setState({ orderParams }, () => {
            LocalStorageUtil.saveCacheQueryParam(CACHED_PARAM_KEY, orderParams);
        });
        this.getOrderRecord(orderParams);
    }

    renderOrderStatus(is_notify, status) {
        if (is_notify) {
            return (<div className='tw-rounded-[20px] tw-px-[12px] tw-py-[4px] tw-text-[11px] tw-py-[0px] tw-bg-status-notified-bg tw-flex tw-items-center tw-justify-center'>
                <span>{I18N.getText('notified')}</span>
            </div>)
        }
        switch (status) {
            case 1: {
                return (<div className='tw-rounded-[20px] tw-px-[12px] tw-py-[4px] tw-text-[11px] tw-bg-status-succeed-bg tw-flex tw-items-center tw-justify-center'>
                    <span>{I18N.getText('succeed')}</span>
                </div>)
            }
            case 3: {
                return (<div className='tw-rounded-[20px] tw-px-[12px] tw-py-[4px] tw-text-[11px] tw-bg-status-settle-accounts-bg tw-flex tw-items-center tw-justify-center'>
                    <span>{I18N.getText('settle-accounts')}</span>
                </div>)
            }
            case 4: {
                return (<div className='tw-rounded-[20px] tw-px-[12px] tw-py-[4px] tw-text-[11px] tw-bg-status-timeout-bg tw-flex tw-items-center tw-justify-center'>
                    <span>{I18N.getText('timeout')}</span>
                </div>)
            }
            case 0: {
                return (<div className='tw-rounded-[20px] tw-px-[12px] tw-py-[4px] tw-text-[11px] tw-bg-status-unpaid-bg tw-flex tw-items-center tw-justify-center'>
                    <span>{I18N.getText('unpaid')}</span>
                </div>)
            }
            case 5: {
                return (<div className='tw-rounded-[20px] tw-px-[12px] tw-py-[4px] tw-text-[11px] tw-bg-status-being-processed-bg tw-flex tw-items-center tw-justify-center'>
                    <span>{I18N.getText('pending-review')}</span>
                </div>)
            }
            default: {
                return (<div className='tw-rounded-[20px] tw-px-[12px] tw-py-[4px] tw-text-[11px] tw-py-[2px] tw-bg-status-being-processed-bg tw-flex tw-items-center tw-justify-center'>
                    <span>{I18N.getText('being-processed')}</span>
                </div>)
            }
        }
    }

    handlePageChange(e, page) {
        let { orderParams } = this.state;
        orderParams.page = page;
        this.getOrderRecord(orderParams)
            .then(() => {
                LocalStorageUtil.saveCacheQueryParam(CACHED_PARAM_KEY, orderParams);
                this.setState({ orderParams });
            });
    }

    renderCurrencyIcon(currency) {
        switch (currency) {
            case 0: {
                return (<DollarIcon size={16} color={ColorConstants.black[0]} className="tw-mb-[2px]" />);
            }
            case 1: {
                return (<EURIcon size={14} color={ColorConstants.black[0]} className="tw-mb-[2px]" />);
            }
            case 2: {
                return (<JPYIcon size={16} color={ColorConstants.black[0]} className="tw-mb-[2px]" />);
            }
            default: {
                return (<DollarIcon size={16} color={ColorConstants.black[0]} className="tw-mb-[2px]" />);
            }
        }
    }

    render() {
        const { orderList, orderTotalNum } = this.props;
        const { orderParams, showLoading, showDeleteConfirmModal, showCancelConfirmModal } = this.state;
        const total = Math.ceil(orderTotalNum / orderParams.per_page);
        return (<div className='tw-w-full tw-px-[20px]'>
            {showLoading && <Loading />}
            <DeleteConfirmationModal isDeleteModalOpen={showDeleteConfirmModal} toggle={this.toggleDeleteConfirmModal} handleDeleteConfirm={this.handleUpdateOrderStatus} sectionName={DialogConstants.EDIT_ORDER_STATUS} />
            <DeleteConfirmationModal isDeleteModalOpen={showCancelConfirmModal} toggle={this.toggleCancelConfirmModal} handleDeleteConfirm={this.handleCancelSubscriptionOrder} sectionName={DialogConstants.CANCEL_ORDER_STATUS} />
            <div className='tw-mx-auto tw-w-full tw-mt-[10px]'>
                <div className='tw-flex tw-items-center tw-justify-between'>
                    <div className='tw-flex tw-w-full'>
                        {ORDER_FILTER_TYPE.map(item => {
                            return <div key={item.id} className={classnames('tw-h-[40px] tw-flex tw-justify-center tw-items-center tw-rounded-[10px] tw-text-body-text-1 tw-text-[14px] tw-font-bold tw-mr-[30px] tw-text-[#717579]', { '!tw-text-brand-primary-light': orderParams.status === item.id })} onClick={() => this.handleOrderFilterClick(item)}>{I18N.getText(item.text)}</div>
                        })}
                    </div>
                    <div className='tw-mt-[10px] tw-w-full tw-flex tw-justify-center tw-items-center'>
                        <div className='tw-relative tw-w-[350px]'>
                            <Input
                                type="text"
                                placeholder={I18N.getText('order-email-search')}
                                className="tw-border-none tw-w-[350px] tw-h-[45px] tw-rounded-[30px] tw-py-[5px] tw-px-[20px] tw-text-[14px] custom-input tw-shadow-xl"
                                onChange={(e) => this.handleOrderSearchTextChange(e, 'email')}
                                value={orderParams.email} />
                            <SearchIcon color={ColorConstants.black[0]} size={20} className="tw-w-[20px] tw-h-[20px] tw-absolute tw-right-[20px] tw-top-[13px]" onClick={this.searchNewOrderRecord} />
                        </div>

                        <div className='tw-ml-[50px] tw-relative tw-w-[350px]'>
                            <Input
                                type="text"
                                placeholder={I18N.getText('order-aisle-search')}
                                className="tw-border-none tw-w-[350px] tw-h-[45px] tw-rounded-[30px] tw-py-[5px] tw-px-[20px] tw-text-[14px] custom-input tw-shadow-xl"
                                onChange={(e) => this.handleOrderSearchTextChange(e, 'comment')}
                                value={orderParams.comment} />
                            <SearchIcon color={ColorConstants.black[0]} size={20} className="tw-w-[20px] tw-h-[20px] tw-absolute tw-right-[20px] tw-top-[13px]" onClick={this.searchNewOrderRecord} />
                        </div>
                    </div>
                </div>
                <div className={classnames('tw-w-full tw-mt-[20px] tw-bg-white tw-rounded-[10px]', { 'tw-px-[20px] tw-py-[10px]': orderTotalNum > 0 })}>
                    {orderTotalNum > 0 && <div className='tw-w-full hs-order-table-container tw-py-[10px] '>
                        <div className='tw-flex tw-justify-between tw-items-center tw-text-center tw-font-bold tw-text-[12px] tw-mb-[10px] tw-h-[40px] tw-border tw-border-border-gray tw-rounded-[10px] tw-shadow-table'>
                            <div className='tw-w-[15%]'>{I18N.getText('mall-order-url')}</div>
                            <div className='tw-w-[15%]'>{I18N.getText('mall-payment-comment')}</div>
                            <div className='tw-w-[15%]'>{I18N.getText('order-email')}</div>
                            <div className='tw-w-[10%]'>{I18N.getText('amount')}</div>
                            <div className='tw-w-[15%]'>{I18N.getText('status')}</div>
                            <div className='tw-w-[15%]'>{I18N.getText('date')}</div>
                            <div className='tw-w-[15%]'>{I18N.getText('operation')}</div>
                        </div>
                        <div className='tw-w-full tw-text-[12px]'>
                            {orderList && orderList.map(record => {
                                return (<div className='tw-h-[50px] tw-overflow-hidden tw-flex tw-justify-between tw-items-center tw-mb-[10px] hs-table-row tw-border tw-border-border-gray tw-rounded-[10px] tw-shadow-table'>
                                    <div className='tw-w-[15%]'>
                                        <div className='tw-flex tw-justify-center tw-items-center tw-px-[10px]'>
                                            <span className='tw-text-[12px] tw-ml-[5px] hs-td-cell'>{record.url}</span>
                                        </div>
                                    </div>
                                    <div className='tw-w-[15%]'>
                                        <div className='tw-flex tw-justify-center tw-items-center tw-px-[10px]'>
                                            <div className='tw-max-w-[calc(100%-30px)] tw-text-[12px] tw-ml-[10px] hs-td-cell'>{record.comment}</div>
                                        </div>
                                    </div>

                                    <div className='tw-w-[15%]'>
                                        <div className='tw-flex tw-justify-center tw-items-center'>
                                            <span className='tw-text-[12px] hs-td-cell'>{record.email}</span>
                                        </div>
                                    </div>
                                    <div className='tw-w-[10%]'>
                                        <div className='tw-flex tw-justify-center tw-items-center'>
                                            {this.renderCurrencyIcon(record.curcrency)}
                                            <span className='tw-text-[12px] tw-ml-[5px] tw-font-bold  hs-td-cell'>{record.amount}</span>
                                        </div>
                                    </div>
                                    <div className='tw-w-[15%]'>
                                        <div className='tw-flex tw-justify-center tw-item-center tw-text-white'>{this.renderOrderStatus(record.is_notify, record.status)}</div>
                                    </div>
                                    <div className='tw-w-[15%]'>
                                        <div className='tw-flex tw-justify-center tw-items-center'>
                                            <CalendarIcon size={24} color={ColorConstants.black[0]} />
                                            <span className='tw-text-[12px] tw-ml-[5px]  hs-td-cell'>{record.createtime}</span>
                                        </div>
                                    </div>
                                    <div className='tw-w-[15%] tw-flex tw-justify-center tw-items-center'>
                                        {/* <div className='tw-w-auto tw-rounded-[20px] tw-px-[12px] tw-py-[4px] tw-text-[11px] tw-bg-status-unpaid-bg tw-flex tw-items-center tw-justify-center tw-text-white'>
                                            View Detail
                                        </div> */}

                                        <RDSPopover strategy='fixed' buttonAs='button' className='tw-inline-block tw-relative hs-popover'>
                                            <div className='tw-w-auto tw-rounded-[20px] tw-px-[12px] tw-py-[4px] tw-text-[11px] tw-bg-brand-primary-light tw-flex tw-items-center tw-justify-center tw-text-white'>
                                                View Detail
                                            </div>
                                            <div role="menu" className='tw-w-[110px] tw-absolute tw-top-[20px] tw-left-[-10px] tw-flex tw-items-center tw-justify-center tw-bg-white tw-px-[10px] tw-py-[6px] tw-rounded-[6px] tw-shadow-2xl'>
                                                <RDSPopoverBtn as='button' className={`dropdown-item tw-flex tw-justify-center tw-items-center`} onClick={() => this.routeToOrderDetailPage(record.client_orderNo)} >
                                                    <SvgIcon size={20} color={ColorConstants.brand.primary} name="eye-icon" />
                                                </RDSPopoverBtn>

                                                <RDSPopoverBtn as='button' className={`dropdown-item tw-flex tw-justify-center tw-items-center`} onClick={() => this.handlePreviewOrderStatusWindow(record.id)} >
                                                    <SvgIcon size={20} color={ColorConstants.brand.primary} name="edit-icon" />
                                                </RDSPopoverBtn>

                                                {record.status === 1 && <RDSPopoverBtn as='button' className={`dropdown-item tw-flex tw-justify-center tw-items-center`} onClick={() => this.handleCancelOrder(record)} >
                                                    <SvgIcon size={18} color={ColorConstants.brand.primary} name="cancel-order-icon" />
                                                </RDSPopoverBtn>}
                                            </div>
                                        </RDSPopover>
                                        {/* <SvgIcon size={18} color={ColorConstants.brand.primary} name="rds-message-icon" onClick={() => this.sendNotification(record.id)} /> */}
                                        {/* <SvgIcon size={20} color={ColorConstants.brand.primary} name="eye-icon" className="tw-ml-[10px]" onClick={() => this.routeToOrderDetailPage(record.client_orderNo)} />
                                        <SvgIcon size={20} color={ColorConstants.brand.primary} name="edit-icon" className="tw-ml-[10px]" onClick={() => this.handlePreviewOrderStatusWindow(record.id)} /> */}
                                    </div>
                                </div>)
                            })}
                        </div>
                    </div>}
                </div>
                {orderTotalNum === 0 && <div className='tw-w-full tw-flex tw-flex-col tw-justify-center tw-items-center'>
                    <SvgIcon size={200} name="non-record-icon" color={ColorConstants.brand.primary} />
                    <div className='tw-mt-[20px] tw-text-[16px] tw-font-medium'>{I18N.getText('no-records')}</div>
                </div>}
                {orderTotalNum > 0 && <div className='tw-w-full tw-flex tw-justify-center tw-mt-[20px]'>
                    <Pagination count={total} page={orderParams.page} onChange={this.handlePageChange} variant="outlined" color="primary" />
                </div>}
            </div>
        </div>);
    }
}

function mapStateToProps(state) {
    return {
        orderList: OrderSelectors.selectOrderList(state),
        orderTotalNum: OrderSelectors.selectOrderRecordTotalNum(state),
    };
}

function mapDispatchToProps(dispatch) {
    return {
        orderActions: bindActionCreators(orderActionCreator, dispatch),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(OrderPage);