import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Select from 'react-select';
import Switch from '@material-ui/core/Switch';

// --- Components --- //
import RDSButton from '../../components/common/RDSButton';
import SvgIcon from '../../components/common/SvgIcon';
import EDSInput from '../../components/common/EDSInput';
import Loading from '../../components/common/LoadingIndicator';

// --- Utils --- //
import * as FormValidationsUtil from '../../utils/FormValidationsUtil';

// --- Constants --- //
import PagePathConstants from '../../constants/router/PagePathConstants';
import ColorConstants from '../../constants/ColorConstants';

// --- Actions --- //
import * as blacklistActionCreator from '../../actions/blacklistActionCreator';
import * as stGroupActionCreator from '../../actions/stGroupActionCreator';

import I18N from '../../i18n';
import classNames from 'classnames';

const SD_CARD_STATUS_LIST = [
    { value: 2, label: I18N.getText('t7') },
    { value: 1, label: I18N.getText('t2') },
    { value: 0, label: I18N.getText('off') },
]


class UserEditPage extends Component {
    constructor(props, context) {
        super(props, context);

        this.state = {
            name: '',
            email: '',
            phone: '',
            ip: '',
            
            showError: false,
            showLoading: false,
            errMsg: '',
            errFiled: '',
            isEditing: false,
        };

        this.handlePropertyChange = this.handlePropertyChange.bind(this);
        this.toggleError = this.toggleError.bind(this);
        this.toggleLoading = this.toggleLoading.bind(this);
        this.toggleEditing = this.toggleEditing.bind(this);
        this.handleBackPage = this.handleBackPage.bind(this);
        this.editBlacklistInfo = this.editBlacklistInfo.bind(this);
        this.getBlacklistInfoById = this.getBlacklistInfoById.bind(this);
    }

    componentDidMount() {
        this.getBlacklistInfoById();
    }

    getBlacklistInfoById() {
        const { location, blacklistActions } = this.props;
        const blacklistId = location.pathname.split(`${PagePathConstants.BLACKLIST}/`)[1];
        if (blacklistId && blacklistId !== 'add') {
            this.toggleLoading();
            blacklistActions.getBlacklistInfoById(blacklistId)
                .then(res => {
                    const blacklistInfo = res;
                    this.setState({
                        ip: blacklistInfo.ip,
                        name: blacklistInfo.name,
                        email: blacklistInfo.email,
                        phone: blacklistInfo.phone,
                    });
                    this.toggleLoading();
                })
                .catch(() => {
                    this.toggleLoading();
                })
        }
    }

    toggleEditing() {
        this.setState({ isEditing: !this.state.isEditing });
    }

    toggleError() {
        this.setState({ showError: !this.state.showError });
    }

    toggleLoading() {
        this.setState({ showLoading: !this.state.showLoading });
    }

    handlePropertyChange(property, e) {
        const value = e && e.target.value;
        this.setState({ [property]: value, showError: false });
    }

    editBlacklistInfo() {
        const { blacklistActions, history, location } = this.props;
        const blacklistId = location.pathname.split(`${PagePathConstants.BLACKLIST}/`)[1];
        let { ip, name, email, phone } = this.state;
        // if (email.trim() !== '' && !FormValidationsUtil.isValidEmail(email.trim())) {
        //     this.setState({ showError: true, errFiled: 'email', errMsg: I18N.getText('inValid-email') });
        //     return;
        // }
        const params = {
            ip,
            name,
            email,
            phone
        }
        if (blacklistId && blacklistId !== 'add') {
            params['id'] = blacklistId;
        }
        // const formData = new FormData();
        // Object.keys(params).forEach((key) => {
        //     formData.append(`stripe[${key}]`, params[key]);
        // });
        this.toggleEditing();
        blacklistActions && blacklistActions.editBlacklistInfoById(params)
            .then(() => {
                history && history.push(PagePathConstants.BLACKLIST);
            })
            .catch(err => {
                this.toggleEditing();
            });
    }

    handleBackPage() {
        const { history } = this.props;
        history && history.goBack();
    }

    render() {
        const { isEditing, showLoading, ip, name, email, phone, showError, errMsg, errFiled } = this.state;
        const { location } = this.props;
        const blacklistId = location.pathname.split(`${PagePathConstants.BLACKLIST}/`)[1];
        const isEditSTCard = (blacklistId && blacklistId !== 'add');
        return <div className='tw-w-full tw-px-[50px] tw-py-[50px]'>
            {showLoading && <Loading />}
            <div className='tw-rounded-[30px] tw-bg-white'>
                <div className='tw-flex tw-items-center  tw-px-[30px] tw-py-[24px] tw-border-b' onClick={this.handleBackPage}>
                    <SvgIcon name="rds-back-icon" color={ColorConstants.black[0]} size={24} />
                    <span className='tw-ml-[10px] tw-text-[18px] tw-font-bold' >{I18N.getText('back')}</span>
                </div>
                <div className='tw-text-[14px] tw-px-[30px] tw-py-[30px]'>
                    <div className='tw-flex tw-items-center'>
                        <div className='tw-w-[20%] tw-font-bold' >{I18N.getText('ip')}</div>
                        <EDSInput
                            className={classNames(`tw-py-[10px] tw-px-[20px] tw-rounded-[30px] tw-border tw-shadow-xl tw-text-base tw-border-border-gray tw-font-medium focus:tw-border-brand-primary focus:tw-shadow-none placeholder:tw-text-body-text-secondary placeholder:tw-font-normal placeholder:tw-text-[12px]`, { 'tw-border-light-red': (showError && errFiled === 'comment') })}
                            placeholder={I18N.getText('ip-tip')}
                            value={ip || ""}
                            type="text"
                            onChange={(e) => this.handlePropertyChange('ip', e)}
                        />
                    </div>
                    <div className='tw-flex tw-items-center tw-mt-[30px]'>
                        <div className='tw-w-[20%] tw-font-bold' >{I18N.getText('name')}</div>
                        <EDSInput
                            className={classNames(`tw-py-[10px] tw-px-[20px] tw-rounded-[30px] tw-border tw-shadow-xl tw-text-base tw-border-border-gray tw-font-medium focus:tw-border-brand-primary focus:tw-shadow-none placeholder:tw-text-body-text-secondary placeholder:tw-font-normal placeholder:tw-text-[12px]`, { 'tw-border-light-red': (showError && errFiled === 'comment') })}
                            placeholder={I18N.getText('name-tip')}
                            value={name || ""}
                            type="text"
                            onChange={(e) => this.handlePropertyChange('name', e)}
                        />
                    </div>

                    <div className='tw-flex tw-items-center tw-mt-[30px]'>
                        <div className='tw-w-[20%] tw-font-bold' >{I18N.getText('email')}</div>
                        <EDSInput
                            className={classNames(`tw-py-[10px] tw-px-[20px] tw-rounded-[30px] tw-border tw-shadow-xl tw-text-base tw-border-border-gray tw-font-medium focus:tw-border-brand-primary focus:tw-shadow-none placeholder:tw-text-body-text-secondary placeholder:tw-font-normal placeholder:tw-text-[12px]`, { 'tw-border-light-red': (showError && errFiled === 'comment') })}
                            placeholder={I18N.getText('blocked-email-tip')}
                            value={email || ""}
                            type="text"
                            onChange={(e) => this.handlePropertyChange('email', e)}
                        />
                    </div>
                    <div className='tw-flex tw-items-center tw-mt-[30px]'>
                        <div className='tw-w-[20%] tw-font-bold' >{I18N.getText('phone')}</div>
                        <EDSInput
                            className={classNames(`tw-py-[10px] tw-px-[20px] tw-rounded-[30px] tw-border tw-shadow-xl tw-text-base tw-border-border-gray tw-font-medium focus:tw-border-brand-primary focus:tw-shadow-none placeholder:tw-text-body-text-secondary placeholder:tw-font-normal placeholder:tw-text-[12px]`, { 'tw-border-light-red': (showError && errFiled === 'comment') })}
                            placeholder={I18N.getText('blocked-phone-tip')}
                            value={phone || ""}
                            type="text"
                            onChange={(e) => this.handlePropertyChange('phone', e)}
                        />
                    </div>
                    
                    {showError && <div className='tw-mt-[20px] tw-text-[14px] tw-text-error-text'>{errMsg}</div>}
                    <div className='tw-flex tw-justify-center tw-items-center tw-mt-[30px]'>
                        <RDSButton
                            loading={isEditing}
                            disabled={isEditing || ip.trim() === '' || name.trim() === '' || email.trim() === '' || phone.trim() === ''}
                            onClick={this.editBlacklistInfo}
                            className="tw-text-white" >{I18N.getText('edit')}</RDSButton>
                    </div>
                </div>
            </div>
        </div>;
    }
}

function mapStateToProps(state) {
    return {
    };
}

function mapDispatchToProps(dispatch) {
    return {
        blacklistActions: bindActionCreators(blacklistActionCreator, dispatch),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(UserEditPage);