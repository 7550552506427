const UserProfileConstants = {
  ID: 'id',
  GROUP_ID: 'group_id',
  POINT: 'point',
  USERNAME: 'username',
  NICKNAME: 'nickname',
  MOBILE: 'mobile',
  AVATAR: 'avatar',
  MONEY: 'money',
  SCORE: 'score',
  USER_ID: 'user_id',
  CREATE_TIME: 'createtime',
  EXPIRE_TIME: 'expiretime',
  EXPIRES_IN: 'expires_in',
}

class UserHelper {

  static getMoneyBalance(user) {
    if (!user) return;
    return user[UserProfileConstants.MONEY];
  }

  static isAdminUser(user) {
    if (!user) return false;
    // 0:老外  1:代理  2:客户(商户)  3:管理员
    return user[UserProfileConstants.GROUP_ID] === 3;
  }

  static isCustomerUser(user) {
    if (!user) return false;
    // 0:老外  1:代理  2:客户(商户)  3:管理员
    return user[UserProfileConstants.GROUP_ID] === 2;
  }

  static isForeigner(user) {
    if (!user) return false;
    // 0:老外  1:代理  2:客户(商户)  3:管理员
    return user[UserProfileConstants.GROUP_ID] === 0;
  }
}

export default UserHelper;
