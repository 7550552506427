import { createSelector } from 'reselect';
import StoreStateConstants from '../constants/store/StoreStateConstants';
import ReducerNameConstants from '../constants/reducers/ReducerNameConstants';

const selectOrderReducer  = state => state.get(ReducerNameConstants.ORDER);

export const selectOrderList = createSelector(
  [selectOrderReducer],
  (orderReducer) => {
    const orderList = orderReducer && orderReducer.get(StoreStateConstants.ORDER_LIST);
    return orderList;
  },
);

export const selectOrderRecordTotalNum = createSelector(
  [selectOrderReducer],
  (orderReducer) => {
    const totalNum = orderReducer && orderReducer.get(StoreStateConstants.ORDER_TOTAL_COUNT);
    return totalNum;
  },
);  

export const selectFailedOrderList = createSelector(
  [selectOrderReducer],
  (orderReducer) => {
    const failedOrderList = orderReducer && orderReducer.get(StoreStateConstants.FAILED_ORDER_LIST);
    return failedOrderList;
  },
);
