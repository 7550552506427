import React from "react";
import Colors from '../constants/ColorConstants';

const RdsRemoveIcon = ({
  size = 32,
  viewBox = "0 0 32 32",
  color =  Colors.brand.primary,
  className,
  ...props
}) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 1024 1024"
    p-id="2399"
    className={className}
    fill="#ffffff"
    {...props}
  >
    <path d="M512 11.234c-274.205 0-496.508 222.348-496.508 496.53 0 274.228 222.303 496.486 496.508 496.486 274.25 0 496.508-222.258 496.508-496.485 0-274.205-222.258-496.53-496.508-496.53z m0 928.352c-238.503 0-431.844-193.296-431.844-431.821C80.156 269.262 273.497 75.876 512 75.876c238.48 0 431.866 193.364 431.866 431.889S750.48 939.586 512 939.586z m218.214-598.65l-57.61-57.788-162.132 161.57-164.087-159.391-56.868 58.553 163.166 158.492-172.76 172.199 57.587 57.766L511.214 559.24l175.995 170.94 56.913-58.53-175.075-170.087z" fill={color} p-id="2400"></path>
  </svg>
);

export default RdsRemoveIcon;
