import ActionConstants from '../constants/actions/ActionConstants';
import * as CoinAPI from '../apis/CoinAPI';
import { showErrorGlobalAlert, showSuccessGlobalAlert } from './globalAlertActionCreator';

export const updateCoinListAction = (coinList, total) => ({
    type: ActionConstants.UPDATE_COIN_LIST,
    payload: { data: coinList, total: total },
});

export const fetchCoinList = (params) => {
    return function (dispatch, getState) {
        return new Promise((resolve, reject) => {
            CoinAPI.fetchCoinList(params)
                .then((res) => {
                    if (res.status === 200) {
                        const coinList = res.data.data.list;
                        const total = res.data.data.total;
                        dispatch(updateCoinListAction(coinList, total));
                        return resolve('success');
                    }
                })
                .catch((err) => {
                    return reject(err);
                });
        });
    };
}

export const getCoinInfoById = (id) => {
    return function (dispatch, getState) {
        return new Promise((resolve, reject) => {
            CoinAPI.getCoinInfoById(id)
                .then((res) => {
                    if (res.status === 200) {
                        return resolve(res.data.data);
                    }
                })
                .catch((err) => {
                    return reject(err);
                });
        });
    };
}

export const editCoinInfoById = (params) => {
    return function (dispatch, getState) {
        return new Promise((resolve, reject) => {
            CoinAPI.editCoinInfoById(params)
                .then((res) => {
                    if (res.status === 200) {
                        dispatch(showSuccessGlobalAlert('edit-ach-info-succeed'));
                        return resolve('success');
                    }
                })
                .catch((err) => {
                    if (err.response.data.msg === 'telId error') {
                        return reject('repeated-telId');
                    } else if (err.response.data.msg === 'card error') {
                        return reject('repeated-cardId');
                    } else {
                        dispatch(showErrorGlobalAlert('edit-ach-info-failed'));
                        return reject(err.response.data.msg);
                    }
                });
        });
    };
}

export const createNewCoin = (params) => {
    return function (dispatch, getState) {
        return new Promise((resolve, reject) => {
            CoinAPI.createCoin(params)
                .then((res) => {
                    if (res.status === 200) {
                        dispatch(showSuccessGlobalAlert('create-ach-info-succeed'));
                        return resolve('success');
                    }
                })
                .catch((err) => {
                    if (err.response.data.msg === 'telId error') {
                        return reject('repeated-telId');
                    } else if (err.response.data.msg === 'card error') {
                        return reject('repeated-cardId');
                    } else {
                        dispatch(showErrorGlobalAlert('create-ach-info-failed'));
                        return reject(err.response.data.msg);
                    }

                });
        });
    };
}

export const deleteCoin = (id) => {
    return function (dispatch, getState) {
        return new Promise((resolve, reject) => {
            CoinAPI.deleteCoin(id)
                .then((res) => {
                    if (res.status === 200) {
                        dispatch(showSuccessGlobalAlert('delete-ach-info-succeed'));
                        return resolve('success');
                    }
                })
                .catch((err) => {
                    dispatch(showErrorGlobalAlert('delete-ach-info-failed'));
                    return reject(err.response.data.msg);
                });
        });
    };
}

