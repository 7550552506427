import ActionConstants from '../constants/actions/ActionConstants';
import * as IbanCardAPI from '../apis/IbanCardAPI';
import { showErrorGlobalAlert, showSuccessGlobalAlert } from './globalAlertActionCreator';

export const updateIbanCardListAction = (ibanCardList, total) => ({
    type: ActionConstants.UPDATE_IBAN_CARD_LIST,
    payload: { data: ibanCardList, total: total },
});

export const fetchIbanCardList = (params) => {
    return function (dispatch, getState) {
        return new Promise((resolve, reject) => {
            IbanCardAPI.fetchIbanCardList(params.page, params.per_page, params.keywords)
                .then((res) => {
                    if (res.status === 200) {
                        const ibanCardList = res.data.data.list;
                        const total = res.data.data.total;
                        dispatch(updateIbanCardListAction(ibanCardList, total));
                        return resolve('success');
                    }
                })
                .catch((err) => {
                    return reject(err);
                });
        });
    };
}

export const getIbanCardInfoById = (id) => {
    return function (dispatch, getState) {
        return new Promise((resolve, reject) => {
            IbanCardAPI.getIbanCardInfoById(id)
                .then((res) => {
                    if (res.status === 200) {
                        return resolve(res.data.data);
                    }
                })
                .catch((err) => {
                    return reject(err);
                });
        });
    };
}

export const editIbanCardInfoById = (params) => {
    return function (dispatch, getState) {
        return new Promise((resolve, reject) => {
            IbanCardAPI.editIbanCardInfoById(params)
                .then((res) => {
                    if (res.status === 200) {
                        dispatch(showSuccessGlobalAlert('edit-iban-info-succeed'));
                        return resolve('success');
                    }
                })
                .catch((err) => {
                    if (err.response.data.msg === 'telId error') {
                        return reject('repeated-telId');
                    } else if (err.response.data.msg === 'card error') {
                        return reject('repeated-cardId');
                    } else {
                        dispatch(showErrorGlobalAlert('edit-iban-info-failed'));
                        return reject(err.response.data.msg);
                    }
                });
        });
    };
}

export const createNewIbanCard = (params) => {
    return function (dispatch, getState) {
        return new Promise((resolve, reject) => {
            IbanCardAPI.createIbanCard(params)
                .then((res) => {
                    if (res.status === 200) {
                        dispatch(showSuccessGlobalAlert('create-iban-info-succeed'));
                        return resolve('success');
                    }
                })
                .catch((err) => {
                    if (err.response.data.msg === 'telId error') {
                        return reject('repeated-telId');
                    } else if (err.response.data.msg === 'card error') {
                        return reject('repeated-cardId');
                    } else {
                        dispatch(showErrorGlobalAlert('create-iban-info-failed'));
                        return reject(err.response.data.msg);
                    }

                });
        });
    };
}