import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Input } from 'reactstrap';
import { bindActionCreators } from 'redux';
import { Table } from 'reactstrap';
import Pagination from '@material-ui/lab/Pagination';
import moment from 'moment';

// --- Components --- //
import Loading from '../components/common/LoadingIndicator';
import SvgIcon from '../components/common/SvgIcon';
import LocaleCalendar from '../components/common/LocaleCalendar';
import RDSButton from '../components/common/RDSButton';

// --- Helpers --- //
import UserHelper from '../helpers/UserHelper';

// --- Utils --- //
import { getMMDDFormattedDate } from '../utils/DateTimeUtil';

// --- Actions --- //
import * as paymentActionCreator from '../actions/paymentActionCreator';

// --- Selectors --- //
import * as PaymentSelectors from '../selectors/PaymentSelectors';
import * as UserSelectors from '../selectors/UserSelectors';

// --- Icons --- //
import DollarIcon from '../icons/DollarIcon';
import XIcon from '../icons/XIcon';
import ArrowRightIcon from '../icons/ArrowRightIcon';
import CalendarIcon from '../icons/CalendarIcon';
import CommentIcon from '../icons/CommentIcon';

// --- Constants --- //
import ColorConstants from '../constants/ColorConstants';
import I18N from '../i18n';



class PaymentPage extends Component {
  constructor(props, context) {
    super(props, context);

    this.state = {
      paymentParams: {
        page: 1,
        per_page: 5,
        keywords: '',
        min_date: null,
        max_date: null,
      },

      isStartDateCalendarOpen: false,
      selectedStartDateText: null,

      isEndDateCalendarOpen: false,
      selectedEndDateText: null,
      showLoading: false,
    };

    this.getPaymentRecord = this.getPaymentRecord.bind(this);
    this.handlePageChange = this.handlePageChange.bind(this);
    this.handleMinDateChange = this.handleMinDateChange.bind(this);
    this.handleMaxDateChange = this.handleMaxDateChange.bind(this);
    this.handleRemoveMinDate = this.handleRemoveMinDate.bind(this);
    this.handleRemoveMaxDate = this.handleRemoveMaxDate.bind(this);
    this.handleRemoveKeywords = this.handleRemoveKeywords.bind(this);
    this.handleKeywordsChange = this.handleKeywordsChange.bind(this);
    this.toggleLoading = this.toggleLoading.bind(this);
    this.toggleStartDateCalendar = this.toggleStartDateCalendar.bind(this);
    this.toggleEndDateCalendar = this.toggleEndDateCalendar.bind(this);
    this.handleSearchPayment = this.handleSearchPayment.bind(this);
  }

  componentDidMount() {
    this.getPaymentRecord();
  }

  handleSearchPayment() {
    let { paymentParams } = this.state;
    if (paymentParams.min_date || paymentParams.max_date || (paymentParams.keywords && paymentParams.keywords.trim() !== '') ) {
      paymentParams.page = 1;
    }
    this.getPaymentRecord(paymentParams)
      .then(() => {
        this.setState({ paymentParams });
      });
  }

  toggleLoading() {
    this.setState({ showLoading: !this.state.showLoading });
  }

  getPaymentRecord(params) {
    if (!params) {
      params = this.state.paymentParams;
    }
    const { paymentActions } = this.props;
    this.toggleLoading();
    return paymentActions && paymentActions.fetchPaymentRecord(params)
      .then(data => {
        this.toggleLoading();
        return;
      })
      .catch(this.toggleLoading);
  }

  handleKeywordsChange(event) {
    const keywords = event.currentTarget.value;
    let { paymentParams } = this.state;
    paymentParams.keywords = keywords;
    this.setState({ paymentParams });
  }

  handleRemoveMinDate(e) {
    e && e.preventDefault();
    e && e.stopPropagation();
    let { paymentParams } = this.state;
    paymentParams.min_date = null;
    this.setState({
      selectedStartDateText: null,
      paymentParams
    })
  }

  handleRemoveMaxDate(e) {
    e && e.preventDefault();
    e && e.stopPropagation();
    let { paymentParams } = this.state;
    paymentParams.max_date = null;
    this.setState({
      selectedEndDateText: null,
      paymentParams
    })
  }

  handleRemoveKeywords() {
    let { paymentParams } = this.state;
    paymentParams.keywords = '';
    this.setState({ paymentParams });
  }

  handleMinDateChange(date) {
    let { paymentParams } = this.state;
    paymentParams.min_date = getMMDDFormattedDate(date);
    paymentParams.page = 1;
    this.setState({
      selectedStartDateText: getMMDDFormattedDate(date),
      paymentParams,
    });
    this.toggleStartDateCalendar();
  }

  handleMaxDateChange(date) {
    let { paymentParams } = this.state;
    paymentParams.max_date = getMMDDFormattedDate(date);
    paymentParams.page = 1;
    this.setState({
      selectedEndDateText: getMMDDFormattedDate(date),
      paymentParams,
    });
    this.toggleEndDateCalendar();
  }

  toggleStartDateCalendar() {
    this.setState({
      isStartDateCalendarOpen: !this.state.isStartDateCalendarOpen,
      isEndDateCalendarOpen: false
    });
  }

  toggleEndDateCalendar() {
    this.setState({
      isStartDateCalendarOpen: false,
      isEndDateCalendarOpen: !this.state.isEndDateCalendarOpen
    });
  }

  handlePageChange(e, page) {
    let { paymentParams } = this.state;
    paymentParams.page = page;
    this.getPaymentRecord(paymentParams)
      .then(() => {
        this.setState({ paymentParams });
      });
  }

  render() {
    const { paymentList, paymentTotalNum } = this.props;
    const { paymentParams, showLoading, isStartDateCalendarOpen, selectedStartDateText, isEndDateCalendarOpen, selectedEndDateText } = this.state;
    const total = Math.ceil(paymentTotalNum / paymentParams.per_page);
    const { currentUser } = this.props;
    const isAdmin = UserHelper.isAdminUser(currentUser)


    const selectMinDateLabel = I18N.getText('select_min_date');
    const selectedStartDateTextLabel = selectedStartDateText || selectMinDateLabel;
    const selectedStartDateForCalendar = selectedStartDateText
      ? new Date(selectedStartDateText)
      : new Date()

    const selectMaxDateLabel = I18N.getText('select_max_date');
    const selectedEndDateTextLabel = selectedEndDateText || selectMaxDateLabel;
    const selectedEndDateForCalendar = selectedEndDateText
      ? new Date(selectedEndDateText)
      : new Date()

    return (<div className='tw-w-full tw-px-[50px]'>
      {showLoading && <Loading />}
      <div className='tw-mx-auto tw-w-full tw-mt-[30px]'>
        <div className='tw-flex tw-justify-between tw-items-center tw-w-full tw-mt-[20px] tw-relative'>
          <div className='tw-flex tw-items-center'>
            <div className='tw-flex tw-items-center'>
              <div className='tw-relative'>
                <div className='tw-w-full tw-h-[45px]' onClick={this.toggleStartDateCalendar}>
                  <span className='tw-w-[170px] tw-h-[45px] tw-flex tw-justify-between tw-items-center tw-rounded-[30px] tw-text-[14px] tw-px-[20px] tw-bg-white tw-shadow-xl'>
                    {selectedStartDateTextLabel}
                    <XIcon className="tw-w-[20px] tw-h-[20px]" size={20} onClick={this.handleRemoveMinDate} color={ColorConstants.black[0]} />
                  </span>
                </div>
                {isStartDateCalendarOpen && <LocaleCalendar
                  value={selectedStartDateForCalendar}
                  onChange={this.handleMinDateChange}
                  maxDate={selectedEndDateText ? new Date(selectedEndDateText) : new Date(moment().subtract(1, 'days'))}
                  className="tw-mt-[20px] tw-absolute tw-left-0 tw-top-[40px] tw-bg-white tw-w-[424px] tw-px-[30px] tw-py-[30px]"
                />}
              </div>
              <ArrowRightIcon className="tw-mx-[20px] tw-w-[16px] tw-h-[16px]" color={ColorConstants.black[0]} size={16} />
              <div className='tw-relative'>
                <div className='tw-w-full tw-h-[45px]' onClick={this.toggleEndDateCalendar}>
                  <span className='tw-w-[170px] tw-h-[45px] tw-flex tw-justify-between tw-items-center tw-rounded-[30px] tw-text-[14px] tw-px-[20px] tw-bg-white tw-shadow-xl'>
                    {selectedEndDateTextLabel}
                    <XIcon className="tw-w-[20px] tw-h-[20px]" size={20} onClick={this.handleRemoveMaxDate} color={ColorConstants.black[0]} />
                  </span>
                </div>
                {isEndDateCalendarOpen && <LocaleCalendar
                  value={selectedEndDateForCalendar}
                  onChange={this.handleMaxDateChange}
                  minDate={selectedStartDateText ? new Date(selectedStartDateText) : null}
                  maxDate={new Date(moment())}
                  className="tw-mt-[20px] tw-absolute tw-left-0 tw-top-[40px] tw-bg-white tw-w-[424px] tw-px-[30px] tw-py-[30px]"
                />}
              </div>
            </div>

            {isAdmin && <div className='tw-relative tw-ml-[50px]'>
              <Input
                type="text"
                placeholder={I18N.getText('payment-account-placeholder')}
                className="tw-border-none tw-w-[350px] tw-h-[45px] tw-rounded-[30px] tw-py-[5px] tw-px-[20px] tw-text-[14px] custom-input tw-shadow-xl"
                onChange={this.handleKeywordsChange}
                value={paymentParams.keywords} />
              <XIcon color={ColorConstants.black[0]} size={20} className="tw-w-[20px] tw-h-[20px] tw-absolute tw-right-[20px] tw-top-[13px]" onClick={this.handleRemoveKeywords} />
            </div>}
          </div>
          <RDSButton className="tw-text-white tw-py-[12px]" disabled={showLoading} loading={showLoading} onClick={this.handleSearchPayment}>{I18N.getText('search')}</RDSButton>
        </div>

        <div className='tw-w-full tw-mt-[30px]'>
          {paymentTotalNum > 0 && <div className='tw-w-full hs-order-table-container'>
            <div className='tw-flex tw-justify-between tw-items-center tw-rounded-[30px] tw-overflow-hidden tw-bg-white tw-h-[70px] tw-mb-[20px] tw-text-center tw-font-bold tw-shadow-xl'>
              {isAdmin && <div className='tw-w-[20%]'>{I18N.getText('account')}</div>}
              <div className='tw-w-[30%]'>{I18N.getText('amount')}</div>
              <div className='tw-w-[30%]'>{I18N.getText('date')}</div>
              <div className='tw-w-[30%]'>{I18N.getText('comment')}</div>
            </div>
            <div className='tw-w-full tw-text-[16px]'>
              {paymentList && paymentList.map(record => {
                return (<div className='tw-flex tw-justify-between tw-items-center tw-rounded-[30px] tw-overflow-hidden tw-bg-white tw-h-[70px] tw-mb-[20px] tw-text-center tw-shadow-xl hs-table-row'>
                  {isAdmin && <div className='tw-w-[20%]  hs-td-cell'>{record.username}</div>}
                  <div className='tw-w-[30%] tw-flex tw-justify-center tw-items-center'>
                    <DollarIcon size={18} color={ColorConstants.black[0]} className="tw-mb-[3px]" />
                    <span className='tw-font-bold hs-td-cell'>{record.money}</span>
                  </div>
                  <div className='tw-w-[30%] tw-flex tw-justify-center tw-items-center'>
                    <CalendarIcon size={24} color={ColorConstants.black[0]} className="tw-mb-[2px]" />
                    <span className='tw-text-[14px] tw-ml-[5px] hs-td-cell'>{record.createtime}</span>
                  </div>
                  <div className='tw-w-[30%] tw-flex tw-justify-center tw-items-center'>
                    <CommentIcon size={24} color={ColorConstants.black[0]} />
                    <span className='tw-text-[14px] tw-ml-[5px] hs-td-cell'>{record.memo}</span>
                  </div>
                </div>)
              })}
            </div>
          </div>}
        </div>
        {paymentTotalNum === 0 && <div className='tw-w-full tw-flex tw-flex-col tw-justify-center tw-items-center'>
          <SvgIcon size={200} name="non-record-icon" />
          <div className='tw-mt-[20px] tw-text-[16px] tw-font-medium'>{I18N.getText('no-records')}</div>
        </div>}
        {paymentTotalNum > 0 && <div className='tw-mt-[40px] tw-w-full tw-flex tw-justify-center'>
          <Pagination count={total} page={paymentParams.page} onChange={this.handlePageChange} variant="outlined" color="primary" />
        </div>}
      </div>
    </div>);
  }
}

function mapStateToProps(state) {
  return {
    paymentList: PaymentSelectors.selectPaymentList(state),
    paymentTotalNum: PaymentSelectors.selectPaymentRecordTotalNum(state),
    currentUser: UserSelectors.selectCurrentUser(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    paymentActions: bindActionCreators(paymentActionCreator, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(PaymentPage);

