import { createSelector } from 'reselect';
import StoreStateConstants from '../constants/store/StoreStateConstants';
import ReducerNameConstants from '../constants/reducers/ReducerNameConstants';

const selectPaymentReducer  = state => state.get(ReducerNameConstants.PAYMENT);

export const selectPaymentList = createSelector(
  [selectPaymentReducer],
  (paymentReducer) => {
    const paymentList = paymentReducer && paymentReducer.get(StoreStateConstants.PAYMENT_LIST);
    return paymentList;
  },
);

export const selectPaymentRecordTotalNum = createSelector(
  [selectPaymentReducer],
  (paymentReducer) => {
    const totalNum = paymentReducer && paymentReducer.get(StoreStateConstants.PAYMENT_TOTAL_COUNT);
    return totalNum;
  },
);  
