import { BASE_URL } from "../constants/api/ApiEndpointConstants";
import * as UploadAPI from "../apis/UploadAPI";
import { showErrorGlobalAlert } from './globalAlertActionCreator';

export const uploadImgToGetUrl = (base64Str) => {
  return function (dispatch, getState) {
    return new Promise((resolve, reject) => {
      UploadAPI.uploadImg(base64Str)
        .then((res) => {
          const imgUrl = BASE_URL + res.data.data.url;
          return resolve(imgUrl);
        })
        .catch((err) => {
          if (err && err.response && err.response.data) {
            dispatch(showErrorGlobalAlert(err.response.data.msg));
          } else {
            dispatch(showErrorGlobalAlert('img-upload-error'));
          }
          return reject(err);
        });
    });
  };
};