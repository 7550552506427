import React, { Component } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';

import { bindActionCreators } from 'redux';
import { div } from 'reactstrap';
import Pagination from '@material-ui/lab/Pagination';

// --- Components --- //
import Loading from '../../components/common/LoadingIndicator';
import SvgIcon from '../../components/common/SvgIcon';
import EDSInput from '../../components/common/EDSInput';

// --- Icons --- //
import SearchIcon from '../../icons/SearchIcon';
import EmailIcon from '../../icons/EmailIcon';
import DollarIcon from '../../icons/DollarIcon';
import PointIcon from '../../icons/PointIcon';
import CalendarIcon from '../../icons/CalendarIcon';
import UserIcon from '../../icons/UserIcon';
import RoleIcon from '../../icons/RoleIcon';

// --- Constants --- //
import ColorConstants from '../../constants/ColorConstants';

// --- Actions --- //
import * as userActionCreator from '../../actions/userActionCreator';

// --- Selectors --- //
import * as UserSelectors from '../../selectors/UserSelectors';
import I18N from '../../i18n';
import RDSButton from '../../components/common/RDSButton';
import PagePathConstants from '../../constants/router/PagePathConstants';
import LocalStorageConstants from '../../constants/LocalStorageConstants';
import LocalStorageUtil from '../../utils/LocalStorageUtil';


const CACHED_PARAM_KEY = LocalStorageConstants.USER_CACHED_PARAM;
class UserPage extends Component {
  constructor(props, context) {
    super(props, context);
    const cachedParam = LocalStorageUtil.loadCachedParam(CACHED_PARAM_KEY);
    this.state = {
      userListParams: cachedParam ? cachedParam : {
        page: 1,
        per_page: 10,
        keywords: ''
      },
      showLoading: false,
    };

    this.getUserList = this.getUserList.bind(this);
    this.handleUserFilterClick = this.handleUserFilterClick.bind(this);
    this.handlePageChange = this.handlePageChange.bind(this);
    this.toggleLoading = this.toggleLoading.bind(this);
    this.handleUsernameFilterChange = this.handleUsernameFilterChange.bind(this);
    this.handleEditUser = this.handleEditUser.bind(this);
    this.createNewUser = this.createNewUser.bind(this);
  }

  componentDidMount() {
    this.getUserList();
  }

  handleEditUser(id) {
    const { history } = this.props;
    history && history.push(PagePathConstants.USER_EDIT.replace(':id', id));
  }

  toggleLoading() {
    this.setState({ showLoading: !this.state.showLoading });
  }

  handleUsernameFilterChange(e) {
    const name = e && e.target.value;
    const { userListParams } = this.state;
    userListParams.keywords = name;
    this.setState({ userListParams });
  }

  getUserList(params) {
    if (!params) {
      params = this.state.userListParams;
    }
    const { userActions } = this.props;
    this.toggleLoading();
    return userActions && userActions.fetchUserList(params)
      .then(data => {
        this.toggleLoading();
        return;
      })
      .catch(this.toggleLoading);
  }

  handleUserFilterClick() {
    let { userListParams } = this.state;
    userListParams.page = 1;
    this.setState({ userListParams });
    LocalStorageUtil.saveCacheQueryParam(CACHED_PARAM_KEY, userListParams);
    this.getUserList(userListParams);
  }

  handlePageChange(e, page) {
    let { userListParams } = this.state;
    userListParams.page = page;
    this.getUserList(userListParams)
      .then(() => {
        LocalStorageUtil.saveCacheQueryParam(CACHED_PARAM_KEY, userListParams);
        this.setState({ userListParams });
      });
  }

  createNewUser() {
    const { history } = this.props;
    history && history.push(PagePathConstants.USER_ADD);
  }

  render() {
    const { userList, userTotalNum } = this.props;
    const { userListParams, showLoading } = this.state;
    const total = Math.ceil(userTotalNum / userListParams.per_page);
    return (<div className='tw-w-full tw-px-[30px]'>
      {showLoading && <Loading />}
      <div className='tw-w-full tw-mt-[30px]'>
        <div className='tw-flex tw-justify-between tw-items-center tw-w-full tw-mt-[20px] hs-userlist-filter-container'>
          <div className='tw-relative'>
            <EDSInput className="tw-w-[450px] tw-py-[12px] tw-rounded-[30px] tw-px-[20px] tw-border-none tw-shadow-xl"
              value={userListParams.keywords}
              type="text"
              placeholder={I18N.getText('username-placeholder')}
              onChange={this.handleUsernameFilterChange}
            />
            <SearchIcon size={24} color={ColorConstants.black[0]} className="tw-absolute tw-right-[20px] tw-bottom-[12px] tw-w-[24px] tw-h-[24px]" onClick={this.handleUserFilterClick} />
          </div>
          <div className='tw-w-[300px] tw-flex tw-justify-end tw-items-center'>
            {/* <RDSButton className="tw-text-white tw-mr-[30px]" onClick={this.handleUserFilterClick}>{I18N.getText('search')}</RDSButton> */}
            <RDSButton className="tw-text-white" onClick={this.createNewUser}>Create</RDSButton>
            {/* <SvgIcon name='add-icon' onClick={this.createNewUser} /> */}
          </div>
        </div>

        {userList.length > 0 && <div className='tw-bg-white tw-px-[20px] tw-py-[15px] tw-rounded-[10px] tw-mt-[10px]'>
          <div className='tw-w-full '>
            <div className='tw-w-full hs-users-table-container'>
              <div className='tw-flex tw-justify-between tw-items-center tw-text-center tw-font-bold tw-text-[12px] tw-mb-[20px] tw-bg-white tw-h-[40px] tw-border tw-border-border-gray tw-rounded-[10px] tw-shadow-table'>
                <div className='tw-w-[15%]'>{I18N.getText('account')}</div>
                <div className='tw-w-[15%]'>{I18N.getText('email')}</div>
                <div className='tw-w-[15%]'>{I18N.getText('role')}</div>
                <div className='tw-w-[15%]'>{I18N.getText('amount')}</div>
                <div className='tw-w-[15%]'>{I18N.getText('point')}</div>
                <div className='tw-w-[15%]'>{I18N.getText('date')}</div>
                <div className='tw-w-[10%] '>{I18N.getText('operation')}</div>
              </div>
              <div className='tw-w-full tw-text-[12px]'>
                {userList && userList.map(record => {
                  return (<div className='tw-h-[50px] tw-bg-white tw-overflow-hidden tw-flex tw-justify-between tw-items-center tw-mb-[10px] hs-table-row tw-border tw-border-border-gray tw-rounded-[10px] tw-shadow-table'>
                    <div className='tw-w-[15%]'>
                      <div className='tw-flex tw-justify-center tw-items-center'>
                        <UserIcon size={18} color={ColorConstants.black[0]} />
                        <span className='tw-text-[12px] tw-ml-[5px] hs-td-cell'>{record.username}</span>
                      </div>
                    </div>
                    <div className='tw-w-[15%]'>
                      <div className='tw-flex tw-justify-center tw-items-center'>
                        <EmailIcon size={18} color={ColorConstants.black[0]} />
                        <span className='tw-text-[12px] tw-ml-[5px] hs-td-cell'>{record.email}</span>
                      </div>
                    </div>
                    <div className='tw-w-[15%]'>
                      <div className='tw-flex tw-justify-center tw-items-center'>
                        <RoleIcon size={18} color={ColorConstants.black[0]} />
                        <span className='tw-text-[12px] tw-ml-[5px] hs-td-cell'>{record.group_name}</span>
                      </div>
                    </div>
                    <div className='tw-w-[15%]'>
                      <div className='tw-flex tw-justify-center tw-items-center tw-font-bold'>
                        <DollarIcon size={18} color={ColorConstants.black[0]} className="tw-mb-[2px]" />
                        <span className='tw-text-[12px] tw-ml-[5px] hs-td-cell'>{record.money}</span>
                      </div>
                    </div>
                    <div className='tw-w-[15%]'>
                      <div className='tw-flex tw-justify-center tw-items-center'>
                        <PointIcon size={18} color={ColorConstants.black[0]} className="tw-mb-[2px]" />
                        <span className='tw-text-[12px] tw-ml-[5px] hs-td-cell'>{record.point}</span>
                      </div>
                    </div>
                    <div className='tw-w-[15%]'>
                      <div className='tw-flex tw-justify-center tw-items-center'>
                        <CalendarIcon size={24} color={ColorConstants.black[0]} />
                        <span className='tw-text-[12px] tw-ml-[5px] hs-td-cell'>{record.createtime}</span>
                      </div>
                    </div>
                    <div className='tw-w-[10%] tw-flex tw-justify-center'>
                      <SvgIcon onClick={() => this.handleEditUser(record.id)} size={20} color={ColorConstants.brand.primary} name="edit-icon" />
                    </div>
                  </div>)
                })}
              </div>
            </div>
          </div>
          <div className='tw-w-full tw-flex tw-justify-center'>
            <Pagination count={total} page={userListParams.page} onChange={this.handlePageChange} variant="outlined" color="primary" />
          </div>
          {userList.length === 0 && <div className='tw-w-full tw-flex tw-flex-col tw-justify-center tw-items-center'>
            <SvgIcon size={200} name="non-record-icon" color={ColorConstants.brand.primary} />
            <div className='tw-mt-[20px] tw-text-[16px] tw-font-medium'>{I18N.getText('no-records')}</div>
          </div>}
        </div>}

      </div>
    </div>);
  }
}

function mapStateToProps(state) {
  return {
    userList: UserSelectors.selectUserList(state),
    userTotalNum: UserSelectors.selectUserRecordTotalNum(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    userActions: bindActionCreators(userActionCreator, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(UserPage);

