import React, { Component } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';

import { bindActionCreators } from 'redux';
import { div } from 'reactstrap';
import classnames from 'classnames';
import Pagination from '@material-ui/lab/Pagination';

// --- Components --- //
import Loading from '../../components/common/LoadingIndicator';
import SvgIcon from '../../components/common/SvgIcon';
import EDSInput from '../../components/common/EDSInput';

// --- Constants --- //
import ColorConstants from '../../constants/ColorConstants';
import DialogConstants from '../../constants/dialog/index';

// --- Actions --- //
import * as achCardActionCreator from '../../actions/achCardActionCreator';

// --- Selectors --- //
import * as AchCardSelectors from '../../selectors/AchCardSelectors'

// --- Icons --- //
import SearchIcon from '../../icons/SearchIcon';
import DollarIcon from '../../icons/DollarIcon';
import CalendarIcon from '../../icons/CalendarIcon';

import I18N from '../../i18n';
import RDSButton from '../../components/common/RDSButton';
import PagePathConstants from '../../constants/router/PagePathConstants';
import DeleteConfirmationModal from '../../components/common/dialog/DeleteConfirmationModal';

class AhcCardPage extends Component {
  constructor(props, context) {
    super(props, context);

    this.state = {
      achCardParams: {
        page: 1,
        per_page: 10,
        type: 'ACH'
      },

      showDeleteConfirmModal: false,
      deleteItemId: '',
      showLoading: false,
    };

    this.getACHCardInfo = this.getACHCardInfo.bind(this);
    this.handleCardFilterClick = this.handleCardFilterClick.bind(this);
    this.handlePageChange = this.handlePageChange.bind(this);
    this.toggleLoading = this.toggleLoading.bind(this);
    this.handleCardFilterChange = this.handleCardFilterChange.bind(this);
    this.handleEditAch = this.handleEditAch.bind(this);
    this.handleDeleteAch = this.handleDeleteAch.bind(this);
    this.createNewACHCard = this.createNewACHCard.bind(this);
    this.toggleDeleteConfirmModal = this.toggleDeleteConfirmModal.bind(this);
    this.handleDeleteACHCard = this.handleDeleteACHCard.bind(this);
  }

  componentDidMount() {
    this.getACHCardInfo();
  }

  toggleDeleteConfirmModal() {
    this.setState({ showDeleteConfirmModal: !this.state.showDeleteConfirmModal });
  }

  handleDeleteACHCard(id) {
    this.toggleDeleteConfirmModal();
    this.setState({ deleteItemId: id });
  }

  handleEditAch(id) {
    const { history } = this.props;
    history && history.push(PagePathConstants.ACH_EDIT.replace(':id', id));
  }

  handleDeleteAch() {
    const { achCardActions } = this.props;
    const { deleteItemId } = this.state;

    this.toggleDeleteConfirmModal();
    this.toggleLoading();
    achCardActions && (deleteItemId && deleteItemId !== '') && achCardActions.deleteAchCard(deleteItemId)
      .then(() => {
        this.toggleLoading();
        this.getACHCardInfo();
      })
      .catch(this.toggleLoading);

  }

  toggleLoading() {
    this.setState({ showLoading: !this.state.showLoading });
  }

  handleCardFilterChange(e) {
    const name = e && e.target.value;
    const { achCardParams } = this.state;
    achCardParams.keywords = name;
    this.setState({ achCardParams });
  }

  getACHCardInfo(params) {
    if (!params) {
      params = this.state.achCardParams;
    }
    const { achCardActions } = this.props;
    this.toggleLoading();
    return achCardActions && achCardActions.fetchAchCardList(params)
      .then(data => {
        this.toggleLoading();
        return;
      });
  }

  handleCardFilterClick() {
    let { achCardParams } = this.state;
    achCardParams.page = 1;
    this.setState({ achCardParams });
    this.getACHCardInfo(achCardParams);
  }

  handlePageChange(e, page) {
    let { achCardParams } = this.state;
    achCardParams.page = page;
    this.getACHCardInfo(achCardParams)
      .then(() => {
        this.setState({ achCardParams });
      });
  }

  createNewACHCard() {
    const { history } = this.props;
    history && history.push(PagePathConstants.ACH_ADD);
  }

  renderACHCardStatus(state) {
    switch (state) {
      case 0: {
        return (<div className='tw-rounded-[10px] tw-w-[120px] tw-h-[40px] tw-text-[16px] tw-font-bold tw-px-[8px] tw-py-[2px] tw-bg-status-succeed-bg tw-text-status-succeed-text tw-flex tw-items-center tw-justify-center'>
          <span>{I18N.getText('on')}</span>
        </div>)
      }
      case 1:
      default: {
        return (<div className='tw-rounded-[10px] tw-w-[120px] tw-h-[40px] tw-text-[16px] tw-font-bold tw-px-[8px] tw-py-[2px] tw-bg-status-timeout-bg tw-text-status-timeout-text tw-flex tw-items-center tw-justify-center'>
          <span>{I18N.getText('off')}</span>
        </div>)
      }
    }
  }

  renderCurrency(currency) {
    switch (currency) {
      case 0: {
        return (<span>{I18N.getText('GBP')}</span>)
      }
      case 1:
      default: {
        return (<span>{I18N.getText('EUR')}</span>)
      }
    }
  }

  render() {
    const { achCardList, achCardTotalNum } = this.props;
    const { achCardParams, showLoading, showDeleteConfirmModal } = this.state;
    const total = Math.ceil(achCardTotalNum / achCardParams.per_page);
    return (<div className='tw-w-full tw-px-[50px]'>
      <div className='tw-w-full tw-mt-[30px]'>
        <div className='tw-flex tw-justify-end tw-items-center tw-w-full tw-mt-[20px] hs-userlist-filter-container'>
          {/* <div className='tw-relative'>
            <EDSInput className="tw-w-[450px] tw-py-[12px] tw-rounded-[30px] tw-px-[20px] tw-border-none tw-shadow-xl"
              value={achCardParams.keywords}
              type="text"
              placeholder={I18N.getText('card-number-placeholder')}
              onChange={this.handleCardFilterChange}
            />
            <SearchIcon size={24} color={ColorConstants.black[0]} className="tw-absolute tw-right-[20px] tw-bottom-[12px] tw-w-[24px] tw-h-[24px]" onClick={this.handleCardFilterClick} />
          </div> */}
          <div className='tw-w-[300px] tw-flex tw-justify-end tw-items-center'>
            <RDSButton className="tw-text-white" onClick={this.createNewACHCard}>Create</RDSButton>
          </div>
        </div>

        {achCardList && achCardList.length > 0 && <React.Fragment>
          <div className='tw-w-full tw-mt-[30px]'>
            {showLoading && <Loading />}
            <DeleteConfirmationModal isDeleteModalOpen={showDeleteConfirmModal} toggle={this.toggleDeleteConfirmModal} handleDeleteConfirm={this.handleDeleteAch} sectionName={DialogConstants.ACH_CARD} />
            <div className='tw-w-full hs-users-table-container'>
              <div className='tw-flex tw-justify-between tw-items-center tw-text-center tw-font-bold tw-text-[14px] tw-mb-[20px] tw-rounded-[30px] tw-bg-white tw-h-[80px] tw-shadow-xl'>
                <div className='tw-w-[15%]'>{I18N.getText('account')}</div>
                <div className='tw-w-[10%]'>{I18N.getText('max-money')}</div>
                <div className='tw-w-[7.5%]'>{I18N.getText('max-order')}</div>
                <div className='tw-w-[10%]'>{I18N.getText('total-money')}</div>
                <div className='tw-w-[7.5%]'>{I18N.getText('order-count')}</div>
                <div className='tw-w-[15%]'>{I18N.getText('tgid')}</div>
                <div className='tw-w-[10%]'>{I18N.getText('status')}</div>
                <div className='tw-w-[15%]'>{I18N.getText('date')}</div>
                <div className='tw-w-[10%]'>{I18N.getText('operation')}</div>
              </div>
              <div className='tw-w-full tw-text-[14px]'>
                {achCardList && achCardList.map(record => {
                  return (<div className='tw-rounded-[30px] tw-h-[80px] tw-bg-white tw-overflow-hidden tw-flex tw-justify-between tw-items-center tw-text-center tw-mb-[20px] tw-shadow-xl hs-table-row'>
                    <div className='tw-w-[15%] tw-flex tw-justify-center tw-flex-wrap tw-items-center hs-td-cell'>{`${record.account}`}</div>
                    <div className='tw-w-[10%] tw-flex tw-justify-center tw-items-center'>
                      <DollarIcon size={18} color={ColorConstants.black[0]} className="tw-mb-[3px]" />
                      <span className='tw-font-bold hs-td-cell'>{record.max_money}</span>
                    </div>
                    <div className='tw-w-[7.5%] tw-flex tw-justify-center tw-items-center hs-td-cell'>{`${record.max_order}`}</div>
                    <div className='tw-w-[10%] tw-flex tw-justify-center tw-items-center'>
                      <DollarIcon size={18} color={ColorConstants.black[0]} className="tw-mb-[3px]" />
                      <span className='tw-font-bold hs-td-cell'>{record.money_sum || 0}</span>
                    </div>

                    <div className='tw-w-[7.5%] tw-flex tw-justify-center tw-items-center hs-td-cell'>{`${record.order_count || 0}`}</div>
                    <div className='tw-w-[15%] tw-flex tw-justify-center tw-items-center hs-td-cell'>{`${record.telId}`}</div>
                    <div className='tw-w-[10%] tw-flex tw-justify-center tw-items-center'>{this.renderACHCardStatus(record.status)}</div>
                    <div className='tw-w-[15%] tw-flex tw-justify-center tw-items-center'>
                      <CalendarIcon size={24} color={ColorConstants.black[0]} className="tw-mb-[2px]" />
                      <span className='tw-text-[14px] tw-ml-[5px] hs-td-cell'>{record.createtime}</span>
                    </div>
                    <div className='tw-w-[10%] tw-flex tw-justify-center tw-items-center'>
                      <SvgIcon onClick={() => this.handleEditAch(record.id)} size={20} color={ColorConstants.brand.primary} name="edit-icon" />
                      <SvgIcon onClick={() => this.handleDeleteACHCard(record.id)} size={20} color={ColorConstants.brand.primary} name="delete-icon" className="tw-ml-[10px]" />
                    </div>
                  </div>)
                })}
              </div>
            </div>
          </div>
          <div className='tw-w-full tw-flex tw-justify-center'>
            <Pagination count={total} page={achCardParams.page} onChange={this.handlePageChange} variant="outlined" color="primary" />
          </div>
        </React.Fragment>}
        {achCardList && achCardList.length === 0 && <div className='tw-w-full tw-flex tw-flex-col tw-justify-center tw-items-center'>
          <SvgIcon size={200} name="non-record-icon" />
          <div className='tw-mt-[20px] tw-text-[16px] tw-font-medium'>{I18N.getText('no-records')}</div>
        </div>}
      </div>
    </div>);
  }
}

function mapStateToProps(state) {
  return {
    achCardList: AchCardSelectors.selectAchCardList(state),
    achCardTotalNum: AchCardSelectors.selectAchRecordTotalNum(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    achCardActions: bindActionCreators(achCardActionCreator, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(AhcCardPage);

