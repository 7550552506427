import ApiEndpointConstants from '../constants/api/ApiEndpointConstants';
import api from './API';

export const getCoinInfoById = (id) => {
    return api.post(
        `${ApiEndpointConstants.COIN_DETAIL}`, {id});
}

export const editCoinInfoById = (params) => {
    return api.post(
        `${ApiEndpointConstants.EDIT_COIN}`, params);
}

export const createCoin = (params) => {
    return api.post(
        `${ApiEndpointConstants.EDIT_COIN}`, params);
}

export const fetchCoinList = (params) => {
    return api.post(
        `${ApiEndpointConstants.COIN}`,
        params,
    );
}

export const deleteCoin = (id) => {
    return api.post(
        `${ApiEndpointConstants.DELETE_COIN}`, {id});
}
