import { createSelector } from 'reselect';
import StoreStateConstants from '../constants/store/StoreStateConstants';
import ReducerNameConstants from '../constants/reducers/ReducerNameConstants';

const selectSdCardReducer = state => state.get(ReducerNameConstants.ST_CARD);

export const selectSdCardList = createSelector(
  [selectSdCardReducer],
  (sdCardReducer) => {
    return sdCardReducer && sdCardReducer.get(StoreStateConstants.ST_CARD_LIST);
  },
);


export const selectStRecordTotalNum = createSelector(
  [selectSdCardReducer],
  (sdCardReducer) => {
    return sdCardReducer && sdCardReducer.get(StoreStateConstants.ST_CARD_TOTAL_COUNT);
  },
);
