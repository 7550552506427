import { createSelector } from 'reselect';
import StoreStateConstants from '../constants/store/StoreStateConstants';
import ReducerNameConstants from '../constants/reducers/ReducerNameConstants';

const selectSdGroupReducer = state => state.get(ReducerNameConstants.ST_GROUP);

export const selectStGroupList = createSelector(
  [selectSdGroupReducer],
  (stGroupReducer) => {
    return stGroupReducer && stGroupReducer.get(StoreStateConstants.ST_GROUP_LIST);
  },
);


export const selectStGroupRecordTotalNum = createSelector(
  [selectSdGroupReducer],
  (stGroupReducer) => {
    return stGroupReducer && stGroupReducer.get(StoreStateConstants.ST_GROUP_TOTAL_COUNT);
  },
);
