import { createSelector } from 'reselect';
import StoreStateConstants from '../constants/store/StoreStateConstants';
import ReducerNameConstants from '../constants/reducers/ReducerNameConstants';

const selectIbanReducer = state => state.get(ReducerNameConstants.IBAN_CARD);

export const selectIbanCardList = createSelector(
  [selectIbanReducer],
  (ibanReducer) => {
    return ibanReducer && ibanReducer.get(StoreStateConstants.IBAN_CARD_LIST);
  },
);


export const selectIbanRecordTotalNum = createSelector(
  [selectIbanReducer],
  (ibanReducer) => {
    return ibanReducer && ibanReducer.get(StoreStateConstants.IBAN_CARD_TOTAL_COUNT);
  },
);
