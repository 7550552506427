import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

// --- Components --- //
import SvgIcon from '../../components/common/SvgIcon';
import Loading from '../../components/common/LoadingIndicator';

// --- Utils --- //
import * as DateTimeUtil from '../../utils/DateTimeUtil';

// --- Constants --- //
import PagePathConstants from '../../constants/router/PagePathConstants';
import ColorConstants from '../../constants/ColorConstants';

// --- Actions --- //
import * as stCardActionCreator from '../../actions/stCardActionCreator';
import * as stGroupActionCreator from '../../actions/stGroupActionCreator';
import * as orderActionCreator from '../../actions/orderActionCreator';

import I18N from '../../i18n';
class OrderDetailPage extends Component {
    constructor(props, context) {
        super(props, context);

        this.state = {
            showLoading: false,
            orderNo: '',
            // product: '',
            // productSku: '',
            // num: '',
            amount: '',
            username: '',
            email: '',
            phone: '',
            address: '',
            zipCode: '',
            orderTime: '',
            ad: '',
        };

        this.toggleLoading = this.toggleLoading.bind(this);
        this.getOrderInfoById = this.getOrderInfoById.bind(this);
        this.handleBackPage = this.handleBackPage.bind(this);
    }

    componentDidMount() {
        this.getOrderInfoById();
    }

    getOrderInfoById() {
        const { location, orderActions } = this.props;
        const orderId = location.pathname.split(`${PagePathConstants.ORDER_PAGE}/`)[1];
        if (orderId && orderId !== '') {
            this.toggleLoading();
            orderActions.getOrderDetail(orderId)
                .then(orderInfo => {
                    let productImg = '';
                    // 用于处理图片数据不合规的问题，以前没有]，不是完整的json 数据
                    // if (orderInfo && orderInfo.goodsList && orderInfo.goodsList[0] && orderInfo.goodsList[0].goods_image.indexOf(']') > 0) {
                    //     productImg = orderInfo && orderInfo.goodsList && orderInfo.goodsList[0] && orderInfo.goodsList[0].goods_image && (window.location.protocol + "//" +  orderInfo.url + JSON.parse(orderInfo.goodsList[0].goods_image)[0]);
                    // } else {
                    //     productImg = orderInfo && orderInfo.goodsList && orderInfo.goodsList[0] && orderInfo.goodsList[0].goods_image && (window.location.protocol + "//" +  orderInfo.url + JSON.parse(orderInfo.goodsList[0].goods_image + ']')[0]);
                    // }
                    // const productTitle = orderInfo && orderInfo.goodsList && orderInfo.goodsList[0] && orderInfo.goodsList[0].goods_title;
                    // const selectedSku = orderInfo && orderInfo.goodsList && orderInfo.goodsList[0] && orderInfo.goodsList[0].goods_image && JSON.parse(orderInfo.goodsList[0].goods_sku);
                    // let formatSku = '';
                    // selectedSku.map(item => {
                    //     formatSku += `<span class="tw-mr-[15px]">${item.title}: ${item.content}</span>`;
                    // });
                    // const num = orderInfo && orderInfo.goodsList && orderInfo.goodsList[0] && orderInfo.goodsList[0].goods_num;

                    this.setState({
                        orderNo: orderInfo.orderNu,
                        // product: productImg,
                        // productTitle: productTitle,
                        // productSku: formatSku,
                        // num: num,
                        amount: orderInfo.amount,
                        username: orderInfo && `${orderInfo.firstName} ${orderInfo.lastName} `,
                        email: orderInfo && orderInfo.email,
                        phone: orderInfo && orderInfo.phone,
                        address: orderInfo && `${orderInfo.state} ${orderInfo.city} ${orderInfo.address}`,
                        zipCode: orderInfo && orderInfo.zipCode,
                        orderTime: orderInfo && DateTimeUtil.getFriendlyTime(orderInfo.updatetime * 1000),
                        ad: orderInfo && orderInfo.ad,
                    });
                    this.toggleLoading();
                })
                .catch(() => {
                    this.toggleLoading();
                })
        }
    }

    toggleLoading() {
        this.setState({ showLoading: !this.state.showLoading });
    }

    handleBackPage() {
        const { history } = this.props;
        history && history.goBack();
    }

    render() {
        const { showLoading, orderNo, product, productTitle, productSku, num, amount, username, email, phone, address, zipCode, orderTime, ad } = this.state;
        return <div className='tw-w-full tw-px-[50px] tw-py-[50px]'>
            {showLoading && <Loading />}
            <div className='tw-rounded-[30px] tw-bg-white'>
                <div className='tw-flex tw-items-center  tw-px-[30px] tw-py-[24px] tw-border-b' onClick={this.handleBackPage}>
                    <SvgIcon name="rds-back-icon" color={ColorConstants.black[0]} size={24} />
                    <span className='tw-ml-[10px] tw-text-[18px] tw-font-bold' >{I18N.getText('back')}</span>
                </div>
                <div className='tw-text-[14px] tw-px-[30px] tw-py-[30px]'>
                    <div className='tw-flex tw-items-center'>
                        <div className='tw-w-[20%] tw-font-bold' >{I18N.getText('order-no')}</div>
                        <div className='tw-w-[80%] tw-line-clamp-2 phone:tw-line-clamp-3 tw-break-all'>{orderNo}</div>
                    </div>
                    {/* <div className='tw-flex tw-items-center tw-mt-[30px]'>
                        <div className='tw-w-[20%] tw-font-bold' >{I18N.getText('product')}</div>
                        <div className='tw-w-[80px] tw-h-[80px] ' style={{ backgroundImage: `url(${product})`, backgroundSize: '100%', backgroundRepeat: 'no-repeat' }}></div>
                    </div>

                    <div className='tw-flex tw-items-center tw-mt-[30px]'>
                        <div className='tw-w-[20%] tw-font-bold' >{I18N.getText('product-title')}</div>
                        <div className='tw-w-[80%] tw-line-clamp-2 phone:tw-line-clamp-3 tw-break-all'>{productTitle}</div>
                    </div>
                    <div className='tw-flex tw-items-center tw-mt-[30px]'>
                        <div className='tw-w-[20%] tw-font-bold' >{I18N.getText('specification')}</div>
                        <div className='tw-w-[80%] '><div className=' tw-flex phone:tw-flex-col tw-items-start' dangerouslySetInnerHTML={{__html: productSku}}></div></div>
                    </div>
                    <div className='tw-flex tw-items-center tw-mt-[30px]'>
                        <div className='tw-w-[20%] tw-font-bold' >{I18N.getText('num')}</div>
                        <div className='tw-w-[80%] tw-line-clamp-2 phone:tw-line-clamp-3 tw-break-all'>{num}</div>
                    </div> */}
                    <div className='tw-flex tw-items-center tw-mt-[30px]'>
                        <div className='tw-w-[20%] tw-font-bold' >{I18N.getText('amount')}</div>
                        <div className='tw-w-[80%] tw-line-clamp-2 phone:tw-line-clamp-3 tw-break-all'>{amount}</div>
                    </div>
                    <div className='tw-flex tw-items-center tw-mt-[30px]'>
                        <div className='tw-w-[20%] tw-font-bold' >{I18N.getText('username')}</div>
                        <div className='tw-w-[80%] tw-line-clamp-2 phone:tw-line-clamp-3 tw-break-all'>{username}</div>
                    </div>
                    <div className='tw-flex tw-items-center tw-mt-[30px]'>
                        <div className='tw-w-[20%] tw-font-bold' >{I18N.getText('email')}</div>
                        <div className='tw-w-[80%] tw-line-clamp-2 phone:tw-line-clamp-3 tw-break-all'>{email}</div>
                    </div>
                    <div className='tw-flex tw-items-center tw-mt-[30px]'>
                        <div className='tw-w-[20%] tw-font-bold' >{I18N.getText('phone')}</div>
                        <div className='tw-w-[80%] tw-line-clamp-2 phone:tw-line-clamp-3 tw-break-all'>{phone}</div>
                    </div>
                    <div className='tw-flex tw-items-center tw-mt-[30px]'>
                        <div className='tw-w-[20%] tw-font-bold' >{I18N.getText('address')}</div>
                        <div className='tw-w-[80%] tw-line-clamp-2 phone:tw-line-clamp-3 tw-break-all'>{address}</div>
                    </div>
                    <div className='tw-flex tw-items-center tw-mt-[30px]'>
                        <div className='tw-w-[20%] tw-font-bold' >{I18N.getText('ZipCode')}</div>
                        <div className='tw-w-[80%] tw-line-clamp-2 phone:tw-line-clamp-3 tw-break-all'>{zipCode}</div>
                    </div>
                    <div className='tw-flex tw-items-center tw-mt-[30px]'>
                        <div className='tw-w-[20%] tw-font-bold' >{I18N.getText('order-time')}</div>
                        <div className='tw-w-[80%] tw-line-clamp-2 phone:tw-line-clamp-3 tw-break-all'>{orderTime}</div>
                    </div>
                    <div className='tw-flex tw-items-center tw-mt-[30px]'>
                        <div className='tw-w-[20%] tw-font-bold' >{I18N.getText('ad')}</div>
                        <div className='tw-w-[80%] tw-line-clamp-2 phone:tw-line-clamp-3 tw-break-all'>{ad}</div>
                    </div>
                    
                </div>
            </div>
        </div>;
    }
}

function mapStateToProps(state) {
    return {
    };
}

function mapDispatchToProps(dispatch) {
    return {
        orderActions: bindActionCreators(stCardActionCreator, dispatch),
        stGroupActions: bindActionCreators(stGroupActionCreator, dispatch),
        orderActions: bindActionCreators(orderActionCreator, dispatch),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(OrderDetailPage);