
import ActionConstants from '../constants/actions/ActionConstants';
import { showErrorGlobalAlert, showInfoGlobalAlert } from './globalAlertActionCreator';
import StoreStateConstants from '../constants/store/StoreStateConstants';
import ReducerNameConstants from '../constants/reducers/ReducerNameConstants';
import * as WithdrawAPI from '../apis/WithdrawAPI';
import {fetchMeProfile} from './userActionCreator';
import moment from 'moment';

export const updateWithdrawListAction = (data) => ({
    type: ActionConstants.UPDATE_WITHDRAW_LIST,
    payload: data,
});


export const fetchWithdrawRecord = (params) => {
    return function (dispatch, getState) {
        return new Promise((resolve, reject) => {
            WithdrawAPI.getWithdrawRecord(params)
            .then((res) => {
                if (res.status === 200 && res.data.code === 1) {
                    const withdrawArr = res.data.data.list;
                    const total = res.data.data.total;
                    dispatch(updateWithdrawListAction({
                        data: withdrawArr,
                        total
                    }));
                    return resolve('success');
                } else {
                    return reject(res.data.msg);
                }
            })
            .catch((err) => {
                dispatch(showErrorGlobalAlert('withdraw-record-error'));
                return reject(err);
            });
        });
    };
};

export const withdrawMoney = (withdrawAccount, withdrawAmount) => {
    return function (dispatch, getState) {
        return new Promise((resolve, reject) => {
            WithdrawAPI.withdraw(withdrawAccount, withdrawAmount)
            .then((res) => {
                if (res.status === 200 && res.data.code === 1) {
                    dispatch(fetchMeProfile());
                    dispatch(showInfoGlobalAlert('withdraw-record-succeed'));
                    return resolve('success');
                } else {
                    dispatch(showErrorGlobalAlert(res.data.msg));
                    return reject(res.data.msg);
                }
            })
            .catch((err) => {
                dispatch(showErrorGlobalAlert('withdraw-money-error'));
                return reject(err);
            });
        });
    };
};

export const operateWithdrawAction = (id, status) => {
    return function (dispatch, getState) {
        return new Promise((resolve, reject) => {
            WithdrawAPI.actionWithdraw(id, status)
            .then((res) => {
                if (res.status === 200 && res.data.code === 1) {
                    if (status === 1) {
                        dispatch(showInfoGlobalAlert('allow-withdraw-action-succeed'));
                    } else {
                        dispatch(showInfoGlobalAlert('forbid-withdraw-action-succeed'));
                    }
                    return resolve('success');
                } else {
                    dispatch(showErrorGlobalAlert(res.data.msg));
                    return reject(res.data.msg);
                }
            })
            .catch((err) => {
                dispatch(showErrorGlobalAlert('withdraw-action-failed'));
                return reject(err);
            });
        });
    };
};
