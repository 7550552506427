
export default {
  USER                            : 'userReducer',
  GLOBAL_ALERT                    : 'globalAlertReducer',
  DASHBOARD                       : 'dashboardReducer',
  PAYMENT                         : 'paymentReducer',
  ORDER                           : 'orderReducer',
  WITHDRAW                        : 'withdrawReducer',
  ROUTE                           : 'routeReducer',
  ST_CARD                         : 'stCardReducer',
  ST_GROUP                        : 'stGroupReducer',
  BLACKLIST                       : 'blacklistReducer',
  IBAN_CARD                       : 'ibanCardReducer',
  ACH_CARD                        : 'achCardReducer',
  COIN                            : 'CoinReducer',


  TAG                             : 'tagReducer',
  CATEGORY                        : 'categoryReducer',
  PRODUCT                         : 'productReducer',
};
