import { Map } from 'immutable';
import StoreStateConstants from '../constants/store/StoreStateConstants';
import ActionConstants from '../constants/actions/ActionConstants';

const initialState = Map({
    [StoreStateConstants.IBAN_CARD_LIST]: [],
    [StoreStateConstants.IBAN_CARD_TOTAL_COUNT]: 0,
});

export const IbanCardReducer = (state = initialState, action) => {
    switch (action.type) {
        case ActionConstants.UPDATE_IBAN_CARD_LIST: {
            return state.set(StoreStateConstants.IBAN_CARD_LIST, action.payload.data).set(StoreStateConstants.IBAN_CARD_TOTAL_COUNT, action.payload.total);
        }
        default:
            return state;
    }
};
