import React from 'react';
import Colors from '../constants/ColorConstants';

const EURIcon = ({
  size = 24,
  color = Colors.icon.default,
  viewBox = '0 0 24 24',
  ...props
}) => (
  <svg viewBox="0 0 1024 1024" p-id="2536" width={size} height={size} {...props}>
    <path d="M680.861168 114.125574a319.849489 319.849489 0 0 0-190.085174 54.549418A312.77482 312.77482 0 0 0 407.927902 260.645684a292.481692 292.481692 0 0 0-44.495942 116.545855h459.667281l-18.617549 90.295112H352.633782v88.991883h433.975063l-18.617549 90.295112H366.783119Q395.081793 800.554599 502.691226 865.529845A320.408015 320.408015 0 0 0 670.249165 912.259892q126.040806 0 196.787491-66.092298v138.700739a436.58152 436.58152 0 0 1-186.175488 39.096852q-304.024572 0-411.075478-253.384839a694.993098 694.993098 0 0 1-37.235098-123.992875H130.340249l18.617549-90.295112h70.746686q-2.04793-28.298674-2.047931-61.251736v-27.553972H130.340249l18.617549-90.295112h78.193705Q268.296286 157.318288 444.232122 61.437911A490.758587 490.758587 0 0 1 683.467625 2.234106 384.638559 384.638559 0 0 1 893.659751 56.783524l-26.064569 125.296103a284.848497 284.848497 0 0 0-186.734014-67.954053z" fill={color} p-id="2537"></path>
    </svg>
);

export default EURIcon