import { createSelector } from 'reselect';
import StoreStateConstants from '../constants/store/StoreStateConstants';
import ReducerNameConstants from '../constants/reducers/ReducerNameConstants';

const selectDashboardReducer  = state => state.get(ReducerNameConstants.DASHBOARD);

// 成功订单金额图表数据
export const selectSuccessfulOrderGraphAmountList = createSelector(
  [selectDashboardReducer],
  (dashboardReducer) => {
    const successfulOrderAmountGraphList = dashboardReducer && dashboardReducer.getIn([StoreStateConstants.ORDER_GRAPH, StoreStateConstants.SUCCESSFUL_ORDER_GRAPH]);
    return successfulOrderAmountGraphList;
  },
);

// 失败订单金额图表数据
export const selectFailedOrderAmountGraphList = createSelector(
  [selectDashboardReducer],
  (dashboardReducer) => {
    const failedOrderAmountGraphList = dashboardReducer && dashboardReducer.getIn([StoreStateConstants.ORDER_GRAPH, StoreStateConstants.FIALED_ORDER_GRAPH]);
    return failedOrderAmountGraphList;
  },
);

// 提现图表数据
export const selectWithdrawGraphAmountList = createSelector(
  [selectDashboardReducer],
  (dashboardReducer) => {
    const withdrawGraphList = dashboardReducer && dashboardReducer.get(StoreStateConstants.WITHDRAW_AMOUNT_GRAPH);
    return withdrawGraphList;
  },
);


// 成功订单数目图表数据
export const selectSuccessfulOrderNumGraphList = createSelector(
  [selectDashboardReducer],
  (dashboardReducer) => {
    const successfulOrderNumGraphList = dashboardReducer && dashboardReducer.getIn([StoreStateConstants.ORDER_GRAPH, StoreStateConstants.SUCCESSFUL_ORDER_NUM_GRAPH]);
    return successfulOrderNumGraphList;
  },
);


// 订单统计数据
export const selectOrderStatisticsData = createSelector(
  [selectDashboardReducer],
  (dashboardReducer) => {
    const orderStatisticsData = dashboardReducer && dashboardReducer.get(StoreStateConstants.ORDER_STATISTICS_DATA);
    return orderStatisticsData;
  },
);
