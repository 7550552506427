import React, { Component } from 'react';
import {
  Switch,
  HashRouter,
  Route,
} from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PagePathConstants from './constants/router/PagePathConstants';
import cookie from "react-cookies";
import LoginPage from './pages/LoginPage';
import EntrancePage from './pages/EntrancePage';
import GlobalAlert from './components/common/GlobalAlert';
import './App.css';
import * as userActionCreator from './actions/userActionCreator';
import { setAPIBaseUrl } from './apis/API';
import { layoutReset } from './utils/layout_1920x1080';
import StoreStateConstants from './constants/store/StoreStateConstants';

class App extends Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    console.log(window.location.protocol + "//" + window.location.host)
    const localAuth = cookie.load(StoreStateConstants.AUTHENTICATION, {path: '/'});
    const {userActions} = this.props;
    localAuth && userActions && userActions.fetchMeProfile();
    // if (window.location.host.indexOf('localhost') < 0) {
    //   const baseUrl = window.location.protocol + "//" + window.location.host;
    //   setAPIBaseUrl(baseUrl);
    // }
    layoutReset();

  }

  render() {
    return (
      <HashRouter>
        <React.Fragment>
          <GlobalAlert />
          <Switch>
            <Route path={PagePathConstants.LOGIN_PAGE} component={LoginPage} />
            <Route path={PagePathConstants.ROOT_PAGE} component={EntrancePage} />
          </Switch>
        </React.Fragment>
      </HashRouter>

    );
  }
}

function mapStateToProps(state) {
  return {
  };
}

function mapDispatchToProps(dispatch) {
  return {
    userActions: bindActionCreators(userActionCreator, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(App);