import React from 'react';
import { render } from 'react-dom'
import './index.scss';
import App from './App';
import { Provider } from 'react-redux';
import store from './store/store';
import reportWebVitals from './reportWebVitals';
import cookie from 'react-cookies';
import { setAPIAuthorizationToken } from './apis/API';
import StoreStateConstants from './constants/store/StoreStateConstants';

const localAuth = cookie.load(StoreStateConstants.AUTHENTICATION, {path: '/'});
if (localAuth) {
    setAPIAuthorizationToken(localAuth);
}

render(<Provider store={store}>
    <App />
</Provider>, document.getElementById('root'))
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
