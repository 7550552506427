import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Select from 'react-select';

// --- Components --- //
import RDSButton from '../../components/common/RDSButton';
import Loading from '../../components/common/LoadingIndicator';
import SvgIcon from '../../components/common/SvgIcon';
import EDSInput from '../../components/common/EDSInput';

// --- Utils --- //
import * as UserLocationUtil from '../../utils/UserLocationUtil';
import LocalStorageUtil from '../../utils/LocalStorageUtil';
import * as FormValidationsUtil from '../../utils/FormValidationsUtil';

// --- Constants --- //
import PagePathConstants from '../../constants/router/PagePathConstants';
import ColorConstants from '../../constants/ColorConstants';

// --- Actions --- //
import * as userActionCreator from '../../actions/userActionCreator';
import * as stGroupActionCreator from '../../actions/stGroupActionCreator';

import I18N from '../../i18n';
import classNames from 'classnames';
import staticMethods from 'antd/es/message';

const USER_ROLE_LIST = [
    { value: 0, label: I18N.getText('foreigner') },
    { value: 1, label: I18N.getText('agent') },
    { value: 2, label: I18N.getText('customer') },
    { value: 3, label: I18N.getText('admin') },
];

class UserEditPage extends Component {
    constructor(props, context) {
        super(props, context);

        this.state = {
            username: '',
            password: '',
            TGid: '',
            TgGroupId: '',
            email: '',
            mobile: '',
            point: '0',
            role: USER_ROLE_LIST[0],
            showError: false,
            showLoading: false,
            errMsg: '',
            errFiled: '',
            isEditing: false,
            // selectGroups: null,
            // stGroupList: [],
        };

        this.handleUsernameChange = this.handleUsernameChange.bind(this);
        this.handleEmailChange = this.handleEmailChange.bind(this);
        this.handlePasswordChange = this.handlePasswordChange.bind(this);
        this.handleMobileChange = this.handleMobileChange.bind(this);
        this.handlePointChange = this.handlePointChange.bind(this);
        this.toggleError = this.toggleError.bind(this);
        this.toggleLoading = this.toggleLoading.bind(this);
        this.editUserProfile = this.editUserProfile.bind(this);
        this.handleBackPage = this.handleBackPage.bind(this);
        this.handleTGidChange = this.handleTGidChange.bind(this);
        this.handleTgGroupChatIdChange = this.handleTgGroupChatIdChange.bind(this);
        this.handleRoleChange = this.handleRoleChange.bind(this);
        this.handleStGroupChange = this.handleStGroupChange.bind(this);
        this.toggleEditing = this.toggleEditing.bind(this);
        this.getUserProfileById = this.getUserProfileById.bind(this);
        // this.getStGroupList = this.getStGroupList.bind(this);
    }

    componentDidMount() {
        this.getUserProfileById();
    }

    toggleError() {
        this.setState({ showError: !this.state.showError });
    }

    toggleLoading() {
        this.setState({ showLoading: !this.state.showLoading });
    }

    toggleEditing() {
        this.setState({ isEditing: !this.state.isEditing });
    }

    // getStGroupList() {
    //     const { stGroupActions } = this.props;
    //     stGroupActions && stGroupActions.getStGroupList()
    //         .then(data => {
                
    //             let newGroupList = [].concat(data.list);
    //             newGroupList = newGroupList.map(item => {
    //                 return { value: item.id, label: item.name };
    //             });
    //             this.setState({ stGroupList: newGroupList }, this.getUserProfileById);

    //         })

    // }

    getUserProfileById() {
        const { location, userActions } = this.props;
        const userId = location.pathname.split('/user/')[1];

        if (userId && userId !== 'add') {
            this.toggleLoading();
            userActions.getUserProfileById(userId)
                .then(res => {
                    const userinfo = res;
                    let role = USER_ROLE_LIST.filter(item => item.value === userinfo.group_id)[0];
                    // const { stGroupList } = this.state;
                    // const userStGroups = userinfo.is_st_group_id ? userinfo.is_st_group_id.split(',') : [];
                    // const filterSelectGroups = stGroupList.filter(item => userStGroups.indexOf(item.value.toString()) >= 0);
                    this.setState({
                        username: userinfo.username,
                        TGid: userinfo.tgid ? (userinfo.tgid + '') : '',
                        TgGroupId: userinfo.qunid ? (userinfo.qunid) : '',
                        email: userinfo.email,
                        mobile: userinfo.mobile,
                        point: userinfo.point ? (parseInt(userinfo.point) + '') : '',
                        role: role,
                        // selectGroups: filterSelectGroups || []
                    });
                    this.toggleLoading();
                })
                .catch(() => {
                    this.toggleLoading();
                })
        }
    }

    handlePasswordChange(e) {
        const password = e && e.target.value;
        this.setState({ password, showError: false });
    }


    handleTGidChange(e) {
        const TGid = e && e.target.value;
        if (FormValidationsUtil.isValidTGid(TGid)) {
            this.setState({ TGid, showError: false });
        }
    }

    handleTgGroupChatIdChange(e) {
        const TgGroupId = e && e.target.value;
        if (FormValidationsUtil.isValidTGid(TgGroupId)) {
            this.setState({ TgGroupId, showError: false });
        }
    }

    handleEmailChange(e) {
        const email = e && e.target.value;
        this.setState({ email, showError: false });
    }

    handleMobileChange(e) {
        const mobile = e && e.target.value;
        if (FormValidationsUtil.isNumber(mobile)) {
            this.setState({ mobile, showError: false });
        }
    }

    handlePointChange(e) {
        const point = e && e.target.value;
        if (FormValidationsUtil.isValidPoint(point)) {
            this.setState({ point, showError: false });
        }
    }

    customClearIndicatorWithClass(className) {
        const ClearIndicator = (props) => {
            const { innerProps: { ...restInnerProps }, } = props;
            return (<div {...restInnerProps} >
                <SvgIcon name="x-icon" className={className} color={ColorConstants.brand.primary} />
            </div>);
        };
        return ClearIndicator;
    }

    editUserProfile() {
        const { userActions, location, history } = this.props;
        const userId = location.pathname.split('/user/')[1];
        let { email, password, mobile, point, TGid, TgGroupId, role, username } = this.state;
        if (password.trim() !== '' && !FormValidationsUtil.isValidPassword(password.trim())) {
            this.setState({ showError: true, errFiled: 'password', errMsg: I18N.getText('inValid-password') });
            return;
        }
        if (email.trim() !== '' && !FormValidationsUtil.isValidEmail(email.trim())) {
            this.setState({ showError: true, errFiled: 'email', errMsg: I18N.getText('inValid-email') });
            return;
        }
        if (mobile.trim() !== '' && !FormValidationsUtil.isNumber(mobile)) {
            this.setState({ showError: true, errFiled: 'mobile', errMsg: I18N.getText('inValid-mobile') });
            return;
        }
        if (point.trim() !== '' && !FormValidationsUtil.isValidPoint(point)) {
            this.setState({ showError: true, errFiled: 'point', errMsg: I18N.getText('inValid-point') });
            return;
        }
        if (TGid.trim() !== '' && !FormValidationsUtil.isValidTGid(TGid)) {
            this.setState({ showError: true, errFiled: 'tgid', errMsg: I18N.getText('inValid-tgid') });
            return;
        }
        if (TgGroupId.trim() !== '' && !FormValidationsUtil.isValidTGid(TgGroupId)) {
            this.setState({ showError: true, errFiled: 'tgGroupChatId', errMsg: I18N.getText('inValid-tgid') });
            return;
        }

        // const formateGroupIds = selectGroups.map(item => item.value).join(',');
        let params = {
            username,
            password,
            email,
            mobile,
            point,
            group_id: role.value,
            tgid: TGid,
            qunid: TgGroupId,
            // is_st_group_id: formateGroupIds
        };
        if (userId && userId !== 'add') {
            params['id'] = userId;
        }
        this.toggleEditing();
        userActions && userActions.editUserProfileById(params)
            .then(() => {
                this.toggleEditing();
                history.push(PagePathConstants.USER);
                if (userId && userId !== 'add') {
                    this.getUserProfileById(userId);
                }
            })
            .catch(this.toggleEditing)
    }

    handleBackPage() {
        const { history } = this.props;
        history && history.goBack();
    }

    handleRoleChange(role) {
        this.setState({ role, showError: false });
    }


    handleStGroupChange(stGroups) {
        this.setState({ selectGroups: stGroups, showError: false });
    }


    handleUsernameChange(e) {
        const username = e && e.target.value;
        this.setState({ username, showError: false });
    }

    render() {
        const { username, password, email, TGid, TgGroupId, mobile, point, role, selectGroups, stGroupList, showError, errMsg, showLoading, errFiled, isEditing } = this.state;
        const { location } = this.props;
        const userId = location.pathname.split('/user/')[1];
        const isEditUser = (userId && userId !== 'add');
        return <div className='tw-w-full tw-px-[50px] tw-py-[50px]'>
            {showLoading && <Loading />}
            <div className='tw-rounded-[30px] tw-bg-white  '>
                <div onClick={this.handleBackPage} className='tw-flex tw-items-center  tw-px-[30px] tw-py-[24px] tw-border-b'>
                    <SvgIcon name="rds-back-icon" color={ColorConstants.black[0]} size={24} />
                    <span className='tw-ml-[10px] tw-text-[18px] tw-font-bold'>{I18N.getText('back')}</span>
                </div>
                <div className='tw-text-[14px] tw-px-[30px] tw-py-[30px]'>
                    <div className='tw-flex tw-items-center'>
                        <div className='tw-w-[20%] tw-font-bold'>{I18N.getText('username')}</div>

                        {isEditUser ? <EDSInput className="tw-py-[10px] tw-px-[20px] tw-rounded-[30px] tw-border-none tw-common-input disabled:tw-text-body-text-1"
                            value={username}
                            type="text"
                            disabled={true}
                        /> : <EDSInput
                            className="tw-py-[10px] tw-px-[20px] tw-rounded-[30px] tw-border tw-shadow-xl tw-text-base tw-border-border-gray tw-font-medium focus:tw-border-brand-primary focus:tw-shadow-none placeholder:tw-text-body-text-secondary placeholder:tw-font-normal placeholder:tw-text-[12px]"
                            placeholder={I18N.getText('username-tip')}
                            value={username || ""}
                            type="text"
                            onChange={this.handleUsernameChange}
                        />}

                    </div>
                    <div className='tw-flex tw-items-center tw-mt-[30px]'>
                        <div className='tw-w-[20%] tw-font-bold'>{I18N.getText('password')}</div>
                        <EDSInput
                            className={classNames(`tw-py-[10px] tw-px-[20px] tw-rounded-[30px] tw-border tw-shadow-xl tw-text-base tw-border-border-gray tw-font-medium focus:tw-border-brand-primary focus:tw-shadow-none placeholder:tw-text-body-text-secondary placeholder:tw-font-normal placeholder:tw-text-[12px]`, { 'tw-border-light-red': (showError && errFiled === 'password') })}
                            placeholder={I18N.getText('password-tip')}
                            value={password || ""}
                            type="text"
                            onChange={this.handlePasswordChange}
                        />
                    </div>
                    <div className='tw-flex tw-items-center tw-mt-[30px]'>
                        <div className='tw-w-[20%] tw-font-bold'>{I18N.getText('tgid')}</div>
                        <EDSInput
                            className={classNames(`tw-py-[10px] tw-px-[20px] tw-rounded-[30px] tw-border tw-shadow-xl tw-text-base tw-border-border-gray tw-font-medium focus:tw-border-brand-primary focus:tw-shadow-none placeholder:tw-text-body-text-secondary placeholder:tw-font-normal placeholder:tw-text-[12px]`, { 'tw-border-light-red': (showError && errFiled === 'tgid') })}
                            placeholder={I18N.getText('tgid-tip')}
                            value={TGid}
                            type="text"
                            pattern="^[0-9]*$"
                            onChange={this.handleTGidChange}
                        />
                    </div>
                    <div className='tw-flex tw-items-center tw-mt-[30px]'>
                        <div className='tw-w-[20%] tw-font-bold'>{I18N.getText('tg-group-chat-id')}</div>
                        <EDSInput
                            className={classNames(`tw-py-[10px] tw-px-[20px] tw-rounded-[30px] tw-border tw-shadow-xl tw-text-base tw-border-border-gray tw-font-medium focus:tw-border-brand-primary focus:tw-shadow-none placeholder:tw-text-body-text-secondary placeholder:tw-font-normal placeholder:tw-text-[12px]`, { 'tw-border-light-red': (showError && errFiled === 'tgGroupChatId') })}
                            placeholder={I18N.getText('tg-group-chat-id-tip')}
                            value={TgGroupId}
                            type="text"
                            pattern="^[0-9]*$"
                            onChange={this.handleTgGroupChatIdChange}
                        />
                    </div>
                    <div className='tw-flex tw-items-center tw-mt-[30px]'>
                        <div className='tw-w-[20%] tw-font-bold'>{I18N.getText('email')}</div>
                        <EDSInput
                            className={classNames(`tw-py-[10px] tw-px-[20px] tw-rounded-[30px] tw-border tw-shadow-xl tw-text-base tw-border-border-gray tw-font-medium focus:tw-border-brand-primary focus:tw-shadow-none placeholder:tw-text-body-text-secondary placeholder:tw-font-normal placeholder:tw-text-[12px]`, { 'tw-border-light-red': (showError && errFiled === 'email') })}
                            placeholder={I18N.getText('email-tip')}
                            value={email || ""}
                            type="text"
                            onChange={this.handleEmailChange}
                        />
                    </div>
                    <div className='tw-flex tw-items-center tw-mt-[30px]'>
                        <div className='tw-w-[20%] tw-font-bold'>{I18N.getText('mobile')}</div>
                        <EDSInput
                            className={classNames(`tw-py-[10px] tw-px-[20px] tw-rounded-[30px] tw-border tw-shadow-xl tw-text-base tw-border-border-gray tw-font-medium focus:tw-border-brand-primary focus:tw-shadow-none placeholder:tw-text-body-text-secondary placeholder:tw-font-normal placeholder:tw-text-[12px]`, { 'tw-border-light-red': (showError && errFiled === 'mobile') })}
                            placeholder={I18N.getText('phone-tip')}
                            value={mobile || ""}
                            type="text"
                            onChange={this.handleMobileChange}
                        />
                    </div>
                    <div className='tw-flex tw-items-center tw-mt-[30px]'>
                        <div className='tw-w-[20%] tw-font-bold'>{I18N.getText('point')}</div>
                        <EDSInput
                            className={classNames(`tw-py-[10px] tw-px-[20px] tw-rounded-[30px] tw-border tw-shadow-xl tw-text-base tw-border-border-gray tw-font-medium focus:tw-border-brand-primary focus:tw-shadow-none placeholder:tw-text-body-text-secondary placeholder:tw-font-normal placeholder:tw-text-[12px]`, { 'tw-border-light-red': (showError && errFiled === 'point') })}
                            placeholder={I18N.getText('point-tip')}
                            value={point}
                            type="text"
                            onChange={this.handlePointChange}
                        />
                    </div>
                    <div className='tw-flex tw-items-center tw-mt-[30px]'>
                        <div className='tw-w-[20%] tw-font-bold'>{I18N.getText('role')}</div>
                        {isEditUser ?
                            <EDSInput className="tw-py-[10px] tw-px-[20px] tw-rounded-[30px] tw-border-none tw-common-input disabled:tw-text-body-text-1"
                                value={role.label}
                                type="text"
                                disabled={true}
                            /> : <Select
                                className="tw-w-full tw-rounded-[30px]  tw-px-[20px] tw-border tw-shadow-xl tw-border-border-gray placeholder:tw-text-body-text-secondary placeholder:tw-font-normal placeholder:tw-text-[12px] hs-role-selector-container"
                                components={{ ClearIndicator: this.customClearIndicatorWithClass('tw-text-brand-primary') }}
                                styles={{ dropdownIndicator: base => ({ ...base, color: ColorConstants.brand.primary }) }}
                                value={role}
                                placeholder={I18N.getText('role-placeholder')}
                                onChange={this.handleRoleChange}
                                isClearable={true}
                                name="role"
                                options={USER_ROLE_LIST}
                            />
                        }
                    </div>
                    {/* <div className='tw-flex tw-items-center tw-mt-[30px]'>
                        <div className='tw-w-[20%] tw-font-bold'>{I18N.getText('st-group')}</div>
                        <Select
                            className="tw-w-full tw-rounded-[30px]  tw-px-[20px] tw-border tw-shadow-xl tw-border-border-gray placeholder:tw-text-body-text-secondary placeholder:tw-font-normal placeholder:tw-text-[12px] hs-role-selector-container"
                            components={{ ClearIndicator: this.customClearIndicatorWithClass('tw-text-brand-primary') }}
                            styles={{ dropdownIndicator: base => ({ ...base, color: ColorConstants.brand.primary }) }}
                            value={selectGroups}
                            placeholder={I18N.getText('st-group-placeholder')}
                            onChange={this.handleStGroupChange}
                            isClearable={true}
                            name="stGroup"
                            options={stGroupList}
                            isMulti
                        />
                    </div> */}
                    {showError && <div className='tw-mt-[20px] tw-text-[14px] tw-text-error-text'>{errMsg}</div>}
                    <div className='tw-flex tw-justify-center tw-items-center tw-mt-[30px]'>
                        <RDSButton loading={isEditing} disabled={TGid.trim() === '' || email.trim() == '' || mobile.trim() === '' || point.trim() === '' || isEditing} onClick={this.editUserProfile} className="tw-text-white ">{I18N.getText('save')}</RDSButton>
                    </div>
                </div>
            </div>

        </div>;
    }
}

function mapStateToProps(state) {
    return {
        // selectedCountryCode: VerifyOptionSelectors.selectCountryCode(state),
        // ssn: VerifyOptionSelectors.selectSsn(state),
    };
}

function mapDispatchToProps(dispatch) {
    return {
        userActions: bindActionCreators(userActionCreator, dispatch),
        stGroupActions: bindActionCreators(stGroupActionCreator, dispatch),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(UserEditPage);