import { createSelector } from 'reselect';
import StoreStateConstants from '../constants/store/StoreStateConstants';
import ReducerNameConstants from '../constants/reducers/ReducerNameConstants';

const selectUserReducer = state => state.get(ReducerNameConstants.USER);

export const selectLoginStatus = createSelector(
  [selectUserReducer],
  (userReducer) => {
    return userReducer && userReducer.get(StoreStateConstants.LOGIN_STATUS);
  },
);

export const selectCurrentUser = createSelector(
  [selectUserReducer],
  (userReducer) => {
    const currentUser = userReducer && userReducer.get(StoreStateConstants.CURRENT_USER)
    return (currentUser && currentUser.toJS()) || {};
  },
);

export const selectPaymentType = createSelector(
  [selectUserReducer],
  (userReducer) => {
    return userReducer && userReducer.get(StoreStateConstants.PAYMENT_TYPE);
  },
);

export const selectUserList = createSelector(
  [selectUserReducer],
  (userReducer) => {
    return userReducer && userReducer.get(StoreStateConstants.USER_LIST);
  },
);

export const selectUserListForSelector = createSelector(
  [selectUserReducer],
  (userReducer) => {
    const userList = userReducer && userReducer.get(StoreStateConstants.USER_LIST);
    return userList && userList.map(item => {
      return {
        value: item.id,
        label: item.username
      }
    });
  },
);


export const selectUserRecordTotalNum = createSelector(
  [selectUserReducer],
  (userReducer) => {
    return userReducer && userReducer.get(StoreStateConstants.USER_TOTAL_COUNT);
  },
);
