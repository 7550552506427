import { createSelector } from 'reselect';
import StoreStateConstants from '../constants/store/StoreStateConstants';
import ReducerNameConstants from '../constants/reducers/ReducerNameConstants';

const selectBlacklistReducer = state => state.get(ReducerNameConstants.BLACKLIST);

export const selectBlacklist = createSelector(
  [selectBlacklistReducer],
  (blacklistReducer) => {
    return blacklistReducer && blacklistReducer.get(StoreStateConstants.BLACKLIST);
  },
);

export const selectBlacklistRecordTotalNum = createSelector(
  [selectBlacklistReducer],
  (blacklistReducer) => {
    return blacklistReducer && blacklistReducer.get(StoreStateConstants.BLACKLIST_TOTAL_COUNT);
  },
);
