
import ActionConstants from '../constants/actions/ActionConstants';
import * as PaymentAPI from '../apis/PaymentAPI';
import { showErrorGlobalAlert } from './globalAlertActionCreator';
import StoreStateConstants from '../constants/store/StoreStateConstants';
import ReducerNameConstants from '../constants/reducers/ReducerNameConstants';
import moment from 'moment';

export const updatePaymentList = (data) => ({
    type: ActionConstants.UPDATE_PAYMENT_LIST,
    payload: data,
});

const mockPaymentList = [
    {
        id: 1,
        username: 'test',
        money: '1000.34',
        createtime: '2023-10-23 12:30',
        memo: 'mock description 1',
    },
    {
        id: 2,
        username: 'test',
        money: '1000.34',
        createtime: '2023/10/23 12:30',
        memo: 'mock description 1',
    },
    {
        id: 3,
        username: 'test',
        money: '1000.34',
        createtime: '2023/10/23 12:30',
        memo: 'mock description 1',
    },
    {
        id: 4,
        username: 'test',
        money: '1000.34',
        createtime: '2023/10/23 12:30',
        memo: 'mock description 1',
    },
    {
        id: 5,
        username: 'test',
        money: '1000.34',
        createtime: '2023/10/23 12:30',
        memo: 'mock description 1',
    },
    // {
    //     id: 6,
    //     status: 'failed',
    //     amount: '1000.34',
    //     description: 'mock description 1',
    //     customer: 'test@test.com',
    //     date: '2023/10/23 12:30',
    // },
    // {
    //     id: 7,
    //     status: 'succeed',
    //     amount: '1000.34',
    //     description: 'mock description 1',
    //     customer: 'test@test.com',
    //     date: '2023/10/23 12:30',
    // },
    // {
    //     id: 8,
    //     status: 'succeed',
    //     amount: '1000.34',
    //     description: 'mock description 1',
    //     customer: 'test@test.com',
    //     date: '2023/10/23 12:30',
    // },
    // {
    //     id: 9,
    //     status: 'succeed',
    //     amount: '1000.34',
    //     description: 'mock description 1',
    //     customer: 'test@test.com',
    //     date: '2023/10/23 12:30',
    // },
    // {
    //     id: 10,
    //     status: 'succeed',
    //     amount: '1000.34',
    //     description: 'mock description 1',
    //     customer: 'test@test.com',
    //     date: '2023/10/23 12:30',
    // }
];

export const fetchPaymentRecord = (params) => {
    return function (dispatch, getState) {
        return new Promise((resolve, reject) => {
            let newParams = Object.assign({}, params);
            newParams.min_date = Math.floor(moment(params.min_date).startOf('day').valueOf()/1000);
            newParams.max_date = Math.floor(moment(params.max_date).endOf('day').valueOf() / 1000);
            
                    // dispatch(updatePaymentList({
                    //     data: mockPaymentList,
                    //     total: 1000
                    // }));

            PaymentAPI.getPaymentRecord(newParams.page, newParams.per_page, newParams.keywords, newParams.min_date, newParams.max_date)
            .then((res) => {
                if (res.status === 200 && res.data.code === 1) {
                    const paymentArr = res.data.data.list;
                    const total = res.data.data.total;
                    dispatch(updatePaymentList({
                        data: paymentArr,
                        total
                    }));
                    return resolve('success');
                } else {
                    return reject(res.data.msg);
                }
            })
            .catch((err) => {
                dispatch(showErrorGlobalAlert('payment-record-error'));
                return reject(err);
            });
        });
    };
};