import * as BaseConfigAPI from '../apis/BaseConfigAPI';
import { showInfoGlobalAlert } from './globalAlertActionCreator';

export const fetchBasicConfig = (queryStr) => {
    return function (dispatch, getState) {
        return new Promise((resolve, reject) => {
            BaseConfigAPI.fetchBasicConfig(queryStr)
                .then((res) => {
                    if (res.status === 200) {
                        return resolve(res.data.data);
                    }
                })
                .catch((err) => {
                    return reject(err);
                });
        });
    };
}

export const updateBasicConfig = (params) => {
    return function (dispatch, getState) {
        return new Promise((resolve, reject) => {
            BaseConfigAPI.setBasicConfig(params)
                .then((res) => {
                    if (res.status === 200) {
                        dispatch(showInfoGlobalAlert('config-update-succeed'))
                        return resolve(res.data.data);
                    }
                })
                .catch((err) => {
                    return reject(err);
                });
        });
    };
}

export const fetchCurrencyList = () => {
    return function (dispatch, getState) {
        return new Promise((resolve, reject) => {
            BaseConfigAPI.fetchCurrencyList()
                .then((res) => {
                    if (res.status === 200) {
                        return resolve(res.data.data);
                    }
                })
                .catch((err) => {
                    return reject(err);
                });
        });
    };
}

