import ApiEndpointConstants from '../constants/api/ApiEndpointConstants';
import api from './API';

export const getOrderRecord = (params) => {
    return api.post(
        `${ApiEndpointConstants.ORDER}`,
        params,
    );
}

export const getOrderStatisticsData = (params) => {
    return api.post(
        `${ApiEndpointConstants.ORDER_STATISTICS}`,
        params,
    );
}

export const getOrderDetail = (params) => {
    return api.post(
        `${ApiEndpointConstants.ORDER_DETAIL}`,
        params,
    );
}

export const updateOrderPaymentStatus = (params) => {
    return api.post(
        `${ApiEndpointConstants.ORDER_PAYMENT_STATUS}`,
        params,
    );
}

export const cancelOrderSubscription = (params) => {
    return api.post(
        `${ApiEndpointConstants.ORDER_CANCEL_SUBSCRIPTION}`,
        params,
    );
}