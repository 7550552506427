export default {
  // 登录相关
  UPDATE_LOGIN_STATUS: 'UPDATE_LOGIN_STATUS',
  LOGOUT: 'LOGOUT',
  UPDATE_AUTHENTICATION: 'UPDATE_AUTHENTICATION',
  UPDATE_PAYMENT_TYPE: 'UPDATE_PAYMENT_TYPE',
  CLEAR_CURRENT_USER: 'CLEAR_CURRENT_USER',
  UPDATE_USER_LIST: 'UPDATE_USER_LIST',


  // 已登录用户信息
  UPDATE_CURRENT_USER: 'UPDATE_CURRENT_USER',

  // Dashboard
  UPDATE_SUCCESSFUL_ORDER_GRAPH: 'UPDATE_SUCCESSFUL_ORDER_GRAPH',
  UPDATE_SUCCESSFUL_ORDER_NUM_GRAPH: 'UPDATE_SUCCESSFUL_ORDER_NUM_GRAPH',
  UPDATE_FAILED_ORDER_GRAPH: 'UPDATE_FAILED_ORDER_GRAPH',
  UPDATE_WITHDRAW_AMOUNT_GRAPH: 'UPDATE_WITHDRAW_AMOUNT_GRAPH',
  UPDATE_ORDER_STATISTICS_DATA: 'UPDATE_ORDER_STATISTICS_DATA',

  // Payment
  UPDATE_PAYMENT_LIST: 'UPDATE_PAYMENT_LIST',

  // Order
  UPDATE_ORDER_LIST: 'UPDATE_ORDER_LIST',
  UPDATE_FAILED_ORDER_LIST: 'UPDATE_FAILED_ORDER_LIST',

  // Withdraw
  UPDATE_WITHDRAW_LIST: 'UPDATE_WITHDRAW_LIST',
  UPDATE_MONEY_BALANCE: 'UPDATE_MONEY_BALANCE',

  // St card
  UPDATE_ST_CARD_LIST: 'UPDATE_ST_CARD_LIST',

  // Blacklist
  UPDATE_BLACKLIST_LIST: 'UPDATE_BLACKLIST_LIST',

  // St Group
  UPDATE_ST_GROUP_LIST: 'UPDATE_ST_GROUP_LIST',

  // Iban card
  UPDATE_IBAN_CARD_LIST: 'UPDATE_IBAN_CARD_LIST',

  // Ach card
  UPDATE_ACH_CARD_LIST: 'UPDATE_ACH_CARD_LIST',

  // coin
  UPDATE_COIN_LIST: 'UPDATE_COIN_LIST',

  SHOW_GLOBAL_ALERT: 'SHOW_GLOBAL_ALERT',


  // Router
  UPDATE_ROUTER_LIST: 'UPDATE_ROUTER_LIST',
  SHOW_ADMIN_ROUTER: 'SHOW_ADMIN_ROUTER',
  SHOW_FOREIGNER_ROUTER: 'SHOW_FOREIGNER_ROUTER',
  SHOW_CUSTOMER_ROUTER: 'SHOW_CUSTOMER_ROUTER',
  SHOW_BASE_ROUTER: 'SHOW_BASE_ROUTER',





  // =========================
  // tag
  UPDATE_TAG_LIST: 'UPDATE_TAG_LIST',

  // category
  UPDATE_CATEGORY_LIST: 'UPDATE_CATEGORY_LIST',

  // product
  UPDATE_PRODUCT_LIST: 'UPDATE_PRODUCT_LIST',
}
