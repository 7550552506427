import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Pagination from '@material-ui/lab/Pagination';
import { div } from 'reactstrap';
import moment from 'moment';

// --- Components --- //
import EDSInput from '../../components/common/EDSInput';
import RDSButton from '../../components/common/RDSButton';
import Loading from '../../components/common/LoadingIndicator';
import SvgIcon from '../../components/common/SvgIcon';

// --- Constants --- //
import ColorConstants from '../../constants/ColorConstants';

// --- Actions --- //
import * as withdrawActionCreator from '../../actions/withdrawActionCreator';

// --- Selectors --- //
import * as WithdrawSelectors from '../../selectors/WithdrawSelectors';
import * as UserSelectors from '../../selectors/UserSelectors';

// --- Icons --- //
import DollarIcon from '../../icons/DollarIcon';
import CalendarIcon from '../../icons/CalendarIcon';
import BankAccountIcon from '../../icons/BankAccountIcon';

import I18N from '../../i18n';
import UserHelper from '../../helpers/UserHelper';
import classNames from 'classnames';

const WITHDRAW_ACCOUNT_MAX_LENGTH = 50;
const WITHDRAW_FILTER_TYPE = [{
  id: '',
  text: 'all'
}, {
  id: 1,
  text: 'succeeded'
}, {
  id: 2,
  text: 'failed'
}, {
  id: 0,
  text: 'being-processed'
}];
class WalletPage extends Component {
  constructor(props, context) {
    super(props, context);

    this.state = {
      showLoading: false,
      withdrawParams: {
        page: 1,
        per_page: 5,
        status: ''
      },
    };
    this.getWithdrawRecord = this.getWithdrawRecord.bind(this);
    this.renderWithdrawList = this.renderWithdrawList.bind(this);
    this.handlePageChange = this.handlePageChange.bind(this);
    this.handleWithdrawFilterClick = this.handleWithdrawFilterClick.bind(this);
    this.handleWithdrawAction = this.handleWithdrawAction.bind(this);
  }

  componentDidMount() {
    this.getWithdrawRecord();
  }

  handleWithdrawAction(id, status) {
    const { withdrawActions } = this.props;
    withdrawActions && withdrawActions.operateWithdrawAction(id, status)
    .then(res => {
      this.getWithdrawRecord();
    })
    .catch(err => {
      console.error(err);
    });
  }

  handleWithdrawFilterClick(item) {
    let { withdrawParams } = this.state;
    withdrawParams.status = item.id;
    withdrawParams.page = 1;
    this.setState({ withdrawParams });
    this.getWithdrawRecord(withdrawParams);
  }

  toggleWithdrawError() {
    this.setState({ showWithdrawError: !this.state.showWithdrawError });
  }

  toggleWithdrawLoadingStatus() {
    this.setState({ isWithdrawing: !this.state.isWithdrawing });
  }


  getWithdrawRecord(params) {
    if (!params) {
      params = this.state.withdrawParams;
    }
    const { withdrawActions } = this.props;
    this.toggleLoading();
    return withdrawActions && withdrawActions.fetchWithdrawRecord(params)
      .then(() => {
        this.toggleLoading();
      })
      .catch(this.toggleLoading);
  }

  toggleLoading() {
    this.setState({ showLoading: !this.state.showLoading });
  }

  renderWithdrawList() {
    const { showLoading, withdrawParams } = this.state;
    const { withdrawList, withdrawTotalNum, currentUser } = this.props;
    const isAdmin = UserHelper.isAdminUser(currentUser);
    const total = Math.ceil(withdrawTotalNum / withdrawParams.per_page);
    return <div className='tw-w-full'>
      <div className='tw-flex tw-w-full tw-mt-[30px]'>
        {WITHDRAW_FILTER_TYPE.map(item => {
          return <div key={item.id} className={classNames(' tw-h-[40px] tw-flex tw-justify-center tw-items-center tw-rounded-[10px] tw-text-body-text-1 tw-text-[18px] tw-font-bold tw-mr-[30px] tw-text-[#717579]', { '!tw-text-brand-primary': withdrawParams.status === item.id })} onClick={() => this.handleWithdrawFilterClick(item)}>{I18N.getText(item.text)}</div>
        })}
      </div>
      <div className='tw-w-full tw-mt-[30px] tw'>
        {showLoading && <Loading />}
        {withdrawList && withdrawList.length > 0 && <div className='tw-w-full hs-wallet-table-container'>
          <div className='tw-flex tw-justify-between tw-items-center tw-text-center tw-font-bold tw-text-[14px] tw-mb-[20px] tw-rounded-[30px] tw-bg-white tw-h-[80px] tw-shadow-xl'>
            <div className='tw-w-[20%]'>{I18N.getText('account')}</div>
            <div className='tw-w-[20%]'>{I18N.getText('amount')}</div>
            <div className='tw-w-[20%]'>{I18N.getText('status')}</div>
            <div className='tw-w-[20%]'>{I18N.getText('date')}</div>
            {isAdmin && <div className='tw-w-[20%]'>{I18N.getText('operation')}</div>}
          </div>
          <div className='tw-w-full tw-text-[14px]'>
            {withdrawList && withdrawList.map(record => {
              return (<div className='tw-rounded-[30px] tw-h-[80px] tw-bg-white tw-overflow-hidden tw-flex tw-justify-between tw-items-center tw-mb-[20px] tw-shadow-xl hs-table-row'>
                <div className='tw-w-[20%]'>
                  <div className='tw-flex tw-justify-center tw-items-center'>
                    <BankAccountIcon size={18} color={ColorConstants.black[0]} />
                    <span className='tw-text-[16px] tw-ml-[5px] hs-td-cell'>{record.address}</span>
                  </div>
                </div>
                <div className='tw-w-[20%]'>
                  <div className='tw-flex tw-justify-center tw-items-center'>
                    <DollarIcon size={18} color={ColorConstants.black[0]} className="tw-mb-[2px]" />
                    <span className='tw-text-[16px] tw-ml-[5px] tw-font-bold hs-td-cell'>{record.money}</span>
                  </div>
                </div>
                <div className='tw-w-[20%] tw-flex tw-justify-center'>{this.renderWithdrawStatus(record.status)}</div>
                <div className='tw-w-[20%]'>
                  <div className='tw-flex tw-justify-center tw-items-center'>
                    <CalendarIcon size={24} color={ColorConstants.black[0]} />
                    <span className='tw-text-[14px] tw-ml-[5px] hs-td-cell'>{moment(record.date).format(`L  HH:mm:ss`)}</span>
                  </div>
                </div>
                {isAdmin && <div className='tw-w-[20%] tw-flex tw-justify-center tw-items-center'>
                  <SvgIcon name="check-icon" color="#09BD3C" onClick={() => this.handleWithdrawAction(record.id, 1)} />
                  <SvgIcon className="tw-ml-[10px]" name="check-failed-icon" color="#b3093c" size={27} onClick={() => this.handleWithdrawAction(record.id, 2)} />
                </div>}
              </div>)
            })}
          </div>
        </div>}
        {withdrawList && withdrawList.length === 0 && <div className='tw-w-full tw-flex tw-flex-col tw-justify-center tw-items-center'>
          <SvgIcon size={200} name="non-record-icon" color={ColorConstants.brand.primary} />
          <div className='tw-mt-[20px] tw-text-[16px] tw-font-medium'>{I18N.getText('no-records')}</div>
        </div>}
      </div>
      {withdrawList && withdrawList.length > 0 && <div className='tw-w-full tw-flex tw-justify-center'>
        <Pagination count={total} page={withdrawParams.page} onChange={this.handlePageChange} variant="outlined" color="primary" />
      </div>}
    </div>
  }

  renderWithdrawStatus(status) {
    switch (status) {
      case 0: {
        return (<div className='tw-rounded-[10px] tw-w-[150px] tw-h-[40px] tw-text-[16px] tw-font-bold tw-px-[8px] tw-py-[2px] tw-bg-status-being-processed-bg tw-text-status-being-processed-text tw-flex tw-items-center tw-justify-center'>
        <span>{I18N.getText('being-processed')}</span>
      </div>)
      }
      case 1: {
        return (<div className='tw-rounded-[10px] tw-w-[150px] tw-h-[40px] tw-text-[16px] tw-font-bold tw-px-[8px] tw-py-[2px] tw-bg-status-succeed-bg tw-text-status-succeed-text tw-flex tw-items-center tw-justify-center'>
          <span>{I18N.getText('succeeded')}</span>
        </div>)
      }
      case 2: {
        return (<div className='tw-rounded-[10px] tw-w-[150px] tw-h-[40px] tw-text-[16px] tw-font-bold tw-px-[8px] tw-py-[2px] tw-bg-status-timeout-bg tw-text-status-timeout-text tw-flex tw-items-center tw-justify-center'>
          <span>{I18N.getText('failed')}</span>
        </div>)
      }
      default: {
        return (<div className='tw-rounded-[10px] tw-w-[150px] tw-h-[40px] tw-text-[16px] tw-font-bold tw-px-[8px] tw-py-[2px] tw-bg-status-being-processed-bg tw-text-status-being-processed-text tw-flex tw-items-center tw-justify-center'>
          <span>{I18N.getText('being-processed')}</span>
        </div>)
      }
    }
  }

  handlePageChange(e, page) {
    let { withdrawParams } = this.state;
    withdrawParams.page = page;
    this.getWithdrawRecord(withdrawParams)
      .then(() => {
        this.setState({ withdrawParams });
      });
  }

  render() {
    return <div className='tw-w-full tw-px-[50px]'>
      {this.renderWithdrawList()}
    </div>;
  }
}

function mapStateToProps(state) {
  return {
    currentUser: UserSelectors.selectCurrentUser(state),
    withdrawList: WithdrawSelectors.selectWithdrawList(state),
    withdrawTotalNum: WithdrawSelectors.selectWithdrawRecordTotalNum(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    withdrawActions: bindActionCreators(withdrawActionCreator, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(WalletPage);

