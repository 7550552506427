import { createSelector } from 'reselect';
import StoreStateConstants from '../constants/store/StoreStateConstants';
import ReducerNameConstants from '../constants/reducers/ReducerNameConstants';

const selectCoinReducer = state => state.get(ReducerNameConstants.COIN);

export const selectCoinCardList = createSelector(
  [selectCoinReducer],
  (coinReducer) => {
    return coinReducer && coinReducer.get(StoreStateConstants.COIN_LIST);
  },
);


export const selectCoinRecordTotalNum = createSelector(
  [selectCoinReducer],
  (coinReducer) => {
    return coinReducer && coinReducer.get(StoreStateConstants.COIN_TOTAL_COUNT);
  },
);
