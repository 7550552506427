import React from 'react';
import Colors from '../constants/ColorConstants';

const JPYIcon = ({
  size = 24,
  color = Colors.icon.default,
  viewBox = '0 0 24 24',
  ...props
}) => (
  <svg viewBox="0 0 1024 1024" p-id="6922"width={size} height={size} {...props}>
    <path d="M613.491509 782.279726l222.285642 0L835.777151 655.982252 613.491509 655.982252l0-40.416502 25.258267-45.467541 197.026352 0 0-131.351584L704.426591 438.746626l161.661913-373.8465L664.011112 64.900126 527.607466 453.901791 396.254859 64.900126 189.125405 64.900126l161.661913 373.8465-161.661913 0 5.048993 131.351584L391.20382 570.098209l25.258267 45.467541 0 40.416502L189.125405 655.982252 189.125405 782.279726l227.336682 0 0 176.820148 197.029422 0L613.491509 782.279726z" fill="#3A2E2E" p-id="6923"></path>
    </svg>
);

export default JPYIcon