import ApiEndpointConstants from '../constants/api/ApiEndpointConstants';
import api from './API';

export const fetchBasicConfig = (queryStr) => {
    return api.post(
        `${ApiEndpointConstants.GET_BASE_CONFIG}`, {
            type: queryStr
        });
}

export const fetchCurrencyList = () => {
    return api.post(
        `${ApiEndpointConstants.CURRENCY_LIST}`);
}

export const setBasicConfig = (params) => {
    return api.post(
        `${ApiEndpointConstants.SET_BASE_CONFIG}`, {
            row: params
        });
}