import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Pagination from '@material-ui/lab/Pagination';
import { div } from 'reactstrap';
import moment from 'moment';

// --- Components --- //
import EDSInput from '../../components/common/EDSInput';
import RDSButton from '../../components/common/RDSButton';
import Loading from '../../components/common/LoadingIndicator';
import SvgIcon from '../../components/common/SvgIcon';

// --- Constants --- //
import ColorConstants from '../../constants/ColorConstants';

// --- Actions --- //
import * as withdrawActionCreator from '../../actions/withdrawActionCreator';

// --- Selectors --- //
import * as WithdrawSelectors from '../../selectors/WithdrawSelectors';
import * as UserSelectors from '../../selectors/UserSelectors';

import I18N from '../../i18n';
import UserHelper from '../../helpers/UserHelper';
import classNames from 'classnames';

const WITHDRAW_ACCOUNT_MAX_LENGTH = 50;
const WITHDRAW_FILTER_TYPE = [{
  id: '',
  text: 'all'
}, {
  id: 1,
  text: 'succeeded'
}, {
  id: 2,
  text: 'failed'
}, {
  id: 0,
  text: 'being-processed'
}];
class WalletPage extends Component {
  constructor(props, context) {
    super(props, context);

    this.state = {
      withdrawAmount: '',
      withdrawAccount: props.currentUser.address || '',
      showLoading: false,
      showWithdrawError: false,
      withdrawError: '',
      isWithdrawing: false,
    };
    this.renderWalletOverview = this.renderWalletOverview.bind(this);
    this.handleWithdrawAmountChange = this.handleWithdrawAmountChange.bind(this);
    this.handleWithdrawAccountChange = this.handleWithdrawAccountChange.bind(this);
    this.handleWithdrawOperation = this.handleWithdrawOperation.bind(this);
    this.toggleWithdrawError = this.toggleWithdrawError.bind(this);
    this.toggleWithdrawLoadingStatus = this.toggleWithdrawLoadingStatus.bind(this);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.currentUser.address && nextProps.currentUser.address !== this.state.withdrawAccount) {
      this.setState({ withdrawAccount: nextProps.currentUser.address });
    }
  }

  toggleWithdrawError() {
    this.setState({ showWithdrawError: !this.state.showWithdrawError });
  }

  toggleWithdrawLoadingStatus() {
    this.setState({ isWithdrawing: !this.state.isWithdrawing });
  }

  handleWithdrawOperation() {
    const { currentUser, withdrawActions } = this.props;
    const { withdrawAccount, withdrawAmount } = this.state;
    const moneyBalance = UserHelper.getMoneyBalance(currentUser);
    if (parseFloat(withdrawAmount) > parseFloat(moneyBalance)) {
      this.toggleWithdrawError();
      this.setState({ withdrawError: I18N.getText('withdraw-overflow-error').replace('%s', moneyBalance) });
      return;
    }
    this.toggleWithdrawLoadingStatus();
    withdrawActions && withdrawActions.withdrawMoney(withdrawAccount, withdrawAmount)
      .then(() => {
        this.setState({ withdrawAmount: '' });
        this.toggleWithdrawLoadingStatus();
      })
      .catch(() => {
        this.toggleWithdrawLoadingStatus();
        this.toggleWithdrawError();
        this.setState({ withdrawError: I18N.getText('withdraw-money-error') });
      })
  }


  toggleLoading() {
    this.setState({ showLoading: !this.state.showLoading });
  }

  handleWithdrawAmountChange(e) {
    let withdrawAmount = e && e.target.value;
    const regx = new RegExp(/^([0-9]|[1-9]\d+)(\.[0-9]{0,2})?$/);
    console.log(withdrawAmount)
    if ((regx.test(withdrawAmount) && withdrawAmount.indexOf('e') < 0) || withdrawAmount === '') {
      this.setState({ withdrawAmount });
    }
    this.setState({ showWithdrawError: false });
  }

  handleWithdrawAccountChange(e) {
    this.setState({ showWithdrawError: false });
    const withdrawAccount = e && e.target.value;
    if (withdrawAccount.length <= WITHDRAW_ACCOUNT_MAX_LENGTH) {
      this.setState({ withdrawAccount });
    }
  }

  renderWalletOverview() {
    const { withdrawAmount, withdrawAccount, showWithdrawError, withdrawError, isWithdrawing } = this.state;
    const { currentUser } = this.props;
    const isAdmin = UserHelper.isAdminUser(currentUser);
    const moneyBalance = UserHelper.getMoneyBalance(currentUser);

    const withdrawableTip = I18N.getText('withdrawable-amount-tip').replace('%s', `$ ${moneyBalance}`);
    return !isAdmin && <div className='tw-w-full'>
      <div className='tw-w-[800px] tw-rounded-[30px] tw-bg-white tw-shadow-xl tw-px-[30px] tw-py-[30px] tw-mx-auto tw-text-[20px]'>
        <div className='tw-flex tw-justify-center '>
          <div className='tw-w-[800px]  tw-mt-[20px] tw-text-body-text-1'>
            <div className='tw-font-bold'>USD {I18N.getText('balance')}</div>
            <div className='tw-h-[1px] tw-bg-line-1 tw-my-[10px]'></div>
            <div className='tw-flex tw-justify-between  tw-text-black'>
              <div className='tw-text-[14px]'>{I18N.getText('total-amount')}</div>
              <div className='tw-font-bold'>{`$ ${moneyBalance}`}</div>
            </div>
          </div>
        </div>
        <div className='tw-mt-[80px] hs-withdraw-container tw-flex tw-justify-center'>
          <div className='tw-w-[800px]'>
            <div className='tw-text-[14px] hs-withdraw-tip'>{`${I18N.getText('withdrawal-amount')}`} (<span dangerouslySetInnerHTML={{ __html: withdrawableTip }}></span>)</div>
            <div className='tw-relative tw-text-[20px] tw-font-bold tw-mt-[20px]'>
              <EDSInput className="tw-h-[60px] tw-w-full tw-pl-[40px] tw-rounded-[30px] tw-shadow-xl tw-border-none placeholder:tw-text-body-text-secondary placeholder:tw-font-normal placeholder:tw-text-[14px] "
                value={withdrawAccount}
                type="text"
                placeholder={I18N.getText('withdraw-account-tip')}
                onChange={this.handleWithdrawAccountChange}
              />
              <div className='tw-relative'>
                <EDSInput className="tw-h-[60px] tw-w-full tw-pl-[40px] tw-mt-[30px] hs-withdraw-amount-input tw-rounded-[30px] tw-shadow-xl tw-border-none placeholder:tw-text-body-text-secondary placeholder:tw-font-normal placeholder:tw-text-[12px]"
                  value={withdrawAmount}
                  type="number"
                  placeholder="0.00"
                  onChange={this.handleWithdrawAmountChange}
                />
                <div className='tw-absolute tw-left-[15px] tw-bottom-[15px]'>$</div>
              </div>
              <div className='tw-w-full tw-text-error-text tw-text-[14px] tw-mt-[15px] tw-font-normal'>{showWithdrawError && withdrawError}</div>
              <div className='tw-w-full tw-flex tw-justify-center tw-mt-[50px]'>
                <RDSButton
                  className="tw-text-white"
                  onClick={this.handleWithdrawOperation}
                  disabled={(withdrawAccount === '') || (withdrawAccount === '') || (withdrawAmount.trim() === '') || (withdrawAmount.trim() === '0') || isWithdrawing}
                  loading={isWithdrawing}
                >
                  {I18N.getText('withdraw-capital-letter')}
                </RDSButton>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>;
  }

  render() {
    return <div className='tw-w-full tw-px-[50px] tw-py-[50px]'>
      {this.renderWalletOverview()}
    </div>;
  }
}

function mapStateToProps(state) {
  return {
    currentUser: UserSelectors.selectCurrentUser(state),
    withdrawList: WithdrawSelectors.selectWithdrawList(state),
    withdrawTotalNum: WithdrawSelectors.selectWithdrawRecordTotalNum(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    withdrawActions: bindActionCreators(withdrawActionCreator, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(WalletPage);

