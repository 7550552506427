import React from 'react';
import Colors from '../constants/ColorConstants';

const PointIcon = ({
  size = 24,
  color = Colors.icon.default,
  viewBox = '0 0 24 24',
  ...props
}) => (
  <svg viewBox="0 0 1024 1024" p-id="10139" width={size} height={size} {...props}>
    <path d="M512 537.6a25.5744 25.5744 0 0 1-25.6-25.6V141.696c0-14.08 11.52-25.6 25.6-25.6 218.112 0 395.52 177.408 395.52 395.52a25.6 25.6 0 0 1-25.6 25.6L512 537.6z m25.6-369.408V486.4l317.696-0.384C842.88 316.416 707.072 180.736 537.6 168.192z" fill="#333333" p-id="10140"></path><path d="M829.056 645.376C775.552 771.584 649.728 855.808 512 855.808c-189.824 0-344.32-154.496-344.32-344.32 0-142.592 87.936-269.696 219.904-320.896v-54.272c-1.408 0.256-2.816 0.512-4.224 1.024C223.744 192.384 116.48 342.656 116.48 511.488 116.48 729.6 293.888 907.008 512 907.008c162.432 0 310.784-101.76 369.152-253.312 1.024-2.688 1.536-5.504 1.536-8.32h-53.632z" fill={color} p-id="10141"></path>
  </svg>
);

export default PointIcon