import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Select from 'react-select';
import { Input } from 'reactstrap';

// --- Components --- //
import RDSButton from '../../components/common/RDSButton';
import SvgIcon from '../../components/common/SvgIcon';
import EDSInput from '../../components/common/EDSInput';
import Loading from '../../components/common/LoadingIndicator';

// --- Utils --- //
import * as FormValidationsUtil from '../../utils/FormValidationsUtil';

// --- Constants --- //
import PagePathConstants from '../../constants/router/PagePathConstants';
import ColorConstants from '../../constants/ColorConstants';

// --- Actions --- //
import * as coinActionCreator from '../../actions/coinActionCreator';
import * as imgActionCreator from '../../actions/imgActionCreator';

// --- Icons --- //
import RdsAddIcon from '../../icons/RdsAddIcon';
import RdsRemoveIcon from '../../icons/RdsRemoveIcon';

import I18N from '../../i18n';
import classNames from 'classnames';
import { BASE_URL } from '../../constants/api/ApiEndpointConstants';

const COIN_STATUS_LIST = [
    { value: 0, label: I18N.getText('on') },
    { value: 1, label: I18N.getText('off') },
]

const CURRENCY_LIST = [
    { value: 'BTC', label: 'BTC' },
    { value: 'ETH', label: 'ETH' },
    { value: 'USDT(TRC-20)', label: 'USDT(TRC-20)' },
    { value: 'ETC', label: 'ETC' },
    // { value: 'USDT(ERC20)', label: 'USDT(ERC20)' },
]

class CoinEditPage extends Component {
    constructor(props, context) {
        super(props, context);

        this.state = {
            url: '',
            account: '',
            telId: '',
            currency: CURRENCY_LIST[0],
            maxAmount: 0,
            maxOrder: 0,
            status: COIN_STATUS_LIST[0],
            showError: false,
            showLoading: false,
            isEditing: false,
            errMsg: '',
            errFiled: '',
        };

        this.handleWalletAddressChange = this.handleWalletAddressChange.bind(this);
        this.handleTelChange = this.handleTelChange.bind(this);
        this.handleMaxAmountChange = this.handleMaxAmountChange.bind(this);
        this.handleMaxOrderChange = this.handleMaxOrderChange.bind(this);
        this.handleStatusChange = this.handleStatusChange.bind(this);
        this.handleCurrencyChange = this.handleCurrencyChange.bind(this);
        this.toggleError = this.toggleError.bind(this);
        this.toggleLoading = this.toggleLoading.bind(this);
        this.toggleEditing = this.toggleEditing.bind(this);
        this.handleBackPage = this.handleBackPage.bind(this);
        this.editCoinInfo = this.editCoinInfo.bind(this);
        this.handleSelectFile = this.handleSelectFile.bind(this);
        this.removeImg = this.removeImg.bind(this);
    }
    componentDidMount() {
        this.getCoinInfoById()
    }

    handleSelectFile(e) {
        e.preventDefault();
        e && e.stopPropagation();

        const { imgActions } = this.props;
        const _this = this;
        _this.toggleLoading();
        const length = e.target.files.length;
        [...e.target.files].forEach((fileInfo, index) => {
            let reads = new FileReader();
            reads.readAsDataURL(fileInfo);
            reads.onload = function (event) {
                const imgUrl = this.result

                imgActions && imgActions.uploadImgToGetUrl(imgUrl)
                    .then(url => {
                        _this.toggleLoading();
                        _this.setState({ url: url });
                        event.target.value = '';
                    })
                    .catch(() => {
                        _this.toggleLoading();
                        event.target.value = '';
                    });
            };

        });
    }

    removeImg(ev) {
        ev && ev.stopPropagation();
        this.setState({ url: undefined, showError: false });
    }

    getCoinInfoById() {
        const { location, coinActions } = this.props;
        const achId = location.pathname.split(`${PagePathConstants.COIN}/`)[1];
        if (achId && achId !== 'add') {
            this.toggleLoading();
            coinActions.getCoinInfoById(achId)
                .then(res => {
                    const coinInfo = res;
                    let status = COIN_STATUS_LIST.filter(item => item.value === coinInfo.status)[0];
                    let currency = CURRENCY_LIST.filter(item => item.value == coinInfo.currency)[0];
                    this.setState({
                        account: coinInfo.account,
                        telId: coinInfo.telId,
                        maxAmount: coinInfo.max_money,
                        maxOrder: coinInfo.max_order,
                        status: status,
                        currency: currency,
                        url: BASE_URL + coinInfo.url
                    });
                    this.toggleLoading();
                })
                .catch(() => {
                    this.toggleLoading();
                })
        }
    }

    toggleEditing() {
        this.setState({ isEditing: !this.state.isEditing });
    }

    toggleError() {
        this.setState({ showError: !this.state.showError });
    }

    toggleLoading() {
        this.setState({ showLoading: !this.state.showLoading });
    }

    handleWalletAddressChange(e) {
        const account = e && e.target.value;
        this.setState({ account, showError: false });
    }

    handleCurrencyChange(currency) {
        this.setState({ currency, showError: false });
    }

    handleTelChange(e) {
        const telId = e && e.target.value;
        if (FormValidationsUtil.isNumber(telId)) {
            this.setState({ telId, showError: false });
        }
    }

    handleMaxAmountChange(e) {
        const maxAmount = e && e.target.value;
        const regx = new RegExp(/^([0-9]|[1-9]\d+)(\.[0-9]{0,2})?$/);
        if (regx.test(maxAmount) || maxAmount === '') {
            this.setState({ maxAmount });
        }
        this.setState({ showError: false });
    }

    handleMaxOrderChange(e) {
        const maxOrder = e && e.target.value;
        if (maxOrder !== '' && FormValidationsUtil.isNumber(maxOrder)) {
            this.setState({ maxOrder: maxOrder, showError: false });
        }
        if (maxOrder === '') this.setState({ maxOrder, showError: false })
    }

    handleStatusChange(status) {
        this.setState({ status, showError: false });
    }

    customClearIndicatorWithClass(className) {
        const ClearIndicator = (props) => {
            const { innerProps: { ...restInnerProps }, } = props;
            return (<div {...restInnerProps} >
                <SvgIcon name="x-icon" className={className} color={ColorConstants.brand.primary} />
            </div>);
        };
        return ClearIndicator;
    }

    editCoinInfo() {
        const { coinActions, history, location } = this.props;
        let { url, account, telId, maxAmount, maxOrder, status, currency } = this.state;
        if (account.trim() !== '' && !FormValidationsUtil.isValidString(account.trim())) {
            this.setState({ showError: true, errFiled: 'account', errMsg: I18N.getText('inValid-coin-account-number') });
            return;
        }
        const achId = location.pathname.split(`${PagePathConstants.COIN}/`)[1];

        let params = {
            type: 'COIN',
            url: url && url.replace(BASE_URL, ''),
            account,
            telId: telId,
            max_money: parseFloat(maxAmount),
            max_order: maxOrder,
            status: status.value,
            currency: currency.value
        }
        if (achId && achId !== 'add') {
            params['id'] = achId;
        }
        this.toggleEditing();
        coinActions && coinActions.editCoinInfoById(params)
            .then(() => {
                history && history.push(PagePathConstants.COIN);
            })
            .catch(err => {
                this.toggleEditing();
            });
    }

    handleBackPage() {
        const { history } = this.props;
        history && history.goBack();
    }

    render() {
        const { isEditing, showLoading, url, account, telId, currency, maxAmount, maxOrder, status, showError, errMsg, errFiled } = this.state;
        return <div className='tw-w-full tw-px-[50px] tw-py-[50px]'>
            {showLoading && <Loading />}
            <div className='tw-rounded-[30px] tw-bg-white'>
                <div className='tw-flex tw-items-center  tw-px-[30px] tw-py-[24px] tw-border-b' onClick={this.handleBackPage}>
                    <SvgIcon name="rds-back-icon" color={ColorConstants.black[0]} size={24} />
                    <span className='tw-ml-[10px] tw-text-[18px] tw-font-bold'>{I18N.getText('back')}</span>
                </div>
                <div className='tw-text-[14px] tw-px-[30px] tw-py-[30px]'>
                    <div className='tw-flex tw-items-center'>
                        <div className='tw-w-[17%] tw-font-bold'>{I18N.getText('qr-code')}</div>
                        <div className='tw-w-[150px] tw-h-[150px] tw-mb-[20px] tw-bg-background-3 tw-rounded-[15px] tw-border-[2px] tw-border-dashed tw-border-body-text-2 tw-relative'>
                            <Input className='tw-relative tw-w-full tw-h-full tw-opacity-0 tw-z-[2]' type='file' onChange={this.handleSelectFile} accept="image/*" multiple />
                            {url && <div style={{ backgroundImage: `url(${url})`, backgroundSize: 'contain', backgroundRepeat: 'no-repeat', backgroundPosition: 'center' }} className='tw-w-full tw-h-full tw-bg-cover tw-rounded-[15px] tw-absolute tw-top-0 tw-left-0 tw-z-[1]' />}
                            {url && <RdsRemoveIcon className="tw-absolute tw-right-[-10px] tw-bottom-[-10px] tw-z-[3]" color='#ACACAC' onClick={this.removeImg} />}
                            {!url && <RdsAddIcon className="tw-absolute tw-right-[-10px] tw-bottom-[-10px] tw-z-[3]" color={ColorConstants.brand.primary} />}
                        </div>
                    </div>
                    <div className='tw-flex tw-items-center'>
                        <div className='tw-w-[20%] tw-font-bold'>{I18N.getText('Account')}</div>
                        <EDSInput
                            className={classNames(`tw-py-[10px] tw-px-[20px] tw-rounded-[30px] tw-border tw-shadow-xl tw-text-base tw-border-border-gray tw-font-medium focus:tw-border-brand-primary focus:tw-shadow-none placeholder:tw-text-body-text-secondary placeholder:tw-font-normal placeholder:tw-text-[12px]`, { 'tw-border-light-red': (showError && errFiled === 'account') })}
                            placeholder={I18N.getText('ach-account-tip')}
                            value={account || ""}
                            type="text"
                            onChange={this.handleWalletAddressChange}
                        />
                    </div>
                    <div className='tw-flex tw-items-center tw-mt-[30px]'>
                        <div className='tw-w-[20%] tw-font-bold'>{I18N.getText('tel-id')}</div>
                        <EDSInput
                            className="tw-py-[10px] tw-px-[20px] tw-rounded-[30px] tw-border tw-shadow-xl tw-text-base tw-border-border-gray tw-font-medium focus:tw-border-brand-primary focus:tw-shadow-none placeholder:tw-text-body-text-secondary placeholder:tw-font-normal placeholder:tw-text-[12px]"
                            placeholder={I18N.getText('tel-tip')}
                            value={telId}
                            type="text"
                            onChange={this.handleTelChange}
                        />
                    </div>
                    <div className='tw-flex tw-items-center tw-mt-[30px]'>
                        <div className='tw-w-[20%] tw-font-bold'>{I18N.getText('currency')}</div>
                        <Select
                            className="tw-w-full tw-rounded-[30px]  tw-px-[20px] tw-border tw-shadow-xl tw-border-border-gray placeholder:tw-text-body-text-secondary placeholder:tw-font-normal placeholder:tw-text-[12px] hs-role-selector-container"
                            components={{ ClearIndicator: this.customClearIndicatorWithClass('tw-text-brand-primary') }}
                            styles={{ dropdownIndicator: base => ({ ...base, color: ColorConstants.brand.primary }) }}
                            value={currency}
                            onChange={this.handleCurrencyChange}
                            isClearable={true}
                            options={CURRENCY_LIST}
                        />
                    </div>
                    <div className='tw-flex tw-items-center tw-mt-[30px]'>
                        <div className='tw-w-[20%] tw-font-bold'>{I18N.getText('max-money')}</div>
                        <EDSInput
                            className="tw-py-[10px] tw-px-[20px] tw-rounded-[30px] tw-border tw-shadow-xl tw-text-base tw-border-border-gray tw-font-medium focus:tw-border-brand-primary focus:tw-shadow-none placeholder:tw-text-body-text-secondary placeholder:tw-font-normal placeholder:tw-text-[12px]"
                            placeholder={I18N.getText('max-money-tip')}
                            value={maxAmount}
                            type="text"
                            onChange={this.handleMaxAmountChange}
                        />
                    </div>
                    <div className='tw-flex tw-items-center tw-mt-[30px]'>
                        <div className='tw-w-[20%] tw-font-bold'>{I18N.getText('max-order')}</div>
                        <EDSInput
                            className="tw-py-[10px] tw-px-[20px] tw-rounded-[30px] tw-border tw-shadow-xl tw-text-base tw-border-border-gray tw-font-medium focus:tw-border-brand-primary focus:tw-shadow-none placeholder:tw-text-body-text-secondary placeholder:tw-font-normal placeholder:tw-text-[12px]"
                            placeholder={I18N.getText('max-order-tip')}
                            value={maxOrder}
                            type="text"
                            onChange={this.handleMaxOrderChange}
                        />
                    </div>
                    <div className='tw-flex tw-items-center tw-mt-[30px]'>
                        <div className='tw-w-[20%] tw-font-bold'>{I18N.getText('status')}</div>
                        <Select
                            className="tw-w-full tw-rounded-[30px]  tw-px-[20px] tw-border tw-shadow-xl tw-border-border-gray placeholder:tw-text-body-text-secondary placeholder:tw-font-normal placeholder:tw-text-[12px] hs-role-selector-container"
                            components={{ ClearIndicator: this.customClearIndicatorWithClass('tw-text-brand-primary') }}
                            styles={{ dropdownIndicator: base => ({ ...base, color: ColorConstants.brand.primary }) }}
                            value={status}
                            onChange={this.handleStatusChange}
                            isClearable={true}
                            name="status"
                            options={COIN_STATUS_LIST}
                        />
                    </div>
                    {showError && <div className='tw-mt-[20px] tw-text-[14px] tw-text-error-text'>{errMsg}</div>}
                    <div className='tw-flex tw-justify-center tw-items-center tw-mt-[30px]'>
                        <RDSButton
                            loading={isEditing}
                            disabled={isEditing || url.trim() === '' || account.trim() === '' || telId === '' || maxAmount === '' || maxOrder === ''}
                            onClick={this.editCoinInfo}
                            className="tw-text-white">{I18N.getText('edit')}</RDSButton>
                    </div>
                </div>
            </div>

        </div>;
    }
}

function mapStateToProps(state) {
    return {
        // selectedCountryCode: VerifyOptionSelectors.selectCountryCode(state),
        // ssn: VerifyOptionSelectors.selectSsn(state),
    };
}

function mapDispatchToProps(dispatch) {
    return {
        coinActions: bindActionCreators(coinActionCreator, dispatch),
        imgActions: bindActionCreators(imgActionCreator, dispatch),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(CoinEditPage);