import React from 'react';
import Calendar from 'react-calendar';

import '../../styles/common/CustomCalendar.scss';

// Convert to ISO locale names. If another locale is added with an '_',
// it needs to be added here:
const calendarLocale = {
  'ka_GE' : 'ka',
  'en_GB' : 'en-gb',
  'lt_LT' : 'lt-lt',
  'fr_CA' : 'fr-ca',
  'sr_RS' : 'sr-rs',
  'hi_IN' : 'hi',
};

// Calendars that start with Sunday: US, Canada, Taiwan, Japan, etc.
// * In Europe and Latin America, most calendars start with Monday.
// * In Arab-speaking countries, most calendars start with Friday and days off are Fri-Sat.
const mondayFirstLocales = [
  'da',
  'es',
  'en-gb',
  'it',
  'ru',
  'fr',
  'ko',
  'zh',
];

function LocaleCalendar({ ...props }) {

  let locale = calendarLocale[props.locale] || props.locale || 'en';
  let calendarType = 'US';

  if (locale === 'ar') {
    calendarType = 'Arabic';

  } else if (locale === 'he') { // there is no hebrew in Edmodo, but if it is added in the future:
    calendarType = 'Hebrew';

  } else if (mondayFirstLocales.includes(locale)) {
    calendarType = 'ISO 8601';
  }

  return (
    <Calendar
      {...props}
      className={`row_style_calendar ${props.className}`}
      calendarType={calendarType}
      locale={locale}
    />
  )
}


export default LocaleCalendar;
