import ActionConstants from '../constants/actions/ActionConstants';
import * as BlacklistAPI from '../apis/BlacklistAPI';
import { showErrorGlobalAlert, showSuccessGlobalAlert } from './globalAlertActionCreator';

export const updateBlacklistAction = (blacklist, total) => ({
    type: ActionConstants.UPDATE_BLACKLIST_LIST,
    payload: { data: blacklist, total: total },
});

export const getBlacklist = (params) => {
    return function (dispatch) {
        return new Promise((resolve, reject) => {
            BlacklistAPI.fetchBlacklist(params)
                .then((res) => {
                    if (res.status === 200) {
                        const blacklist = res.data.data.list;
                        const total = res.data.data.total;
                        dispatch(updateBlacklistAction(blacklist, total));
                        return resolve('success');
                    }
                })
                .catch((err) => {
                    return reject(err);
                });
        });
    };
}

export const getBlacklistInfoById = (id) => {
    return function (dispatch, getState) {
        return new Promise((resolve, reject) => {
            BlacklistAPI.getBlacklistInfoById(id)
                .then((res) => {
                    if (res.status === 200) {
                        return resolve(res.data.data);
                    }
                })
                .catch((err) => {
                    return reject(err);
                });
        });
    };
}

export const editBlacklistInfoById = (params) => {
    return function (dispatch, getState) {
        return new Promise((resolve, reject) => {
            BlacklistAPI.editBlacklistInfoById(params)
                .then((res) => {
                    if (res.status === 200 && res.data.code === 1) {
                        dispatch(showSuccessGlobalAlert('edit-blacklist-info-succeed'));
                        return resolve('success');
                    } else {
                        dispatch(showErrorGlobalAlert(res.data.msg));
                        return reject(res.data.msg)
                    }
                })
                .catch((err) => {
                    dispatch(showErrorGlobalAlert('edit-blacklist-info-failed'));
                    return reject(err)
                });
        });
    };
}

export const deleteBlacklistInfoById = (id) => {
    return function (dispatch, getState) {
        return new Promise((resolve, reject) => {
            BlacklistAPI.deleteBlacklistInfoById(id)
                .then((res) => {
                    if (res.status === 200 && res.data.code === 1) {
                        dispatch(showSuccessGlobalAlert('delete-blacklist-info-succeed'));
                        return resolve('success');
                    } else {
                        dispatch(showErrorGlobalAlert(res.data.msg));
                        return reject(res.data.msg)
                    }
                })
                .catch((err) => {
                    dispatch(showErrorGlobalAlert('delete-blacklist-info-failed'));
                    return reject(err)
                });
        });
    };
}
