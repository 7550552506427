import React from 'react';
import Colors from '../constants/ColorConstants';

const BankAccountIcon = ({
  size = 24,
  color = Colors.icon.default,
  viewBox = '0 0 24 24',
  ...props
}) => (
  <svg viewBox="0 0 1251 1024" p-id="5196" width={size} height={size} className={`tw-w-[${size}px] tw-h-[${size}px] `} {...props}>
    <path d="M1102.677333 1023.971556H148.992c-67.811556 0-122.993778-61.127111-122.993778-136.049778V136.049778c0-75.036444 55.182222-136.021333 122.993778-136.021334h953.685333c67.896889 0 123.107556 61.155556 123.107556 136.106667v751.786667c0 75.008-55.210667 136.049778-123.107556 136.049778z m37.461334-800.369778V136.135111c0-27.335111-17.152-50.545778-37.461334-50.545778H148.992c-20.309333 0-37.461333 23.068444-37.461333 50.460445v117.703111h1028.608v-30.151111z m0 286.805333h-0.369778v-85.532444h0.369778v-85.560889h-0.369778v-0.199111H111.502222v342.186666h0.028445v206.620445c0 27.306667 17.152 50.460444 37.461333 50.460444l444.273778 0.028445h509.411555c20.309333 0 37.461333-23.096889 37.461334-50.488889V510.407111zM1006.791111 871.537778a42.865778 42.865778 0 0 1-38.286222 23.637333h-256.938667a42.837333 42.837333 0 0 1-38.286222-61.895111l85.617778-171.093333c7.281778-14.506667 22.072889-23.665778 38.314666-23.665778h256.910223a42.780444 42.780444 0 0 1 38.314666 61.923555l-85.646222 171.093334z m-183.096889-147.456l-42.837333 85.560889h161.166222l42.808889-85.560889h-161.137778z m-154.936889-213.873778H640a42.780444 42.780444 0 1 1 0-85.560889h28.757333a42.780444 42.780444 0 1 1 0 85.560889z m-200.021333 0h-28.757333a42.780444 42.780444 0 1 1 0-85.560889h28.757333a42.780444 42.780444 0 1 1 0 85.560889z m-200.021333 0H239.957333a42.808889 42.808889 0 1 1 0-85.560889h28.757334a42.780444 42.780444 0 1 1 0 85.560889z" fill={color} p-id="5197"></path>
  </svg>
);

export default BankAccountIcon