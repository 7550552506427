import React from 'react';
import Colors from '../constants/ColorConstants';

const RoleIcon = ({
  size = 24,
  color = Colors.icon.default,
  viewBox = '0 0 24 24',
  ...props
}) => (
  <svg viewBox="0 0 1024 1024" p-id="9082" width={size} height={size} {...props}>
    <path d="M426.666667 149.333333A149.333333 149.333333 0 1 0 277.333333 298.666667 149.333333 149.333333 0 0 0 426.666667 149.333333zM277.333333 256A106.666667 106.666667 0 1 1 384 149.333333 106.666667 106.666667 0 0 1 277.333333 256z m682.666667 0A64 64 0 1 0 896 192 64 64 0 0 0 960 256z m-128-42.666667a21.333333 21.333333 0 0 0 0-42.666666h-85.333333a21.333333 21.333333 0 0 0-21.333334 21.333333V512h-64a21.333333 21.333333 0 0 0 0 42.666667H725.333333v277.333333a21.333333 21.333333 0 0 0 21.333334 21.333333h85.333333a21.333333 21.333333 0 0 0 0-42.666666H768v-256h64a21.333333 21.333333 0 0 0 0-42.666667H768V213.333333h64z m128 256a64 64 0 1 0 64 64 64 64 0 0 0-64-64zM469.333333 298.666667H85.333333a85.589333 85.589333 0 0 0-85.333333 85.333333v341.333333a85.589333 85.589333 0 0 0 85.333333 85.333334h85.333334v192a21.333333 21.333333 0 0 0 42.666666 0V768H85.333333a42.666667 42.666667 0 0 1-42.666666-42.666667V384a42.666667 42.666667 0 0 1 42.666666-42.666667h384a42.666667 42.666667 0 0 1 42.666667 42.666667v341.333333a42.666667 42.666667 0 0 1-42.666667 42.666667H384v234.666667a21.333333 21.333333 0 0 0 42.666667 0V810.666667h42.666666a85.589333 85.589333 0 0 0 85.333334-85.333334V384a85.589333 85.589333 0 0 0-85.333334-85.333333z m490.666667 469.333333a64 64 0 1 0 64 64 64 64 0 0 0-64-64z" fill={color} p-id="9083"></path>
  </svg>
);

export default RoleIcon