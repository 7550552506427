import ActionConstants from '../constants/actions/ActionConstants';
import * as UserAPI from '../apis/UserAPI';
import { showErrorGlobalAlert, showSuccessGlobalAlert } from './globalAlertActionCreator';
import UserHelper from '../helpers/UserHelper';
import { showAdminRouterAction, showBaseRouterAction, showForeignerRouterAction } from './routeActionCreator';
import I18N from '../i18n';

export const updateCurrentUser = (data) => ({
    type: ActionConstants.UPDATE_CURRENT_USER,
    payload: data,
});

export const logoutAction = () => ({
    type: ActionConstants.LOGOUT,
    payload: null,
});

export const setAuthenticationAction = (token) => ({
    type: ActionConstants.UPDATE_AUTHENTICATION,
    payload: { token }
});


export const updatePaymentTypeAction = (type) => ({
    type: ActionConstants.UPDATE_PAYMENT_TYPE,
    payload: type,
});

export const clearCurrentUserAction = () => ({
    type: ActionConstants.CLEAR_CURRENT_USER,
    payload: null,
});

export const updateUserListAction = (userList, total) => ({
    type: ActionConstants.UPDATE_USER_LIST,
    payload: { data: userList, total: total },
});


export const getVerificationCode = (email) => {
    return function (dispatch, getState) {
        return new Promise((resolve, reject) => {
            UserAPI.fetchVerificationCode({account: email})
                .then((res) => {
                    if (res.status === 200 && res.data.code === 1) {
                        dispatch(showSuccessGlobalAlert(I18N.getText('code-sent-successfully')));
                        return resolve('success');
                    } else {
                        dispatch(showErrorGlobalAlert(res.data.msg));
                        return reject(res.data.msg);
                    }
                })
                .catch((err) => {
                    return reject(err);
                });
        });
    };
};

export const loginUser = (email, password, code) => {
    return function (dispatch, getState) {
        return new Promise((resolve, reject) => {
            UserAPI.loginUser(email, password, code)
                .then((res) => {
                    if (res.status === 200 && res.data.code === 1) {
                        let userInfo = res.data.data.userinfo;
                        updateMeProfile(dispatch, userInfo);
                        return resolve('success');
                    } else {
                        dispatch(showErrorGlobalAlert(res.data.msg));
                        return reject(res.data.msg);
                    }
                })
                .catch((err) => {
                    return reject(err);
                });
        });
    };
};

export const logout = () => {
    return function (dispatch) {
        return new Promise((resolve, reject) => {

            UserAPI.logout()
                .then(res => {
                    dispatch(logoutAction());
                    dispatch(clearCurrentUserAction());
                    resolve(res);
                })
                .catch(err => {
                    dispatch(showErrorGlobalAlert('logout-error'));
                });
        });
    }
}

export const fetchMeProfile = () => {
    return function (dispatch, getState) {
        return new Promise((resolve, reject) => {
            UserAPI.fetchUserProfileWithAccessToken()
                .then((res) => {
                    if (res.status === 200) {
                        let userInfo = res.data.data;
                        updateMeProfile(dispatch, userInfo);
                        return resolve('success');
                    }
                })
                .catch((err) => {
                    return reject(err);
                });
        });
    };
}

function updateMeProfile(dispatch, userInfo) {
    dispatch(updateCurrentUser(userInfo));
    dispatch(setAuthenticationAction(userInfo.token));
    if (UserHelper.isAdminUser(userInfo)) {
        dispatch(showAdminRouterAction());
    }  else if (UserHelper.isForeigner(userInfo)) {
        dispatch(showForeignerRouterAction());
    }  else if (UserHelper.isCustomerUser(userInfo)) {
        dispatch(showForeignerRouterAction());
    } else {
        dispatch(showBaseRouterAction())
    }
}

export const fetchUserList = (params) => {
    return function (dispatch, getState) {
        return new Promise((resolve, reject) => {
            UserAPI.fetchUserList(params.page, params.per_page, params.keywords)
                .then((res) => {
                    if (res.status === 200) {
                        const userList = res.data.data.list;
                        const total = res.data.data.total;
                        dispatch(updateUserListAction(userList, total));
                        return resolve('success');
                    }
                })
                .catch((err) => {
                    return reject(err);
                });
        });
    };
}

export const fetchUnpagedUserList = () => {
    return function (dispatch, getState) {
        return new Promise((resolve, reject) => {
            UserAPI.fetchUnpagedUserList()
                .then((res) => {
                    if (res.status === 200) {
                        const userList = res.data.data.list;
                        const total = res.data.data.total;
                        dispatch(updateUserListAction(userList, total));
                        return resolve('success');
                    }
                })
                .catch((err) => {
                    return reject(err);
                });
        });
    };
}

export const getUserProfileById = (id) => {
    return function (dispatch, getState) {
        return new Promise((resolve, reject) => {
            UserAPI.getUserInfoById(id)
                .then((res) => {
                    if (res.status === 200) {
                        return resolve(res.data.data);
                    }
                })
                .catch((err) => {
                    return reject(err);
                });
        });
    };
}

export const editUserProfileById = (params) => {
    return function (dispatch, getState) {
        return new Promise((resolve, reject) => {
            UserAPI.editUserInfoById(params)
                .then((res) => {
                    if (res.status === 200 && res.data.code === 1) {
                        dispatch(showSuccessGlobalAlert('edit-profile-succeed'));
                        return resolve('success');
                    } else {
                        dispatch(showErrorGlobalAlert(res.data.msg));
                        return reject(res.data.msg)
                    }
                })
                .catch((err) => {
                    dispatch(showErrorGlobalAlert('edit-profile-failed'));
                    return reject(err)
                });
        });
    };
}

// export const createNewUser = (params) => {
//     return function (dispatch, getState) {
//         return new Promise((resolve, reject) => {
//             UserAPI.createUser(params)
//                 .then((res) => {
//                     if (res.status && res.data.code === 1) {
//                         dispatch(showSuccessGlobalAlert('create-user-succeed'));
//                         return resolve('success');
//                     }
//                 })
//                 .catch((err) => {
//                     dispatch(showErrorGlobalAlert('create-user-failed'));
//                 });
//         });
//     };
// }