import { createSelector } from 'reselect';
import StoreStateConstants from '../constants/store/StoreStateConstants';
import ReducerNameConstants from '../constants/reducers/ReducerNameConstants';

const selectWithdrawReducer  = state => state.get(ReducerNameConstants.WITHDRAW);

export const selectWithdrawList = createSelector(
  [selectWithdrawReducer],
  (withdrawReducer) => {
    const withdrawList = withdrawReducer && withdrawReducer.get(StoreStateConstants.WITHDRAW_LIST);
    return withdrawList;
  },
);

export const selectWithdrawRecordTotalNum = createSelector(
  [selectWithdrawReducer],
  (withdrawReducer) => {
    const totalNum = withdrawReducer && withdrawReducer.get(StoreStateConstants.WITHDRAW_TOTAL_COUNT);
    return totalNum;
  },
);  

export const selectMoneyBalance = createSelector(
  [selectWithdrawReducer],
  (withdrawReducer) => {
    const moneyBalance = withdrawReducer && withdrawReducer.get(StoreStateConstants.MONEY_BALANCE);
    return moneyBalance;
  },
);  
