import ApiEndpointConstants from '../constants/api/ApiEndpointConstants';
import api from './API';

export const getWithdrawRecord = (params) => {
    return api.post(
        `${ApiEndpointConstants.WITHDRAW_LIST}`,
        params,
    );
}

export const withdraw = (address, money) => {
    return api.post(
        `${ApiEndpointConstants.WITHDRAW}`,
        { address, money },
    );
}

export const actionWithdraw = (id, type) => {
    return api.post(
        `${ApiEndpointConstants.WITHDRAW_ACTION}`,
        { id, type },
    );
}