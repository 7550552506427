import React, { Component } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';

import { bindActionCreators } from 'redux';
import { div } from 'reactstrap';
import classnames from 'classnames';
import Pagination from '@material-ui/lab/Pagination';

// --- Components --- //
import Loading from '../../components/common/LoadingIndicator';
import SvgIcon from '../../components/common/SvgIcon';
import EDSInput from '../../components/common/EDSInput';

// --- Constants --- //
import ColorConstants from '../../constants/ColorConstants';
import DialogConstants from '../../constants/dialog/index';

// --- Actions --- //
import * as coinActionCreator from '../../actions/coinActionCreator';

// --- Selectors --- //
import * as CoinSelectors from '../../selectors/CoinSelectors'

// --- Icons --- //
import DollarIcon from '../../icons/DollarIcon';
import CalendarIcon from '../../icons/CalendarIcon';

import I18N from '../../i18n';
import RDSButton from '../../components/common/RDSButton';
import PagePathConstants from '../../constants/router/PagePathConstants';
import DeleteConfirmationModal from '../../components/common/dialog/DeleteConfirmationModal';

class AhcCardPage extends Component {
  constructor(props, context) {
    super(props, context);

    this.state = {
      coinParams: {
        page: 1,
        per_page: 10,
        type: 'COIN'
      },

      showDeleteConfirmModal: false,
      deleteItemId: '',
      showLoading: false,
    };

    this.getCoinInfo = this.getCoinInfo.bind(this);
    this.handleCardFilterClick = this.handleCardFilterClick.bind(this);
    this.handlePageChange = this.handlePageChange.bind(this);
    this.toggleLoading = this.toggleLoading.bind(this);
    this.handleCardFilterChange = this.handleCardFilterChange.bind(this);
    this.handleEditCoin = this.handleEditCoin.bind(this);
    this.handleDeleteCoin = this.handleDeleteCoin.bind(this);
    this.createNewCoin = this.createNewCoin.bind(this);
    this.toggleDeleteConfirmModal = this.toggleDeleteConfirmModal.bind(this);
    this.handleDeleteCoin = this.handleDeleteCoin.bind(this);
  }

  componentDidMount() {
    this.getCoinInfo();
  }

  toggleDeleteConfirmModal() {
    this.setState({ showDeleteConfirmModal: !this.state.showDeleteConfirmModal });
  }

  handleDeleteCoin(id) {
    this.toggleDeleteConfirmModal();
    this.setState({ deleteItemId: id });
  }

  handleEditCoin(id) {
    const { history } = this.props;
    history && history.push(PagePathConstants.COIN_EDIT.replace(':id', id));
  }

  handleDeleteCoin() {
    const { coinActions } = this.props;
    const { deleteItemId } = this.state;

    this.toggleDeleteConfirmModal();
    this.toggleLoading();
    coinActions && (deleteItemId && deleteItemId !== '') && coinActions.deleteCoin(deleteItemId)
      .then(() => {
        this.toggleLoading();
        this.getCoinInfo();
      })
      .catch(this.toggleLoading);

  }

  toggleLoading() {
    this.setState({ showLoading: !this.state.showLoading });
  }

  handleCardFilterChange(e) {
    const name = e && e.target.value;
    const { coinParams } = this.state;
    coinParams.keywords = name;
    this.setState({ coinParams });
  }

  getCoinInfo(params) {
    if (!params) {
      params = this.state.coinParams;
    }
    const { coinActions } = this.props;
    this.toggleLoading();
    return coinActions && coinActions.fetchCoinList(params)
      .then(data => {
        this.toggleLoading();
        return;
      });
  }

  handleCardFilterClick() {
    let { coinParams } = this.state;
    coinParams.page = 1;
    this.setState({ coinParams });
    this.getCoinInfo(coinParams);
  }

  handlePageChange(e, page) {
    let { coinParams } = this.state;
    coinParams.page = page;
    this.getCoinInfo(coinParams)
      .then(() => {
        this.setState({ coinParams });
      });
  }

  createNewCoin() {
    const { history } = this.props;
    history && history.push(PagePathConstants.COIN_ADD);
  }

  renderCoinStatus(state) {
    switch (state) {
      case 0: {
        return (<div className='tw-rounded-[10px] tw-w-[120px] tw-h-[40px] tw-text-[16px] tw-font-bold tw-px-[8px] tw-py-[2px] tw-bg-status-succeed-bg tw-text-status-succeed-text tw-flex tw-items-center tw-justify-center'>
          <span>{I18N.getText('on')}</span>
        </div>)
      }
      case 1:
      default: {
        return (<div className='tw-rounded-[10px] tw-w-[120px] tw-h-[40px] tw-text-[16px] tw-font-bold tw-px-[8px] tw-py-[2px] tw-bg-status-timeout-bg tw-text-status-timeout-text tw-flex tw-items-center tw-justify-center'>
          <span>{I18N.getText('off')}</span>
        </div>)
      }
    }
  }

  renderCurrency(currency) {
    switch (currency) {
      case 0: {
        return (<span>{I18N.getText('GBP')}</span>)
      }
      case 1:
      default: {
        return (<span>{I18N.getText('EUR')}</span>)
      }
    }
  }

  render() {
    const { coinList, coinTotalNum } = this.props;
    const { coinParams, showLoading, showDeleteConfirmModal } = this.state;
    const total = Math.ceil(coinTotalNum / coinParams.per_page);
    return (<div className='tw-w-full tw-px-[50px]'>
      <div className='tw-w-full tw-mt-[30px]'>
        <div className='tw-flex tw-justify-end tw-items-center tw-w-full tw-mt-[20px] hs-userlist-filter-container'>
          {/* <div className='tw-relative'>
            <EDSInput className="tw-w-[450px] tw-py-[12px] tw-rounded-[30px] tw-px-[20px] tw-border-none tw-shadow-xl"
              value={coinParams.keywords}
              type="text"
              placeholder={I18N.getText('card-number-placeholder')}
              onChange={this.handleCardFilterChange}
            />
            <SearchIcon size={24} color={ColorConstants.black[0]} className="tw-absolute tw-right-[20px] tw-bottom-[12px] tw-w-[24px] tw-h-[24px]" onClick={this.handleCardFilterClick} />
          </div> */}
          <div className='tw-w-[300px] tw-flex tw-justify-end tw-items-center'>
            <RDSButton className="tw-text-white" onClick={this.createNewCoin}>Create</RDSButton>
          </div>
        </div>

        {coinList && coinList.length > 0 && <React.Fragment>
          <div className='tw-w-full tw-mt-[30px]'>
            {showLoading && <Loading />}
            <DeleteConfirmationModal isDeleteModalOpen={showDeleteConfirmModal} toggle={this.toggleDeleteConfirmModal} handleDeleteConfirm={this.handleDeleteCoin} sectionName={DialogConstants.COIN_CARD} />
            <div className='tw-w-full hs-users-table-container'>
              <div className='tw-flex tw-justify-between tw-items-center tw-text-center tw-font-bold tw-text-[14px] tw-mb-[20px] tw-rounded-[30px] tw-bg-white tw-h-[80px] tw-shadow-xl'>
                <div className='tw-w-[15%]'>{I18N.getText('account')}</div>
                <div className='tw-w-[5%]'>{I18N.getText('currency')}</div>
                <div className='tw-w-[10%]'>{I18N.getText('max-money')}</div>
                <div className='tw-w-[5%]'>{I18N.getText('max-order')}</div>
                <div className='tw-w-[10%]'>{I18N.getText('total-money')}</div>
                <div className='tw-w-[5%]'>{I18N.getText('order-count')}</div>
                <div className='tw-w-[15%]'>{I18N.getText('tgid')}</div>
                <div className='tw-w-[10%]'>{I18N.getText('status')}</div>
                <div className='tw-w-[15%]'>{I18N.getText('date')}</div>
                <div className='tw-w-[10%]'>{I18N.getText('operation')}</div>
              </div>
              <div className='tw-w-full tw-text-[14px]'>
                {coinList && coinList.map(record => {
                  return (<div className='tw-rounded-[30px] tw-h-[80px] tw-bg-white tw-overflow-hidden tw-flex tw-justify-between tw-items-center tw-text-center tw-mb-[20px] tw-shadow-xl hs-table-row'>
                    <div className='tw-w-[15%] tw-flex tw-justify-center tw-flex-wrap tw-items-center hs-td-cell'>{`${record.account}`}</div>
                    <div className='tw-w-[5%] tw-flex tw-justify-center tw-flex-wrap tw-items-center hs-td-cell'>{`${record.currency}`}</div>
                    <div className='tw-w-[10%] tw-flex tw-justify-center tw-items-center'>
                      <DollarIcon size={18} color={ColorConstants.black[0]} className="tw-mb-[3px]" />
                      <span className='tw-font-bold hs-td-cell'>{record.max_money}</span>
                    </div>
                    <div className='tw-w-[5%] tw-flex tw-justify-center tw-items-center hs-td-cell'>{`${record.max_order}`}</div>
                    <div className='tw-w-[10%] tw-flex tw-justify-center tw-items-center'>
                      <DollarIcon size={18} color={ColorConstants.black[0]} className="tw-mb-[3px]" />
                      <span className='tw-font-bold hs-td-cell'>{record.money_sum || 0}</span>
                    </div>

                    <div className='tw-w-[5%] tw-flex tw-justify-center tw-items-center hs-td-cell'>{`${record.order_count || 0}`}</div>
                    <div className='tw-w-[15%] tw-flex tw-justify-center tw-items-center hs-td-cell'>{`${record.telId}`}</div>
                    <div className='tw-w-[10%] tw-flex tw-justify-center tw-items-center'>{this.renderCoinStatus(record.status)}</div>
                    <div className='tw-w-[15%] tw-flex tw-justify-center tw-items-center'>
                      <CalendarIcon size={24} color={ColorConstants.black[0]} className="tw-mb-[2px]" />
                      <span className='tw-text-[13px] tw-ml-[5px] hs-td-cell'>{record.createtime}</span>
                    </div>
                    <div className='tw-w-[10%] tw-flex tw-justify-center tw-items-center'>
                      <SvgIcon onClick={() => this.handleEditCoin(record.id)} size={20} color={ColorConstants.brand.primary} name="edit-icon" />
                      <SvgIcon onClick={() => this.handleDeleteCoin(record.id)} size={20} color={ColorConstants.brand.primary} name="delete-icon" className="tw-ml-[10px]" />
                    </div>
                  </div>)
                })}
              </div>
            </div>
          </div>
          <div className='tw-w-full tw-flex tw-justify-center'>
            <Pagination count={total} page={coinParams.page} onChange={this.handlePageChange} variant="outlined" color="primary" />
          </div>
        </React.Fragment>}
        {coinList && coinList.length === 0 && <div className='tw-w-full tw-flex tw-flex-col tw-justify-center tw-items-center'>
          <SvgIcon size={200} name="non-record-icon" />
          <div className='tw-mt-[20px] tw-text-[16px] tw-font-medium'>{I18N.getText('no-records')}</div>
        </div>}
      </div>
    </div>);
  }
}

function mapStateToProps(state) {
  return {
    coinList: CoinSelectors.selectCoinCardList(state),
    coinTotalNum: CoinSelectors.selectCoinRecordTotalNum(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    coinActions: bindActionCreators(coinActionCreator, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(AhcCardPage);

