import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination } from 'swiper';

// --- Components --- //
import RDSButton from '../components/common/RDSButton';
import SvgIcon from '../components/common/SvgIcon';

// --- Constants --- //
import PagePathConstants from '../constants/router/PagePathConstants';

// --- Actions --- //
import * as userActionCreator from '../actions/userActionCreator';

// --- Selectors --- //
import * as UserSelectors from '../selectors/UserSelectors';

// --- Image --- //
import LoginFormImg1 from '../images/login-form-1.png';
import LoginFormImg2 from '../images/login-form-2.png';

import '../styles/pages/loginPage.css';
import 'swiper/css';
import 'swiper/scss/navigation';
import 'swiper/scss/pagination';
import classNames from 'classnames';

class LoginPage extends Component {
  constructor(props, context) {
    super(props, context);

    this.state = {
      email: '',
      password: '',
      code: '',
      showLoginError: false,
      isLogining: false,
      showPasswordEyeIcon: false,
    };

    this.handleLoginAction = this.handleLoginAction.bind(this);
    this.handleEmailChange = this.handleEmailChange.bind(this);
    this.handlePasswordChange = this.handlePasswordChange.bind(this);
    this.handleCodeChange = this.handleCodeChange.bind(this);
    this.handleGetVerificationCode = this.handleGetVerificationCode.bind(this);
    this.toggleLoginErrorStatus = this.toggleLoginErrorStatus.bind(this);
    this.togglePasswordEyeIcon = this.togglePasswordEyeIcon.bind(this);
    this.toggleLoginStatus = this.toggleLoginStatus.bind(this);
    this.toggleCodeLoadingStatus = this.toggleCodeLoadingStatus.bind(this);
  }

  componentDidMount() {
    // const {loginStatus, history} = this.props;
    // const login = (loginStatus === LOGIN_CONSTANTS.LOGGED_IN);
    // login && history && history.replace(PagePathConstants.HOME_PAGE);
  }

  togglePasswordEyeIcon() {
    this.setState({ showPasswordEyeIcon: !this.state.showPasswordEyeIcon });
  }

  toggleLoginStatus() {
    this.setState({ isLogining: !this.state.isLogining });
  }

  toggleCodeLoadingStatus() {
    this.setState({ isCodeLoading: !this.state.isCodeLoading });
  }

  handleLoginAction() {
    const { email, password, code } = this.state;
    const { userActions, history } = this.props;
    this.setState({ showLoginError: false });
    this.toggleLoginStatus();
    userActions && userActions.loginUser(email, password, code)
      .then(() => {
        this.toggleLoginStatus();
        history && history.push(PagePathConstants.ROOT_PAGE);
      })
      .catch(() => {
        this.toggleLoginStatus();
        this.toggleLoginErrorStatus();
      });
  }

  toggleLoginErrorStatus() {
    this.setState({ showLoginError: !this.state.showLoginError });
  }

  handleEmailChange(event) {
    this.toggleLoginErrorStatus();
    const email = event.currentTarget.value;
    this.setState({ email, showLoginError: false });
  }

  handlePasswordChange(event) {
    this.toggleLoginErrorStatus();
    const password = event.currentTarget.value;
    this.setState({ password, showLoginError: false });
  }

  handleCodeChange(event) {
    this.toggleLoginErrorStatus();
    const code = event.currentTarget.value;
    this.setState({ code, showLoginError: false });
  }

  handleGetVerificationCode() {
    const { email } = this.state;
    const { userActions, history } = this.props;
    this.setState({ showLoginError: false });
    this.toggleCodeLoadingStatus();
    userActions && userActions.getVerificationCode(email)
      .then(() => {
        this.toggleCodeLoadingStatus();
      })
      .catch(() => {
        this.toggleCodeLoadingStatus();
        this.toggleLoginErrorStatus();
      });
  }

  render() {
    const { email, password, code, showLoginError, showPasswordEyeIcon, isLogining, isCodeLoading } = this.state;
    return (
      <div className='tw-w-full tw-h-full tw-flex tw-justify-center tw-items-center hs-login-page-container'>
        <div className="login-container">
          <div className="login-form">
            <div className="login-form-inner">
              <div className="logo"><svg height="512" viewBox="0 0 192 192" width="512" xmlns="http://www.w3.org/2000/svg">
                <path d="m155.109 74.028a4 4 0 0 0 -3.48-2.028h-52.4l8.785-67.123a4.023 4.023 0 0 0 -7.373-2.614l-63.724 111.642a4 4 0 0 0 3.407 6.095h51.617l-6.962 67.224a4.024 4.024 0 0 0 7.411 2.461l62.671-111.63a4 4 0 0 0 .048-4.027z" />
              </svg></div>
              <h1>Login</h1>
              <p className="body-text">See your growth and get consulting support!</p>

              {/* <a href="http://www.baidu.com" className="rounded-button google-login-button">
                <span className="google-icon"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                  <path d="M113.47 309.408L95.648 375.94l-65.139 1.378C11.042 341.211 0 299.9 0 256c0-42.451 10.324-82.483 28.624-117.732h.014L86.63 148.9l25.404 57.644c-5.317 15.501-8.215 32.141-8.215 49.456.002 18.792 3.406 36.797 9.651 53.408z" fill="#fbbb00" />
                  <path d="M507.527 208.176C510.467 223.662 512 239.655 512 256c0 18.328-1.927 36.206-5.598 53.451-12.462 58.683-45.025 109.925-90.134 146.187l-.014-.014-73.044-3.727-10.338-64.535c29.932-17.554 53.324-45.025 65.646-77.911h-136.89V208.176h245.899z" fill="#518ef8" />
                  <path d="M416.253 455.624l.014.014C372.396 490.901 316.666 512 256 512c-97.491 0-182.252-54.491-225.491-134.681l82.961-67.91c21.619 57.698 77.278 98.771 142.53 98.771 28.047 0 54.323-7.582 76.87-20.818l83.383 68.262z" fill="#28b446" />
                  <path d="M419.404 58.936l-82.933 67.896C313.136 112.246 285.552 103.82 256 103.82c-66.729 0-123.429 42.957-143.965 102.724l-83.397-68.276h-.014C71.23 56.123 157.06 0 256 0c62.115 0 119.068 22.126 163.404 58.936z" fill="#f14336" />
                </svg></span>
                <span>Sign in with google</span>
              </a> */}

              <div className="sign-in-seperator">
                <span> Sign in with Email</span>
              </div>

              <div className="login-form-group">
                <label for="email">Email <span className="required-star">*</span></label>
                <input type="text" value={email} placeholder="email@website.com" onChange={this.handleEmailChange} />
              </div>
              <div className="login-form-group tw-relative">
                <label for="pwd">Password <span className="required-star">*</span></label>
                <input type={showPasswordEyeIcon ? "text" : "password"} value={password} placeholder="Minimum 8 characters" onChange={this.handlePasswordChange} />
                <div className='tw-absolute tw-right-[10px] tw-bottom-[8px] tw-right-0 tw-mt-[-8px]' onClick={this.togglePasswordEyeIcon}>
                  {showPasswordEyeIcon ? <SvgIcon name="eye-icon" size={30} /> : <SvgIcon name="eye-off-icon" size={30} />}
                </div>
              </div>

              <div className="login-form-group tw-relative">
                <label for="pwd">Code <span className="required-star">*</span></label>
                <input type="text" value={code} placeholder="Verification code" onChange={this.handleCodeChange} />
                <RDSButton disabled={isCodeLoading} loading={isCodeLoading} size='md' rectangle className={classNames('!tw-w-auto tw-rounded-[30px] tw-text-white tw-absolute tw-right-[10px] tw-bottom-[7px] tw-right-0 tw-mt-[-8px]')} onClick={this.handleGetVerificationCode}>Get Code</RDSButton>
              </div>

              <div className="login-form-group single-row tw-text-right">
                {/* <div className="custom-check">
                  <input autocomplete="off" type="checkbox"  id="remember" /><label for="remember">Remember me</label>
                </div> */}

                {/* <div className="link forgot-link">Forgot Password ?</div> */}
              </div>

              <RDSButton loading={isLogining} className="rounded-button login-cta" onClick={this.handleLoginAction} disabled={isLogining || email.trim() === '' || password.trim() === '' || code.trim() === ''}>Login</RDSButton>

              {/* <div className="register-div">Not registered yet? <span className="link create-account" >Create an account ?</span></div> */}
            </div>
          </div>


          <div className="onboarding tw-flex tw-items-center tw-justify-center">
            <div className='tw-w-full tw-h-[80%] hs-login-img-container'>
              <Swiper modules={[Pagination]} pagination className='tw-w-full tw-h-full tw-relative'>
                <SwiperSlide>
                  <div className="tw-w-full tw-broder tw-flex tw-flex-col tw-items-center">
                    <div className='tw-w-full tw-flex tw-justify-center tw-mb-[30px]'>
                      <img src={LoginFormImg1} alt="tour-img" className='tw-w-[80%] tw-h-auto' />
                    </div>
                    <div className="tw-w-[60%]">
                      <h2 className='tw-text-[22px] tw-font-[500] tw-mb-[15px]'>Turn your ideas into reality.</h2>
                      <p className='tw-text-[16px] tw-font-[300] tw-leading-[22px]'>Consistent quality and eperience across all platform and devices</p>
                    </div>
                  </div>
                </SwiperSlide>

                <SwiperSlide>
                  <div className="tw-w-full tw-broder tw-flex tw-flex-col tw-items-center">
                    <div className='tw-w-full tw-flex tw-justify-center tw-mb-[30px]'>
                      <img src={LoginFormImg2} alt="tour-img" className='tw-w-[80%] tw-h-auto' />
                    </div>
                    <div className="tw-w-[60%]">
                      <h2 className='tw-text-[22px] tw-font-[500] tw-mb-[15px]'>Turn your ideas into reality.</h2>
                      <p className='tw-text-[16px] tw-font-[300] tw-leading-[22px]'>Consistent quality and eperience across all platform and devices</p>
                    </div>
                  </div>

                </SwiperSlide>


              </Swiper>
            </div>



            {/* <div className="swiper-container">
            <div className="swiper-wrapper">

              
             
            </div>
            <div className="swiper-pagination"></div>
          </div> */}
          </div>
        </div>
      </div>


    );
  }
}

function mapStateToProps(state) {
  return {
    loginStatus: UserSelectors.selectLoginStatus(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    userActions: bindActionCreators(userActionCreator, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(LoginPage);

