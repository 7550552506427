import { Map } from 'immutable';
import StoreStateConstants from '../constants/store/StoreStateConstants';
import ActionConstants from '../constants/actions/ActionConstants';

const initialState = Map({
    [StoreStateConstants.BLACKLIST]: [],
    [StoreStateConstants.BLACKLIST_TOTAL_COUNT]: 0,
});

export const BlacklistReducer = (state = initialState, action) => {
    switch (action.type) {
        case ActionConstants.UPDATE_BLACKLIST_LIST: {
            return state.set(StoreStateConstants.BLACKLIST, action.payload.data).set(StoreStateConstants.BLACKLIST_TOTAL_COUNT, action.payload.total);
        }
        default:
            return state;
    }
};
